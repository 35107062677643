import Vue from "vue";
import { Base64 } from 'js-base64';

const baseUrl = '/api/v2/user-files/'

export default {
  tree() {
    return Vue.axios.get(baseUrl)
  },

  get(path) {
    return Vue.axios.get(`${baseUrl}${Base64.encode(path).replace(/\+/g, '-').replace(/\//g, '_')}/`)
  },

  patch(path, data) {
    return Vue.axios.patch(`${baseUrl}${Base64.encode(path).replace(/\+/g, '-').replace(/\//g, '_')}/` , data)
  },

  post(data) {
    return Vue.axios.post(baseUrl, data)
  },

  delete(path) {
    return Vue.axios.delete(`${baseUrl}${Base64.encode(path).replace(/\+/g, '-').replace(/\//g, '_')}/`)
  }
}
