<template>
  <div>
    <inner-payment
        @onPaymentDialogClosed="onPaymentDialogClosed"
        @onFirstTimeLoad='loading = false'
        :is-gift-payment="true"
    >
      Купить в подарок
    </inner-payment>
    <v-skeleton-loader
        v-if="loading"
        class="mx-auto"
        type="list-item-three-line, list-item-three-line, card-heading, list-item-two-line, list-item-three-line"
    ></v-skeleton-loader>
  </div>
</template>

<script>
import {mapState} from "vuex";
import InnerPayment from "@/components/InnerPayment";

export default {
  name: 'gift-purchase-content-view',
  components: {InnerPayment},
  data: () => ({
    loading: true
  }),

  computed: {
    ...mapState(['social', 'settings', 'user']),
  },

  created() {

  },

  methods: {
     onPaymentDialogClosed(status) {
      if (status === 'successful') {
        this.$store.dispatch('user/fetchGifts');
      }
    },
  },

  watch: {
    '$route.path'() {

    }
  }
}
</script>