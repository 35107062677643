import QuestionService from '@/services/QuestionService.js'

export const namespaced = true

export const state = {
  questions: [],
  cachedComment: '',
  cachedQuestion: {
    title: '',
    text: ''
  }
}

export const mutations = {
  SET_QUESTIONS(state, questionsArray) {
    state.questions = questionsArray.map((item) => {
      item['isProfileOpened'] = false
      return item
    })
  },

  SET_CACHED_COMMENT(state, comment) {
    state.cachedComment = comment
  },

  SET_CACHED_QUESTION(state, question) {
    state.cachedQuestion = question;
  },

  ADD_QUESTION(state, questionObject) {
    questionObject['isProfileOpened'] = false
    state.questions.unshift(questionObject)
  },

  UPDATE_QUESTION(state, newQuestionObject) {
    let question = state.questions.find(question => question.id === newQuestionObject.id)
    newQuestionObject['isProfileOpened'] = false
    Object.assign(question, newQuestionObject)
  },

  APPEND_QUESTIONS(state, questions) {
    state.questions.push(...questions)
  },

  DELETE_QUESTION(state, deletedQuestionObject) {
    let questionIndex = state.questions.findIndex(question => question.id === deletedQuestionObject.id)
    if (questionIndex !== -1) {
      state.questions.splice(questionIndex, 1)
    }
  }
}

export const actions = {
  setCachedComment({ commit }, comment) {
    commit('SET_CACHED_COMMENT', comment);
  },

  setCachedQuestion({ commit }, question) {
    commit('SET_CACHED_QUESTION', question)
  },

  fetch({ commit, state }, params) {
    if (state.questions.length > 0) {
      params['id__lt'] = state.questions[state.questions.length - 1].id
    }

    return QuestionService.get(undefined, params).then((response) => {
      commit('APPEND_QUESTIONS', response.data);
      return response.data
    })
  },

  fetchOne({ commit }, id) {
    return QuestionService.get(`${id}`).then((response) => {
      commit('ADD_QUESTION', response.data)
      return response
    })
  },

  add({ commit }, data) {
    return QuestionService.post(data).then((response) => {
      commit('ADD_QUESTION', response.data)
      return response
    })
  },

  update({ commit }, data) {
    return QuestionService.patch(data, data.id).then((response) => {
      commit('UPDATE_QUESTION', response.data)
      return response
    })
  },

  delete({ commit }, data) {
    return QuestionService.delete(data.id).then(() => {
      commit('DELETE_QUESTION', data)
    })
  },

  reset({ commit }) {
    commit('SET_QUESTIONS', [])
  }
}

export const getters = {
  getCachedComment: (state) => {
    return state.cachedComment
  },

  getCachedQuestion: (state) => {
    return state.cachedQuestion
  }
}