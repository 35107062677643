import Vue from 'vue'
import Vuex from 'vuex'
import * as settings from './modules/settings'
import * as user from './modules/user'
import * as usersPublic from './modules/usersPublic'
import * as vfs from './modules/vfs'
import * as files from './modules/files'
import * as userFiles from './modules/userFiles';
import * as lessons from './modules/lessons'
import * as questions from './modules/questions'
import * as messages from './modules/messages'
import * as bookmarks from './modules/bookmarks'
import * as workbook from './modules/workbook'
import * as social from './modules/social'
import * as notifications from './modules/notifications'
import * as commonTools from './modules/commonTools'
import * as applications from './modules/applications'
import * as activity from './modules/activity'
import * as navigation from './modules/navigation'
import * as innerActions from './modules/innerActions'

Vue.use(Vuex)


export default new Vuex.Store({
  modules: {
    settings,
    user,
    usersPublic,
    vfs,
    files,
    userFiles,
    lessons,
    questions,
    messages,
    bookmarks,
    workbook,
    social  ,
    notifications,
    commonTools,
    applications,
    activity,
    navigation,
    innerActions
  }
})
