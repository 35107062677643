<template>
  <transition name="slide-fade" mode="in-out" appear>


    <v-navigation-drawer
      v-if="settings.UI.helperTitlesDrawerActive !== null"
      clipped
      app
      right
      :width=width
      :style=styles
      mobile-breakpoint="0"
    >
      <keep-alive>
        <router-view/>
      </keep-alive>
    </v-navigation-drawer>
  </transition>
</template>

<script>

import {mapGetters, mapState} from "vuex";

export default {
  name: 'helper-titles-drawer',

  data: () => ({
  }),

  computed: {
    ...mapState(['settings', 'navigation']),
    ...mapGetters(['lessons/getLastPassedLesson']),

    width() {
      if (this.$vuetify.breakpoint.width > (450 + 48)) {
        return 450;
      }
      return this.$vuetify.breakpoint.width - 48;
    },

    styles() {
      let baseStyles = {
        'z-index': 6,
        'box-shadow': '4px 23px 21px 5px rgba(0,0,0,0.3)'
      }

      if (!this.$vuetify.breakpoint.smAndDown && (this.$vuetify.breakpoint.width - this.settings.UI.gutters.right) < this.width) {
        return Object.assign({
          'left': `${(this.$vuetify.breakpoint.width - this.settings.UI.gutters.right + 48)}px`,
        }, baseStyles)
      } else if (this.$vuetify.breakpoint.smAndDown) {
        return Object.assign({'left': 0}, baseStyles)
      } else {
        return {'right': `${this.settings.UI.gutters.right}px`, 'z-index': 6}
      }
    }
  },

  watch: {
    '$route'(to, from) {
      if (this.$vuetify.breakpoint.smAndDown && (
          ((to.name === 'questionList') && (from.name === 'questionList')) ||
          ((to.name === 'questionList_') && (from.name === 'questionList_')) ||
          ((to.name === 'bookmarkContent') && ((from.name === 'bookmarkContent') || (from.name === 'bookmarks'))) ||
          ((to.name === 'workbookViewUpdate') && from.name.startsWith('workbook')) ||
          ((to.name === 'workbookAddRecord') && from.name.startsWith('workbook')) ||
          (to.path.startsWith('/social/') && from.path.startsWith('/social/'))
      )) {
        this.$store.dispatch('settings/updateUI', {'helperTitlesDrawerActive': null})
      }
    }

  }

}
</script>
