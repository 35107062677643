<template>
  <section @mousedown="onMouseDown" @click="onClick">
    <div :class="((right > 650) ? 'd-flex align-center justify-space-between' : '')">
      <div class="d-flex align-center">
        <slot></slot>
        <v-tabs v-model="tab" background-color="transparent">
          <v-tab :key="'write'">{{ $t('Text') }}</v-tab>
          <v-tab :key="'preview'">{{ $t('Preview') }}</v-tab>
        </v-tabs>
      </div>
      <div v-if="tab === 0" :class="'d-flex pl-3 pr-3 ' + ((right < 650) ? 'justify-start pt-2' : '')">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon @click="callAction('h3')" v-bind="attrs" v-on="on" :disabled="previewMode">
            <v-icon>mdi-format-header-pound</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('Header') }}</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon @click="callAction('bold')" v-bind="attrs" v-on="on" :disabled="previewMode">
            <v-icon>mdi-format-bold</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('Bold') }}{{ shortcut('bold')}}</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon @click="callAction('italic')" v-on="on" v-bind="attrs" :disabled="previewMode">
            <v-icon>mdi-format-italic</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('Italic') }}{{ shortcut('italic')}}</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon @click="callAction('quote')" v-on="on" v-bind="attrs" :disabled="previewMode">
            <v-icon>mdi-format-quote-open</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('quote') }}{{ shortcut('quote')}}</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon @click="callAction('list')" v-on="on" v-bind="attrs" :disabled="previewMode">
            <v-icon>mdi-format-list-bulleted</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('list') }}{{ shortcut('list')}}</span>
      </v-tooltip>


      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon @click="callAction('link')" v-on="on" v-bind="attrs" :disabled="previewMode">
            <v-icon>mdi-link</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('link') }}{{ shortcut('link')}}</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon @click="callAction('code')" v-on="on" v-bind="attrs" :disabled="previewMode">
            <v-icon>mdi-code-tags</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('insertCode') }}{{ shortcut('code')}}</span>
      </v-tooltip>


      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn :disabled="(!currentFile && !currentUserFile) || previewMode" icon @click="callAction('codeFromFile')" v-on="on" v-bind="attrs">
            <v-icon>mdi-file-code-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('codeFromFile') }}</span>
      </v-tooltip>
    </div>
    </div>



    <v-tabs-items v-model="tab">
      <v-tab-item :key="'write'" :transition="null">
        <AceEditor
          v-model="textInner"
          @init="editorInit"
          @blur="onBlur"
          lang="markdown"
          :theme=theme
          :wrap="true"
          width="100%"
          :minHeight="editorHeight"
          :options="{
              maxLines: Infinity,
              fontSize: 16, //settings.preferences.fontSize.value,
              highlightActiveLine: true,
              showLineNumbers: false,
              tabSize: 4,
              showPrintMargin: false,
              showGutter: false,
              showInvisibles: settings.preferences.showInvisible.value
            }"
          :commands="commands"
        />

        <v-card v-if="markdownLink">
          <v-card-text class="pa-1">
            <v-btn x-small text href="https://letpy.io/help/markdown/" target="_blank">Styling with Markdown is supported</v-btn>
            <a href="" target="_blank"></a>
          </v-card-text>
        </v-card>
      </v-tab-item >
      <v-tab-item :key="'preview'" :transition="null">
        <div v-if="contentLoading" :style="{'min-height': `${editorHeight}px`}">
          <v-progress-linear

            indeterminate
            color="primary"
            height="1.5"
          ></v-progress-linear>
        </div>
        <v-card v-else
                   :style="{'min-height': `${editorHeight}px`}">
          <v-card-text :class="['body-1', settings.preferences.darkTheme.value ? 'monokai-sublime-theme' : 'github-theme']" v-html="preview" v-hljs>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </section>
</template>
<script>
import {mapGetters, mapState} from 'vuex'
import AceEditor from '@/plugins/ace-editor/ace-editor';
import ThemeMixin from "@/mixins/ThemeMixin";
import SizeMixin from "@/mixins/SizeMixin";

export default {
  name: 'public-editor',
  components: {
    AceEditor,
  },
  mixins: [ThemeMixin, SizeMixin],

  props: {
    text: {
      type: String
    },
    editorHeight: {
      type: Number,
      default: 200
    }
  },

  data: () => ({
    previewMode: false,
    contentLoading: true,
    aceEditor: null,
    tab: 'write',
    preview: '',
    innerClickStarted: false
  }),

  computed: {
    ...mapState(['commonTools', 'settings', 'user']),
    ...mapGetters({currentFile: 'vfs/currentFile', currentUserFile: 'userFiles/current'}),
    markdownLink: function() {
      if (process.env.VUE_APP_APPLICATION_MODE === 'letpy.io') {
        return `${process.env.VUE_APP_MAIN_HOST}/help/markdown/`;
      } else {
        return null
      }
    },
    textInner: {
      get() {
        return this.text
      },

      set(val) {
        this.$emit('update:text', val)
      }
    },

    commands: function() {
      return [
          {
            name: 'insertCode',
            bindKey: {'win': 'Ctrl-E', 'mac': 'Cmd-E'},
            exec:  (editor) => {
              this.callAction('code')
            }
          },
          {
            name: 'insertBold',
            bindKey: {'win': 'Ctrl-B', 'mac': 'Cmd-B'},
            exec:  (editor) => {
              this.callAction('bold')
            }
          },
          {
            name: 'insertItalic',
            bindKey: {'win': 'Ctrl-I', 'mac': 'Cmd-I'},
            exec:  (editor) => {
              this.callAction('italic')
            }
          },
          {
            name: 'insertLink',
            bindKey: {'win': 'Ctrl-K', 'mac': 'Cmd-K'},
            exec:  (editor) => {
              this.callAction('link')
            }
          },
          {
            name: 'insertQuote',
            bindKey: {'win': 'Ctrl-Shift-.', 'mac': 'Cmd-Shift-.'},
            exec:  (editor) => {
              this.callAction('quote')
            }
          },
          {
            name: 'insertList',
            bindKey: {'win': 'Ctrl-Shift-8', 'mac': 'Cmd-Shift-8'},
            exec:  (editor) => {
              this.callAction('list')
            }
          }
      ]
    }
  },

  methods: {
    shortcut(key) {
      let isMac = null;
      if (navigator.userAgent.indexOf('Mac') !== -1) {
        isMac = true;
      } else if (navigator.userAgent.indexOf('Win') !== 1) {
        isMac = false;
      } else {
        return ''
      }

      switch (key) {
        case 'bold':
          return ', ' + (isMac ? '<⌘ + B>' : '<Ctrl + B>')
        case 'italic':
          return ', ' + (isMac ? '<⌘ + I>' : '<Ctrl + I>')
        case 'code':
          return ', ' + (isMac ? '<⌘ + E>' : '<Ctrl + E>')
        case 'quote':
          return ', ' + (isMac ? '<⌘ + ⇧ + .>' : '<Ctrl + Shift + .>')
        case 'list':
          return ', ' + (isMac ? '<⌘ + ⇧ + 8>' : '<Ctrl + Shift + 8>')
        case 'link':
          return ', ' + (isMac ? '<⌘ + K>' : '<Ctrl + K>')

        default:
          return ''
      }
    },

    editorInit(editor) {
      this.aceEditor = editor;
    },

    onMouseDown() {
      this.innerClickStarted = true;
    },

    onClick() {
      this.innerClickStarted = false;
    },

    onBlur(content) {
      if (!this.innerClickStarted) {
        this.$emit('blur', content);
      }
    },

    callAction(type) {
      /* TODO: We should refactor this method, but later. When we
          code all inserting rules.
      */
      let range = this.aceEditor.selection.getRange();
      if ((range.start.row === range.end.row) && (range.start.column === range.end.column)) {
        let pos = range.start;

        switch (type) {
          case 'bold':
            this.aceEditor.session.insert(pos, '****');
            this.aceEditor.gotoLine(pos.row + 1, pos.column + 2);
            this.aceEditor.focus();
            break
          case 'italic':
            this.aceEditor.session.insert(pos, '**');
            this.aceEditor.gotoLine(pos.row + 1, pos.column + 1);
            this.aceEditor.focus();
            break
          case 'quote':
            this.aceEditor.session.insert(pos, '\n\n> ');
            this.aceEditor.focus();
            break
          case 'h3':
            this.aceEditor.session.insert(pos, '### ');
            this.aceEditor.focus();
            break
          case 'list':
            this.aceEditor.session.insert(pos, '* ');
            this.aceEditor.focus();
            break
          case 'link':
            this.aceEditor.session.insert(pos, '[](url)');
            this.aceEditor.gotoLine(pos.row + 1, pos.column + 1);
            this.aceEditor.focus();
            break
          case 'code':
            this.aceEditor.session.insert(pos, '``');
            this.aceEditor.gotoLine(pos.row + 1, pos.column + 1);
            this.aceEditor.focus();
            break
          case 'codeFromFile':
            this.aceEditor.session.insert(pos, '\n```python\n' + (this.currentFile ? this.currentFile.content : this.currentUserFile.content) + '\n```\n');
            this.aceEditor.focus();
            break
        }
      } else {
        switch (type) {
          case 'bold':
            this.aceEditor.session.insert(range.end, '**');
            this.aceEditor.session.insert(range.start, '**');
            this.aceEditor.gotoLine(range.end.row + 1, range.end.column + 2);
            this.aceEditor.focus();
            break
          case 'italic':
            this.aceEditor.session.insert(range.end, '*');
            this.aceEditor.session.insert(range.start, '*');
            this.aceEditor.gotoLine(range.end.row + 1, range.end.column + 1);
            this.aceEditor.focus();
            break
          case 'quote':
            this.aceEditor.session.insert(range.start, '> ');
            for (let row=range.start.row + 1;row <= range.end.row; row++) {
              this.aceEditor.session.insert({row: row, column: 0}, '> ');
            }
            break
          case 'code':
            if (range.start.row === range.end.row) {
              this.aceEditor.session.insert(range.end, '`');
              this.aceEditor.session.insert(range.start, '`');
              this.aceEditor.gotoLine(range.end.row + 1, range.end.column + 1);
              this.aceEditor.focus();
            } else {
              this.aceEditor.session.insert(range.end, '\n```\n');
              this.aceEditor.session.insert(range.start, '\n```\n');
            }
            break
        }
      }
    }
  },

  watch: {
    tab(value) {
      if (value === 1) {
        this.$store.dispatch('commonTools/markdown', this.textInner).then((response) => {
          this.preview = response.data.text;
        }).finally(() => {
          this.contentLoading = false
        })
      }
    },

    textInner(value) {
      if (value === '') {
        this.preview = '';
        this.tab = 0;
      }
    }
  }
}
</script>
