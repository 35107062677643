import WorkbookService from '@/services/WorkbookService.js'


export const namespaced = true

export const state = {
  list: [],
  current: null
}

export const mutations = {
  SET_WORKBOOK_TITLES(state, workbookTitles) {
    state.list = workbookTitles
  },

  ADD_WORKBOOK_TITLE(state, title) {
    state.list.push(title)
  },

  SET_CURRENT_RECORD(state, record) {
    state.current = record;
  },

  UPDATE_CURRENT_RECORD(state, newRecord) {
    state.current = newRecord;
    let toUpdate = state.list.filter((item) => {
      return item.id === newRecord.id
    })[0]
    toUpdate['title'] = newRecord['title'];
  },

  REMOVE_RECORD(state, id) {
    let toDelete = state.list.filter((item) => {
      return item.id === Number(id)
    })[0]
    state.list.splice(state.list.indexOf(toDelete), 1)
  }
}

export const actions = {
  addRecord({ commit }, data) {
    return WorkbookService.post(data).then((response) => {
      commit("ADD_WORKBOOK_TITLE",response.data)
      return response
    })
  },

  getRecord({ commit }, id) {
    return WorkbookService.get(id).then((response) => {
      commit("SET_CURRENT_RECORD", response.data)
    })
  },

  updateRecord({ commit }, data) {
    return WorkbookService.patch(data, data.id).then((response) => {
      commit("UPDATE_CURRENT_RECORD", response.data)
    })
  },

  deleteRecord({ commit }, id) {
    return WorkbookService.delete(id).then(() => {
      commit("REMOVE_RECORD", id)
    })
  },

  fetchWorkbookTitles({ commit }) {
    return WorkbookService.get().then((response) => {
      commit("SET_WORKBOOK_TITLES", response.data)
    })
  },

  setNullRecord({ commit }) {
    commit('SET_CURRENT_RECORD', null)
  }
}

export const getters = {

}