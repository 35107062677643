<template>
    <i class="file-icon">
    <img v-if="name.endsWith('.py')" :src="require('material-icon-theme/icons/python.svg')"/>
    <img v-else-if="name.endsWith('.txt')" :src="require('material-icon-theme/icons/document.svg')"/>
    <img v-else-if="name.endsWith('.csv')" :src="require('material-icon-theme/icons/table.svg')"/>
    <img v-else-if="name.endsWith('.json')" :src="require('material-icon-theme/icons/json.svg')"/>
    <img v-else :src="require('material-icon-theme/icons/file.svg')"/>
    </i>
</template>
<script>

export default {
    props: [
        'name'
    ]
}

</script>

<style>
.file-icon > img {
    width: 20px;
    display: flex;
}
</style>