<template>
  <section>
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="primary"
      height="1"
    ></v-progress-linear>

    <v-list v-else-if="!loading && workbook.list.length > 0">
      <v-list-item
        v-for="item in workbook.list"
        :key="`item-workbook-${item.id}`"
        :to="`/workbook/${item.id}`"
      >
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <section class="text-center ma-16" v-else>
      <h3 class="text-h5 grey--text">{{ $t("There are no pages yet" ) }}</h3>
      <p class="grey--text pa-2">{{ $t('Here you can write notes about lessons, code examples, etc.') }}</p>
      <v-icon size="64" class="grey--text">mdi-book-open-outline</v-icon>
    </section>

    <v-fab-transition>
    <v-btn v-if="(($route.name === 'workbookViewUpdate') || ($route.name === 'workbook'))"
        class="mb-12"
        fab
        dark
        color="primary"
        bottom
        right
        fixed
        to="/workbook/add"
    >
      <v-icon dark>
        mdi-plus
      </v-icon>
    </v-btn>
      </v-fab-transition>
  </section>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: 'workbook-titles-view',

  data: () => ({
    loading: true
  }),

  created() {
    this.$store.dispatch('workbook/fetchWorkbookTitles').finally(() => {
      this.loading = false
    })
  },

  computed: {
    ...mapState(['workbook'])
  }
}
</script>

