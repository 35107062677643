import ActivityService from "@/services/ActivityService";

export const namespaced = true

export const state = {
  notifications: [],
  newNotificationsCount: 0,
  newNotificationsCountAfterFetch: 0
}

export const mutations = {
  SET_NOTIFICATIONS(state, notifications) {
    state.notifications = notifications
  },

  APPEND_NOTIFICATIONS(state, notifications) {
    state.notifications.push(...notifications)
  },

  SET_NOTIFICATIONS_COUNT(state, data) {
    state.newNotificationsCount = data.total;
    state.newNotificationsCountAfterFetch = data.filtered;
  }
}

export const actions = {
  fetch({ commit }) {
    return ActivityService.get(undefined, {}).then((response) => {
      commit('SET_NOTIFICATIONS', response.data);
      return response.data
    })
  },

  fetchAfter({ commit, state }) {
    let params = {}
    if (state.notifications.length > 0) {
      params['createdAt__lt'] = state.notifications[state.notifications.length - 1].createdAt
    }

    return ActivityService.get(undefined, params).then((response) => {
      commit('APPEND_NOTIFICATIONS', response.data);
      return response.data
    })
  },

  fetchNewNotificationsCount({ commit }) {
    let params = {}
    if (state.notifications.length > 0) {
      params['createdAt__gt'] = state.notifications[0].createdAt
    }

    return ActivityService.get('unseen-count', params).then((response) => {
      commit("SET_NOTIFICATIONS_COUNT", response.data)
    })
  },

  setViewedActivityAt({ state }) {
    let params = {}
    if ((state.notifications.length > 0) && (state.newNotificationsCountAfterFetch > 0)) {
      // If we have existing notifications, and after page loading,
      // we get several, but didn't load them.
      params['viewed_activity_at'] = state.notifications[0].createdAt
    }
    return ActivityService.put(params , 'set-viewed-activity-at')
  }
}

export const getters = {
}