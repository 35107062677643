<template>
  <div>
    <div class="text-xl pa-4"><router-link class="underline" :to='{name: "questionList_"}'>К списку вопросов</router-link></div>

    <div class="pl-4 pr-4 pb-16">
      <v-text-field :label="$t('Topic')" v-model="title"></v-text-field>
      <public-editor :text.sync="text"></public-editor>
      <div class="d-flex justify-end mt-5">
        <v-btn :loading="loading" text color="primary" :disabled="title === '' || text === '' || loading" @click="submit">
          {{ $t('Submit') }}
        </v-btn>
      </div>
      <set-username-dialog :show-menu="!!(user.info.hasAutoUsername)">
          {{ $t('setUsernameAddQuestionText') }}
      </set-username-dialog>
    </div>
  </div>
</template>

<script>
import PublicEditor from "@/components/editor/PublicEditor";
import {mapGetters, mapState} from "vuex";
import SetUsernameDialog from "@/components/dialogs/SetUsernameDialog";

export default {
  name: 'add-question',
  components: {SetUsernameDialog, PublicEditor},

  data: () => ({
    title: '',
    text: '',
    loading: false
  }),

  computed: {
    ...mapState(['user', 'lessons']),
    ...mapGetters(['questions/getCachedQuestion'])
  },

  created() {
    let q = this['questions/getCachedQuestion'];
    this.title = q.title;
    this.text = q.text;
  },

  methods: {
    submit() {
      this.loading = true;
      this.$store.dispatch('questions/add', {
        'sectionId': this.$route.params.sectionId,
        'lessonId': this.$route.params.lessonId,
        'courseId': this.$route.params.courseId,
        'title': this.title,
        'text': this.text,
      }).then((response) => {
        this.setDefaultValues();
        this.$nextTick(()=> {
          if (this.$route.path.startsWith('/lessons')) {
            this.$router.push(`/lessons/${this.$route.params.sectionId}.${this.$route.params.lessonId}/q/${response.data.id}`)
          } else {
            this.$router.push(`/course/${this.$route.params.courseId}/${this.$route.params.sectionId}/${this.$route.params.lessonId}/q/${response.data.id}`)
          }

          this.$store.dispatch('notifications/show', {text: this.$t('Question submitted')})
        });
      }).finally(() => {
        this.loading = false;
      });
    },

    setDefaultValues() {
      this.title = ''
      this.text = ''
    }
  },
  watch: {
    title(value) {
      this.$store.dispatch('questions/setCachedQuestion', {title: value, text: this.text})
    },

    text(value) {
      this.$store.dispatch('questions/setCachedQuestion', {title: this.title, text: value})
    }
  }
}
</script>