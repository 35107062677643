import SettingsService from "@/services/SettingsService";

export const namespaced = true

export const state = {
  preferences: {
    showInvisible: {
      label: 'showInvisibleSymbols',
      value: true,
      component: 'v-switch'
    },
    autoSave: {
      label: 'filesAutoSave',
      value: false,
      component: 'v-switch'
    },
    darkTheme: {
      label: 'nightMode',
      value: false,
      component: 'v-switch'
    },
    showAllNotifications: {
      label: 'Q&ANotifications',
      value: false,
      component: 'v-switch'
    },
    playSounds: {
      label: 'turnOnSounds',
      value: false,
      component: 'v-switch'
    },
    fontSize: {
      label: 'A',
      value: 15,
      component: 'v-slider'
    }
  },

  UI: {
    filesDrawer: true,
    helperDrawer: true,
    debuggerDrawer: true,
    helperTitlesDrawer: false,
    helperTitlesDrawerActive: null,

    editor: {
      transition: 'all .2s',
    },

    gutters: {
      top: 48, // top menu height
      bottom: 300,
      left: 300,
      right: 600,
    }
  }
}

export const mutations = {
  UPDATE_PREFERENCES(state, data) {
    for (const [key, item] of Object.entries(data)) {
      if (Object.prototype.hasOwnProperty.call(state.preferences, key)) {
        state.preferences[key].value = item;
      }
    }
  },

  UPDATE_UI(state, data) {
    Object.assign(state.UI, data)
  },

  UPDATE_GUTTER_POSITION(state, data) {
    state.UI.gutters[data.name] = data.value
  }
}

export const actions = {
  updateGutterPosition({ commit }, data) {
    commit('UPDATE_GUTTER_POSITION', data);
  },

  fetchPreferences({ commit }) {
    SettingsService.get().then((response)=>{
      commit('UPDATE_PREFERENCES', response.data)
    });
  },

  updatePreferences(_, data) {
    let settings = {}
    for (const [key, item] of Object.entries(data)) {
      settings[key] = item.value;
    }
    return SettingsService.put(settings);
  },

  updateUI({ commit, state }, data) {
    commit('UPDATE_UI', data)
    localStorage.setItem(process.env.VUE_APP_UI_STATE_STORAGE_NAME, JSON.stringify(state.UI))
  },

  fetchLocalUISettings({ commit, state }, breakpoint) {
    if (breakpoint.mdAndDown) {
      state.UI.filesDrawer = false;
      state.UI.gutters.right = breakpoint.width / 2;
    } else {
      state.UI.gutters.right = (breakpoint.width - state.UI.gutters.left) / 2;
    }

    let localUISettings = localStorage.getItem(process.env.VUE_APP_UI_STATE_STORAGE_NAME)
    if (localUISettings !== null) {
      commit('UPDATE_UI', JSON.parse(localUISettings))
    }
  },
}

export const getters = {
  getUISettings(state) {
    return state.UI
  },
}
