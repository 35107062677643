export default {
  unpack(string) {
    if ((string === null) || (string === undefined)) {
      return null;
    }
    if (string.length % 4 !== 0) {
      string += '='.repeat(4 - string.length % 4)
    }
    let symbols = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
    let symbols_len = symbols.length
    let result = ''
    let phrase = 'PYTHONsadropinreplacementforBASICinthesensethatOptimusPrimeisadropinreplacementforatruck'
    let phrase_index = 0
    for (let i = 0; i < string.length; i++) {
      if (symbols.indexOf(string[i]) !== -1) {
        let ind = (symbols_len + symbols.indexOf(string[i]) - symbols.indexOf(phrase[phrase_index])) % symbols_len;
        result += symbols[ind];
      } else {
        result += string[i]
      }
      phrase_index++;
      if (phrase_index >= phrase.length) {
        phrase_index = 0;
      }
    }
    string = result;
    let z = '';
    let mid = (string.length - 4) / 2;
    for (let i = 0; i < mid; i++) {
      z += string[i] + string[i + mid]
    }
    z += string.slice(mid * 2);
    return this.decode(z);
  },
  _utf8_decode(utftext) {
    let string = "";
    let i = 0;
    let c = 0;
    let c1 = 0;
    let c2 = 0;
    while (i < utftext.length) {
      c = utftext.charCodeAt(i);
      if (c < 128) {
        string += String.fromCharCode(c);
        i++;
      } else if ((c > 191) && (c < 224)) {
        c2 = utftext.charCodeAt(i + 1);
        string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
        i += 2;
      } else {
        c2 = utftext.charCodeAt(i + 1);
        c1 = utftext.charCodeAt(i + 2);
        string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c1 & 63));
        i += 3;
      }
    }
    return string;
  },
  decode(input) {
    let output = "";
    let chr1, chr2, chr3;
    let enc1, enc2, enc3, enc4;
    let i = 0;

    input = input.replace(/[^A-Za-z0-9+/=]/g, "");
    let _keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/="
    while (i < input.length) {
      enc1 = _keyStr.indexOf(input.charAt(i++));
      enc2 = _keyStr.indexOf(input.charAt(i++));
      enc3 = _keyStr.indexOf(input.charAt(i++));
      enc4 = _keyStr.indexOf(input.charAt(i++));
      chr1 = (enc1 << 2) | (enc2 >> 4);
      chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
      chr3 = ((enc3 & 3) << 6) | enc4;
      output = output + String.fromCharCode(chr1);
      if (enc3 != 64) {
        output = output + String.fromCharCode(chr2);
      }
      if (enc4 != 64) {
        output = output + String.fromCharCode(chr3);
      }
    }
    output = this._utf8_decode(output);
    return output;
  }
}

