import Vue from "vue";


class MySocket {
  constructor(url) {
    this.url = url;
    this.handlers = [];
    this.connectionAttemts = 0;
    this.openConnection();

  }
  openConnection() {
    this.socket = new WebSocket(
        this.url
    );

    this.socket.onopen = (event) => {
      this.connectionAttemts = 0
      console.info("Connection established.");
    }

    this.socket.onmessage = (event) => {
      this.handlers.forEach(handler => handler(JSON.parse(event.data)));
    }

    this.socket.onerror = (error) => {
      console.info(`Websocket error: `, error);
    }

    this.socket.onclose = (event) => {
      console.info("Connection closed.");
      this.connectionAttemts += 1;
      let timeout = this.connectionAttemts * 1000;
      if (timeout > 60 * 1000) {
        timeout = 60 * 1000;
      }
      setTimeout(() => {
        console.info('Trying to reconnect...');
        this.openConnection()
      }, timeout);
    }

  }

  subscribe(handler) {
    this.handlers.push(handler);
  }

  unsubscribe(handler) {
    this.handlers = this.handlers.filter(h => h !== handler);
  }
}

const socket = new MySocket(process.env.VUE_APP_WEBSOCKET_URL);

const VueSocket = function() {};

VueSocket.install = function(Vue) {
  Object.defineProperties(Vue.prototype, {
    $socket: {
      get() {
        return socket;
      }
    },
  });
};

Vue.use(VueSocket)
export default VueSocket;

/*
    socket.onmessage = function(event) {
        console.log(`[message] Данные получены с сервера: ${event.data}`);
      };

      socket.onclose = function(event) {
        console.log(event);
      };

      socket.onerror = function(error) {
        console.log(`[error]`);
      };
*/