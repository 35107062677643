
export default {
  methods: {
    registerInnerAction(action, params) {
      const isGtag = process.env.VUE_APP_APPLICATION_MODE === 'letpy.com'
      switch (action) {
        case 'purchase':
          if (isGtag) this.$gtag.event('Purchase', {'event_category': 'user'})
          if (this.$metrika) this.$metrika.reachGoal('Purchase');
          this.$store.dispatch('innerActions/register',
              params.parent === "LessonComponent" ? 'purchaseInLesson': 'purchaseInSocial'
              )
          break

        case 'openPaymentDialog':
          this.$store.dispatch('innerActions/register',
              params.parent === "LessonComponent" ? 'openPaymentDialogInLesson': 'openPaymentDialogInSocial'
              )
          break

        case 'openPaymentExternal':
          this.$store.dispatch('innerActions/register',
              params.parent === "LessonComponent" ? 'openPaymentExternalInLesson': 'openPaymentExternalInSocial'
              )
          break

        case 'viewPaymentInfo':
          this.$store.dispatch('innerActions/register',
              params.parent === "LessonComponent" ? 'viewPaymentInfoInLesson': 'viewPaymentInfoInSocial'
              )
          break

        case 'clickTariffsSelect':
          this.$store.dispatch('innerActions/register',
              params.parent === "LessonComponent" ? 'clickTariffsSelectInLesson' : 'clickTariffsSelectInSocial'
              )
          break


        case 'visitLesson':
          let goal = params.isPaid ? 'visit_paid_lesson' : 'visit_free_lesson'
          if (isGtag) this.$gtag.event(goal, {'event_category': 'user'})
          if (this.$metrika) this.$metrika.reachGoal(goal);
          break

        case 'passLesson':
          if (params.isPaid) {
            if (isGtag) this.$gtag.event('pass_paid_lesson', {'event_category': 'user'})
            if (this.$metrika) this.$metrika.reachGoal('pass_paid_lesson');
          } else {
            if (isGtag) this.$gtag.event('pass_free_lesson', {'event_category': 'user'})
            if (this.$metrika) this.$metrika.reachGoal('pass_free_lesson');
            if (isGtag) this.$gtag.event(`pass_free_lesson_${params.sectionId}`, {'event_category': 'user'})
            if (this.$metrika) this.$metrika.reachGoal(`pass_free_lesson_${params.sectionId}`);
          }
          break
        case 'interpreterSuccessfullyLoaded':
          this.$store.dispatch('innerActions/register', 'interpreterSuccessfullyLoaded')
          break
        default:
          throw Error('Unknown inner action')
      }
    }
  }
}