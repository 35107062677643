import Vue from 'vue'

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import App from './App.vue'
import './plugins/axios'
import './plugins/Constants'
import './plugins/interpreter/InterpreterPlugin'
import './directives/resizable'
import './directives/hljs'
import './directives/emodji'
import './directives/plyr'
import './plugins/vhotkey'
import './plugins/clipboard'
import './plugins/gtag'
import './plugins/ym'
import './plugins/websocket';

import vuetify from './plugins/vuetify';
import store from './store'
import router from './router'
import i18n from './plugins/i18n'
import timeago from './plugins/timeago'
import moment from './plugins/moment'
import "animate.css"
import "plyr/dist/plyr.css";

import './styles.scss';

import VuetifyConfirm from "vuetify-confirm";
Vue.use(VuetifyConfirm, { vuetify: vuetify })

Vue.config.productionTip = false

if (process.env.VUE_APP_APPLICATION_MODE === 'letpy.com') {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    beforeSend: (event, hint) => {
      if (hint.originalException && hint.originalException.response && hint.originalException.response.status === 403) {
        // drop 403 http exception
        return null;
      }

      if (process.env.NODE_ENV !== 'production') {
        console.error(hint.originalException || hint.syntheticException);
        return null; // this drops the event and nothing will be sent to sentry
      }
      return event;
    },

    release: `letpy-app@${process.env.VUE_APP_VERSION}`,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["letpy.com", "localhost"],
        shouldCreateSpanForRequest: url => {
          // Do not create spans for outgoing requests to a yandex-metrika and gtag
          return !(url.match(/mc.yandex.ru/) || (url.match(/google-analytics.com/)));
        },
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE
  });
}

new Vue({
  created() {
    this.$axios.interceptors.response.use(
        (response) => {
          if (response.headers['product-version'] && (response.headers['product-version'] !== process.env.VUE_APP_VERSION)) {
            console.warn(`Versions didn't match: app-${process.env.VUE_APP_VERSION}, letpy-${response.headers['product-version']}`)
            let updateLater = this.$store.state.notifications.update.later
            let show = this.$store.state.notifications.update.show
            if (!updateLater && !show)
              this.$store.dispatch('notifications/updatesAvailable')
          }
          return response;
        },
        (error) => {
          if (error.response && error.response.status === 403) {
            window.location.href = process.env.VUE_APP_SIGN_IN_PATH
            return Promise.reject(error);
          } else if (error.response && error.response.status === 500) {
            this.$store.dispatch('notifications/show', {
              text: this.$t('serverError'),
              color: 'red'
            });
            return Promise.reject(error);
          } else {
            return Promise.reject(error);
          }
        }
    );
  },
  vuetify,
  store,
  router,
  i18n,
  timeago,
  moment,
  render: h => h(App)
}).$mount('#app')