import Vue from 'vue'
import hljs from "highlight.js";

Vue.directive('hljs', {
  inserted: function(el) {
    el.querySelectorAll('code.code:not(.no-hljs)').forEach(block => {
      hljs.highlightElement(block);
    });
    el.querySelectorAll('code.inline:not(.no-hljs)').forEach(block => {
      hljs.highlightElement(block);
    });


    el.querySelectorAll('pre > code:not(.hljs)').forEach(block => {
      block.classList.add('code');
      if (block.classList.length === 1) {
        block.classList.add('python');
      }
      hljs.highlightElement(block);
    });

    //el.querySelectorAll('code:not(.hljs)').forEach(block => {
    //  block.classList.add('inline');
    //});
  },

  componentUpdated: function(el) {
    el.querySelectorAll('code.code:not(.no-hljs):not(.hljs)').forEach(block => {
      hljs.highlightElement(block);
    });
    el.querySelectorAll('code.inline:not(.no-hljs):not(.hljs)').forEach(block => {
      hljs.highlightElement(block);
    });

    el.querySelectorAll('pre > code:not(.hljs)').forEach(block => {
      block.classList.add('code');
      if (block.classList.length === 1) {
        block.classList.add('python');
      }
      hljs.highlightElement(block);
    });

    //el.querySelectorAll('code:not(.hljs)').forEach(block => {
    //  block.classList.add('inline');
    //});
  }
})