<template>
  <ProseContainer>
    <template v-for="(line, i) in contentLines">
      <div v-if="line.code" class='relative' :key="`content-line-${i}`">
        <pre v-html="line.html"></pre>
        <v-btn
               v-clipboard:copy="line.code"
               v-clipboard:success="() => { $store.dispatch('notifications/show', {'text': $t('CopiedToClipboard')}) }"
               absolute
               elevation="0"
               fab
               right
               top
               x-small
               color="transparent"
               class="mt-5 -mr-2 opacity-75"
        >
          <v-icon small>mdi-content-copy</v-icon>
        </v-btn>
      </div>
      <component
        v-else-if="componentKeys.includes(line.tag)"
        v-emoji
        :is="line.tag"
        v-bind='line.props'>
      </component>
      <component
        :key="`content-line-${i}`"
        v-else-if='htmlTags.includes(line.tag)' v-emoji :is="line.tag"
        v-html=line.html :class="line.classes" ></component>

      <div v-else class='bg-red-700 p-4 rounded-xl text-center'>
        Unknown tag: {{ line.tag }}
      </div>

    </template>

  </ProseContainer>
</template>

<script>
import ProseContainer from '@/components/ProseContainer.vue'
import TestMarkdownComponent from '@/views/lessons/TestMarkdownComponent.vue'
import _ from 'lodash';

const components = {
  TestMarkdownComponent
}

export default {
  name: "ActiveContent",
  components: { ProseContainer,
    ...components
  },
  props: ['content', ],
  data: () => {
    return {
      htmlTags: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'ul', 'ol', 'li', 'a', 'img', 'blockquote', 'pre', 'code', 'table', 'tr', 'th', 'td', 'em', 'strong', 'span', 'div', 'hr', 'br'],
      componentKeys: Object.keys(components).map(key => _.kebabCase(key))
    }
  },
  computed: {
    contentLines() {
      let doc = new DOMParser().parseFromString(this.content, "text/html");
      let result = [];
      for (let i = 0; i < doc.children[0].children[1].children.length; i++) {
        let el = doc.children[0].children[1].children[i];
        let item = {
          html: el.innerHTML,
          tag: el.tagName.toLowerCase(),
          classes: el.className,
          props: Array.from(el.attributes).reduce((acc, attr) => {
            try {
              acc[attr.name] = JSON.parse(attr.value);
            } catch(e) {
            }
            return acc;
          }, {}),
        }
        if ((el.tagName === 'PRE') && el.querySelector('code.python')) {
          item.code = el.querySelector('code.python').innerText;
        } else if ((el.tagName === 'PRE') && el.querySelector('code.language-python')) {
          item.code = el.querySelector('code.language-python').innerText;
        } else if ((el.tagName === 'PRE') && el.querySelector('code')) {
          item.code = el.querySelector('code').innerText;
        }
        result.push(item);
      }
      return result;
    }
  }
}
</script>
