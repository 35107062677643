import MessagesService from "@/services/MessagesService";

export const namespaced = true

export const state = {
  messages: []
}

export const mutations = {
  SET_MESSAGES(state, messagesArray) {
    state.messages = messagesArray.map((item) => {
      item['isProfileOpened'] = false
      return item
    })
  },

  ADD_MESSAGE(state, messageObject) {
    messageObject['isProfileOpened'] = false
    state.messages.push(messageObject)
  },

  UPDATE_MESSAGE(state, newMessageObject) {
    let message = state.messages.find(message => message.id === newMessageObject.id)
    newMessageObject['isProfileOpened'] = false
    Object.assign(message, newMessageObject)
  },

  DELETE_MESSAGE(state, message) {
    let index = state.messages.indexOf(message)
    state.messages.splice(index, 1)
  }
}

export const actions = {
  fetch({ commit }, params) {
    return MessagesService.get(undefined, params).then((response) => {
      commit('SET_MESSAGES', response.data)
      return response
    })
  },

  add({ commit }, data) {
    return MessagesService.post(data).then((response) => {
      commit('ADD_MESSAGE', response.data)
      return response
    })
  },

  update({ commit }, data) {
    return MessagesService.patch({'text': data.text}, data.id).then((response) => {
      commit('UPDATE_MESSAGE', response.data)
      return response
    })
  },

  delete({ commit }, message) {
    return MessagesService.delete(message.id).then(() => {
      commit('DELETE_MESSAGE', message)
    })
  }
}

export const getters = {

}
