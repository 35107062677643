<template>
  <section>
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="primary"
      height="1"
    ></v-progress-linear>

    <v-list v-if="bookmarks.list.length > 0">
      <v-list-item
        v-for="item in bookmarks.list"
        :key="`${item.id}`"
        :to="`/bookmarks/${item.id}`"
      >
        <v-list-item-content>
          <v-list-item-title>{{ item.title }} <span>#<span v-text="item.number ? item.number: $t('deletedLessonNumber')"></span></span></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <section class="text-center pa-16" v-else-if="(bookmarks.list.length === 0) && !loading">
      <h3 class="text-h5 grey--text">{{ $t("There are no bookmarks yet" ) }}</h3>
      <p class="grey--text pa-2">{{ $t('You can add any completed lesson to bookmarks for quick access') }}</p>

      <v-icon size="64" class="grey--text">mdi-bookmark-multiple-outline</v-icon>
    </section>
  </section>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: 'bookmarks-titles-view',

  data: () => ({
    loading: true,
  }),

  activated() {
    this.fetch()
  },

  created() {
    this.fetch()
  },

  computed: {
    ...mapState(['bookmarks'])
  },

  methods: {
    fetch() {
      this.loading = true;
      this.$store.dispatch('bookmarks/fetchBookmarks').finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>
.v-list-item__title > span {
  opacity: 0.5;
}
</style>

