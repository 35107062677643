<template>
  <nav
      class="v-navigation-bottom-drawer"
      :style="{'left': '0', 'right': `${right}px`, 'width': `${width}!important`, 'transition': settings.UI.editor.transition}"
  >
    <div class="v-navigation-bottom-drawer__border"></div>
    <div style="width: 100%; overflow-x: auto" @click="$focusOnInputElement();">
      <ul class="tabs">
        <li>
          {{ $t('Result') }}
        </li>
        <li :class="showDebugger ? ['clickable', 'pressed'] : ['clickable']" @click="showDebugger = !showDebugger">
          {{ $t('Debugger') }}
        </li>
      </ul>
    </div>

    <div class="d-flex">
      <div :style="{'height': `${bottom - 35}px`, 'overflow': 'hidden', 'width': showDebugger ? '50%' : '100%'}" class='stdout'>
        <div id="terminal-container" ref="terminalContainer" :style="{'height': `${terminalHeight}px`, 'overflow': 'hidden', 'width': '100%'}"></div>
        <div ref='friendlyTraceback'>
        <v-alert text type="error" v-if="$interpreter.friendlyTraceback">
          <div>
            <div v-if="friendlyTracebackMarkdownParsed.generic" v-html="friendlyTracebackMarkdownParsed.generic"></div>
            <div v-if="friendlyTracebackMarkdownParsed.cause" v-html="friendlyTracebackMarkdownParsed.cause"></div>
          </div>
        </v-alert>
        </div>
      </div>
      <!--div ref="stdout" class="stdout"  :style="{'height': `${bottom - 35}px`, 'overflow-y': 'auto', 'width': showDebugger ? '50%' : '100%'}">
        <pre v-html="$stdout"></pre>
        <v-alert text
          type="error"
          v-if="$interpreter.friendlyTraceback">
          <div>
            <div v-if="friendlyTracebackMarkdownParsed.generic" v-html="friendlyTracebackMarkdownParsed.generic"></div>
            <div v-if="friendlyTracebackMarkdownParsed.cause" v-html="friendlyTracebackMarkdownParsed.cause"></div>
          </div>
        </v-alert>
      </div-->

      <div v-if="showDebugger" style="width: 50%">
        <div v-if="!showDebuggerInfo" class="debugger"
             :style="{'height': `${bottom - 35}px`, 'overflow-y': 'auto'}">
          <div v-for="(item, i) in locals" style="margin: 0;padding: 0;" :key="`locals-${i}`">
            <span class="primary--text">{{ item.name }}</span> = {<span class="grey--text">{{ item.type }}</span>}
            {{ item.value }}
          </div>
        </div>
        <div v-else class="debuggerInfo font-weight-regular subheading pa-10"
             :style="{'height': `${bottom - 35}px`, 'overflow-y': 'auto'}">
          <div style="max-width: 400px;">
            <p v-html="$t('DebuggerInfo1')"></p>
            <p v-if="isDebuggerAvailable" v-html="$t('DebuggerInfo2')"></p>
            <p v-else v-html="$t('DebuggerInfo3', {'url': $router.resolve({name: 'Subscription'}).href})"></p>
          </div>
        </div>
      </div>
    </div>

  </nav>
</template>

<script>
import {mapState} from "vuex";
import SizeMixin from "@/mixins/SizeMixin";
import Interpreter from "@/plugins/interpreter/Interpreter";
import {marked} from "marked";
import { Terminal } from '@xterm/xterm'
import { FitAddon } from '@xterm/addon-fit';
import { WebLinksAddon } from '@xterm/addon-web-links';
import { PencilLight, PencilDark } from 'xterm-theme';
import '@xterm/xterm/css/xterm.css'

export default {
  name: 'bottom-drawer',
  mixins: [SizeMixin],
  data: () => ({
    locals_: [],
    showDebugger: false,
    showDebuggerInfo: true,
  }),

  mounted() {
    const terminalContainer = this.$refs.terminalContainer;
    // Initialize the Terminal
    this.terminal = new Terminal({
      convertEol: true,
      cursorBlink: true,
      fontFamily: 'Roboto Mono, Monaco, Menlo, Ubuntu Mono, Consolas, source-code-pro, monospace',
      fontSize: this.settings.preferences.fontSize.value,
      theme: this.settings.preferences.darkTheme.value ? PencilDark : PencilLight
    });
    // hide cursor
    this.terminal.write('\x1b[?25l');
    this.fitAddon = new FitAddon();
    this.terminal.loadAddon(this.fitAddon);
    this.terminal.loadAddon(new WebLinksAddon());
    this.terminal.open(terminalContainer);  // Attach to the DOM element
    this.fitAddon.fit();
    Interpreter.terminal = this.terminal;
  },

  computed: {
    ...mapState(['settings', 'user']),
    terminalHeight() {
      if ((this.$interpreter.friendlyTraceback !== null) && this.$refs.friendlyTraceback)  {
        const h = this.bottom - 35 - this.$refs.friendlyTraceback.offsetHeight - 20;
        return h < 120 ? 120 : h;
      } else {
        return this.bottom - 35
      }
    },
    width() {
      return `calc(100% - ${this.right}px)`
    },

    isDebuggerAvailable() {
      if (process.env.VUE_APP_APPLICATION_MODE === 'letpy.com') {
        return this.user.paymentInfo.hasPremiumAccess;
      } else {
        return true;
      }
    },

    locals() {
      if (this.$interpreter.debug) {
        return this.$interpreter.debug.locals;
      } else {
        return this.locals_;
      }
    },

    friendlyTracebackMarkdownParsed() {
      return {
        cause: this.$interpreter.friendlyTraceback.cause ? marked.parse(this.$interpreter.friendlyTraceback.cause) : '',
        generic: this.$interpreter.friendlyTraceback.cause ? marked.parse(this.$interpreter.friendlyTraceback.generic) : '',
        message: this.$interpreter.friendlyTraceback.cause ? marked.parse(this.$interpreter.friendlyTraceback.message) : '',
      }
    }
  },

  watch: {
    // '$stdout': function() {
    //   this.$nextTick(()=>{
    //     this.$refs.stdout.scrollTop = this.$refs.stdout.scrollHeight;
    //   });
    // },

    '$interpreter.isRunning': function(val) {
      if (val) {
        if (this.$interpreter.isVerification) {
          return;
        }
        this.locals_ = [];

        if (this.$interpreter.mode === 'debug') {
          this.showDebugger = true;
          this.showDebuggerInfo = false;
          this.$store.dispatch('notifications/show', {
            text: this.$t('debuggerNextStepInfo'),
            color: 'green',
            timeout: 5000
          })
        } else {
          this.showDebugger = false;
          this.showDebuggerInfo = true;
        }
      }
    },

    '$interpreter.inputInProgress': function(val) {
      if ((this.$interpreter.mode === 'debug') && val) {
        this.$store.dispatch('notifications/show', {
          text: this.$t('debuggerInputAndNextStepInfo'),
          color: 'green',
          timeout: 5000
        })
      }
    },
    'right': function() {
      this.fitAddon.fit();
    },
    'terminalHeight': function() {
      this.$nextTick(()=>{
        this.fitAddon.fit();
      });
    },
    'left': function() {
      this.fitAddon.fit();
    },
    'settings.preferences.fontSize.value': function(v) {
      this.terminal.options.fontSize = v
      this.fitAddon.fit();
    },

    'settings.preferences.darkTheme.value': function(v) {
      this.terminal.options.theme = v ? PencilDark : PencilLight
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../styles.scss";

.v-navigation-bottom-drawer {
  bottom: 0;
  position: absolute;
  margin: 0;
  z-index: 5;
}

.v-navigation-bottom-drawer__border {
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  width: 100%;
}

.v-navigation-bottom-drawer .stdout  {
  padding: 10px;
  font-weight: 300;
}

.v-navigation-bottom-drawer .stdout, .v-navigation-bottom-drawer .stdout > pre {
  font-family: "Roboto Mono", "Monaco", "Menlo", "Ubuntu Mono", "Consolas", "source-code-pro", monospace;
}

.v-navigation-bottom-drawer .debugger {
  padding: 10px;
  font-weight: 300;
  font-family: "Roboto Mono", "Monaco", "Menlo", "Ubuntu Mono", "Consolas", "source-code-pro", monospace;
}
.v-navigation-bottom-drawer .debuggerInfo {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.v-navigation-bottom-drawer ul.tabs {
  list-style: none;
  padding:0;
  height: 35px;
  display: flex;
  user-select: none;
}

.v-navigation-bottom-drawer ul.tabs > li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition: border-bottom .2s;
  border-bottom: solid 3px transparent;
  padding: 5px 25px 0 25px;
}

.v-navigation-bottom-drawer ul.tabs > li.clickable {
  cursor: pointer;
}


.v-navigation-bottom-drawer ul.tabs > .active {
  border-bottom: solid 3px var(--v-primary-base);
  transition: border-bottom .5s;
}


.theme--light {
  .v-navigation-bottom-drawer__border {
    background-color: rgba(0, 0, 0, 0.12)
  }

  .v-navigation-bottom-drawer ul.tabs {
    background-color: $background-color-light;
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
  }
  .v-navigation-bottom-drawer ul.tabs > li {
    border-right: thin solid rgba(0, 0, 0, 0.12);
  }

  .v-navigation-bottom-drawer ul.tabs > li.pressed {
    background-color: darken($background-color-light, 5%);
  }
  /*
  .v-navigation-bottom-drawer ul.tabs > li.clickable:hover {
    background-color: lighten($background-color-light, 5%);
  }*/
}

.theme--dark {
  .v-navigation-bottom-drawer ul.tabs {
    background-color: $background-color-dark;
    border-bottom: thin solid rgba(255, 255, 255, 0.12);
  }
  .v-navigation-bottom-drawer ul.tabs > li {
    border-right: thin solid rgba(255, 255, 255, 0.12);
  }

  .v-navigation-bottom-drawer ul.tabs > li.pressed {
    background-color: darken($background-color-dark, 5%);
  }

  /*.v-navigation-bottom-drawer ul.tabs > li.clickable:hover {
    background-color: lighten($background-color-dark, 5%);
  }*/
  .v-navigation-bottom-drawer__border {
    background-color: rgba(255, 255, 255, 0.12);
  }
}

</style>
