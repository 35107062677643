<template>
  <v-card>
    <v-card-actions class="pa-4 pb-0">
      <div class='text-4xl'>{{ lessons.currentLesson.header }}&nbsp;<span>#<span v-text="lessons.currentLesson.number ? lessons.currentLesson.number: $t('deletedLessonNumber')"></span></span></div>
      <v-spacer></v-spacer>
      <v-tooltip left>
        <template v-slot:activator="{on, attrs}">
          <v-btn
              icon
              v-on="on"
              v-bind="attrs"
              @click="bookmarkToggle()"
              :color="((lessons.currentLesson.isBookmarked) ? 'primary':null)"
              :loading="bookmarkLoading"
          >
            <v-icon v-if="!lessons.currentLesson.isBookmarked">mdi-bookmark-outline</v-icon>
            <v-icon v-else>mdi-bookmark-check-outline</v-icon>
          </v-btn>
        </template>
        <span v-if="!lessons.currentLesson.isBookmarked">{{ $t("Bookmark it") }}</span>
        <span v-else>{{ $t("Delete bookmark") }}</span>
        </v-tooltip>
    </v-card-actions>

    <div class="body-1 px-4 pa-4" v-hljs v-plyr>
      <active-content :content="lessons.currentLesson.text"></active-content>
    </div>
    <lesson-quiz @onResultChanged="onQuizResultChanged" v-if="lessons.currentLesson.isQuiz" :items="lessons.currentLesson.quizItems"></lesson-quiz>
    <v-card-actions>
      <v-btn
          v-if="lessons.currentLesson.prev !== null"
          :to="`/course/${this.$route.params.courseId}/${lessons.currentLesson.prev.sectionSlug}/${lessons.currentLesson.prev.slug}`"
          icon
      ><v-icon>mdi-arrow-left</v-icon></v-btn>

      <v-spacer/>
        <v-btn
          :disabled="lessons.verificationInProgress || $interpreter.loading"
          @click="verify"
          v-if="lessons.currentLesson.isPractice || lessons.currentLesson.isQuiz"
          rounded
          color="green"
          class="check-button"
          x-large
          outlined
        >
          {{ $t('Check') }}
        </v-btn>
      <v-btn
            v-if="lessons.currentLesson.next !== null"
            :loading="nextLoading"
            text
            rounded
            @click="next"
            data-cy="next-lesson"
            x-large
        >{{ $t("Next") }}</v-btn>

    </v-card-actions>

    <div ref="resultContainer" class="resultContainer">
      <transition name="fade" mode="out-in" appear>
        <v-card-text class="body-1" v-if="verificationInProgress">
          <transition name="fade" mode="out-in" appear>
            <div class="text-center" v-if="!verification">
              <verification-progress-animation :hasError="hasError"></verification-progress-animation>
            </div>

            <v-alert v-else-if="verification.result" text color="success" border="left" data-cy="verification-success">
              <div v-html="verificationText" v-hljs style="padding-top: 15px;"></div>
            </v-alert>

            <v-alert v-else text color="error" border="left" data-cy="verification-error">
              <div v-html="verificationText" v-hljs style="padding-top: 15px;"></div>
            </v-alert>
          </transition>

        </v-card-text>
      </transition>
    </div>

  </v-card>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import ActiveContent from '@/components/ActiveContent.vue'
import LessonQuiz from '@/views/lessons/Quiz.vue'
import Interpreter from '@/plugins/interpreter/Interpreter'
import InnerActionsMixin from '@/mixins/InnerActionsMixin'
import VerificationProgressAnimation from '@/components/VerificationProgressAnimation.vue'
import { marked } from 'marked'

export default {
  name: 'LessonContent',
  components: { VerificationProgressAnimation, LessonQuiz, ActiveContent },
  mixins: [InnerActionsMixin],
  data: () => ({
    bookmarkLoading: false,
    quizResult: null,
    nextLoading: false,
    verification: null,
    verificationInProgress: false,
    hasError: false,
    verificationStartedAt: null,
  }),

  computed: {
    ...mapState(['lessons', 'settings', 'navigation', 'user']),
    ...mapGetters(['lessons/getLastPassedLesson', 'lessons/getDefinition', 'lessons/getCourseId', 'userFiles/current']),
    verificationText() {
      return marked.parse(this.verification.text);
    },

    currentFile() {
      return this['userFiles/current'];
    }
  },
  methods: {
    showSnackbar(title, content) {
      this.$store.dispatch('notifications/show', {'text': `<div class='text-lg pb-2'>${title}</div> ${content}`, color: 'error', timeout: 10000})
    },

    next() {
      let currentLesson = this.lessons.currentLesson;
      if (!currentLesson.isPractice && !currentLesson.isQuiz) {
        if (currentLesson.prev && !currentLesson.prev.isPassed && !currentLesson.prev.canBeSkipped) {
          this.showSnackbar(this.$t('The previous lesson is not passed'), this.$t('prevLessonNotPassedText'));
        } else {
          this.nextLoading = true;
          this.$store.dispatch('lessons/passLesson', {
            lessonId: currentLesson.slug,
            sectionId: currentLesson.sectionSlug,
            courseId: this.$route.params.courseId // TODO: Replace with currentLesson property
          }).then(() => {
            this.registerInnerAction('passLesson', {
              'isPaid': currentLesson.isPaid,
              'sectionId': currentLesson.sectionSlug
            })
            this.nextLoading = false;
            this.$router.push({
              name: 'lessonContent', params: {
                'sectionId': currentLesson.next.sectionSlug,
                'lessonId': currentLesson.next.slug,
                'courseId': this.$route.params.courseId // TODO: Replace with currentLesson property
              }
            });
          });
        }
      } else {
        if (!currentLesson.isPassed && !currentLesson.canBeSkipped) {
          this.showSnackbar(this.$t('Verification is required'), currentLesson.isPractice ? this.$t('practiceVerificationNeededText') : this.$t('quizVerificationNeededText'))
        } else {
          this.$router.push({
            name: 'lessonContent',
            params: {
              'sectionId': currentLesson.next.sectionSlug,
              'lessonId': currentLesson.next.slug,
              'courseId': this.$route.params.courseId // TODO: Replace with currentLesson property
            }
          });
        }
      }
    },

    onQuizResultChanged(payload) {
      this.quizResult = payload;
    },

    bookmarkToggle() {
      this.bookmarkLoading = true
      let data = {
        'sectionId': this.lessons.currentLesson.sectionSlug,
        'lessonId': this.lessons.currentLesson.slug,
        'courseId': null  // this.lessons.currentLesson.courseId
      }

      this.$store.dispatch(`bookmarks/${((!this.lessons.currentLesson.isBookmarked) ? 'add':'remove')}`, data).then(() => {
        this.$store.dispatch('lessons/isBookmarkedToggle', this.lessons.currentLesson)
        this.$store.dispatch(
            'notifications/show',
            {
              show: true,
              text: this.$t(`Bookmark ${((this.lessons.currentLesson.isBookmarked) ? 'added':'removed')}`),
              color: 'primary'
            }
        )
      }).finally(() => {
        this.bookmarkLoading = false
      })
    },

    showResultContainer() {
      this.$refs['resultContainer'].style.height = '200px';
    },

    startAnimationQueue() {
      this.$store.dispatch('lessons/startVerification');
      // Interpreter.hideCanvasWindow(); // is that still needed?
      new Promise(function(resolve) {
        setTimeout(() => resolve(), 200);  // At first, we show result container
      }).then(() => {
        // after that we show verification animation
        this.verificationInProgress = true;
        this.hasError = false;
        // scroll to result container
        this.$refs.resultContainer.scrollIntoView({behavior: 'smooth', block: 'center'});

      });
    },

    stopInterpreterIfRunning(response) {
      if (this.$interpreter.isRunning) {
        Interpreter.stopExecution();
        return new Promise((resolve) => {
          let interval = setInterval(() => {
            if (!this.$interpreter.isRunning) {
              clearInterval(interval);
              resolve(response);
            }
          }, 300)
        })
      } else {
        return new Promise((resolve) => {
          resolve(response);
        })
      }
    },

    waitShowResultContainerAnimation(response) {
      return new Promise((resolve) => {
        let timeout = 300 - (new Date().getTime() - this.verificationStartedAt)
        setTimeout(() => resolve(response.data), timeout > 0 ? timeout : 0);
      });
    },

    verifyCurrentFile(response) {
      return new Promise((resolve, reject) => {
        Interpreter.verifyFile(this.currentFile, this.lessons.currentLesson).then((result)=>{
          resolve([result, response])
        }, (error)=> {
          reject([error, response])
        });
      });
    },

    handleVerificationSuccess(result) {
      let lessonVerificationHistory = result[1];
      let verificationResult = result[0];
      return Promise.all([
          this.updateLessonVerificationHistory(lessonVerificationHistory, true),
          this.$store.dispatch('lessons/passLesson', lessonVerificationHistory)
      ]).then(() => {  // [verificationHistoryResponse, passLessonResponse]
        let currentLesson = this.lessons.currentLesson;
        this.registerInnerAction('passLesson', {
          'isPaid': currentLesson.isPaid,
          'sectionId': currentLesson.sectionSlug
        })

        let timeout = 2000 - (new Date().getTime() - this.verificationStartedAt)
        setTimeout(() => {
          this.verification = verificationResult;
          this.$store.dispatch('lessons/finishVerification');
        }, timeout > 0 ? timeout : 0)
      });
    },

    handleVerificationFail(error) {
      let lessonVerificationHistory = error[1];
      let verificationResult = error[0];
      return this.updateLessonVerificationHistory(lessonVerificationHistory, false).then(() => {
        this.hasError = true;
        setTimeout(() => {
          this.verification = verificationResult;
          this.$store.dispatch('lessons/finishVerification');
        }, 500)
      });
    },

    updateLessonVerificationHistory(lessonVerificationHistory, status) {
      lessonVerificationHistory.status = status ? 1 : 0;
      lessonVerificationHistory.output = {
        'stdout': '' //this.$stdout  // TODO: replace with bot-stdout
      }
      return this.$store.dispatch('lessons/updateLessonVerificationHistory', lessonVerificationHistory)
    },

    async verify() {
      if (this.lessons.currentLesson.prev && !this.lessons.currentLesson.prev.isPassed && !this.lessons.currentLesson.prev.canBeSkipped) {
        this.showSnackbar(this.$t('The previous lesson is not passed'), this.$t('prevLessonPassingNeededForVerificationText'))
        return;
      }
      if (this.lessons.currentLesson.isPractice && this.currentFile === undefined) {
        this.showSnackbar(this.$t('Open a file with the code'), this.$t('currentFileNeededForVerificationText'))
        return;
      } else if (this.lessons.currentLesson.isPractice && this.currentFile.title.split('.').pop() !== 'py') {
        this.showSnackbar(this.$t('Open a file with the code'), this.$t('currentFileIsNotPythonProgramText'));
        return;
      }

      this.verification = null;
      this.showResultContainer();
      this.startAnimationQueue();
      this.verificationStartedAt = new Date().getTime();
      if (this.lessons.currentLesson.isPractice) {
        this.$store.dispatch(
            'lessons/createLessonVerificationHistory', {
               'sectionId': this.$route.params.sectionId,
               'lessonId': this.$route.params.lessonId,
               'courseId': this.$route.params.courseId,
               'status': 100,
               'source': this.currentFile.content
            }
        )
        .then(this.stopInterpreterIfRunning)
        .then(this.waitShowResultContainerAnimation)
        .then(this.verifyCurrentFile)
        .then(this.handleVerificationSuccess, this.handleVerificationFail)
      } else {
        if (this.quizResult.result) {
          this.$store.dispatch('lessons/passLesson', {
            'sectionId': this.$route.params.sectionId,
            'lessonId': this.$route.params.lessonId,
            'courseId': this.$route.params.courseId
          }).then(() => {
            let currentLesson = this.lessons.currentLesson;
            this.registerInnerAction('passLesson', {
              'isPaid': currentLesson.isPaid,
              'sectionId': currentLesson.sectionSlug
            })

            let timeout = 2000 - (new Date().getTime() - this.verificationStartedAt)
            setTimeout(()=> {
              this.verification = this.quizResult;
              this.$store.dispatch('lessons/finishVerification');
            }, timeout > 0 ? timeout : 0)
          })
        } else {
          setTimeout(()=>{
            this.hasError = true;
            setTimeout(() => {
              this.verification = this.quizResult;
              this.$store.dispatch('lessons/finishVerification');
            }, 500)
          }, 1000)
        }
      }

      // try {
      //   const result = await Interpreter.verifyFile_(this.currentFile, this.lessons.currentLesson);
      //   console.log(result)
      //
      // } catch (e) {
      //   console.log(e, 'error')
      // }
    }
  }
}
</script>
<style>
div.text-4xl > span {
  opacity: 0.5;
}
</style>