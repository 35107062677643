<template>
  <v-navigation-drawer
      app
      temporary
      v-model="showMenuInner"
      width="500"
      right
  >
    <v-toolbar dense class="pr-2">
      <v-toolbar-title>
        {{ $t('Notifications') }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <transition name="fade">
      <v-chip
          v-if="activity.newNotificationsCountAfterFetch"
          color="red"
          text-color="white"
          @click="reFetch()"
          :disabled="reFetchInProgress"
      >
        <v-avatar left class="red darken-4">
          {{ activity.newNotificationsCountAfterFetch }}
        </v-avatar>
        {{ $tc('newNotificationsCount', activity.newNotificationsCountAfterFetch) }}
        <v-icon right>
          mdi-refresh
        </v-icon>
      </v-chip>
      </transition>
      <v-spacer></v-spacer>
      <v-btn small icon @click="showMenuInner = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-divider></v-divider>

    <v-progress-linear
        v-if="loading"
        indeterminate
        color="primary"
        height="1"
    ></v-progress-linear>
    <v-card flat v-else class="notifications-container">
      <div
          v-for="item in activity.notifications"
          :key="`item-activity-${item.id}`"
          :class=getClassesForItem(item)
          @click="go(item)"
          style="position: relative"
      >

        <div v-if="item.actionType === $constants.ACTIVITY_ASK_QUESTION">
          <div class="d-flex">
            <v-avatar color="primary" size="48">
              <v-img
                  v-if="item.question.user.photo"
                  :src="item.question.user.photo"
              ></v-img>
              <span v-else class="white--text headline"
                    style="font-size: 1rem!important;">{{ item.question.user.photoPlaceholder.toUpperCase() }}</span>
            </v-avatar>
            <div class="pl-3 body-1">
              <div>
                <strong>{{ item.question.user.fullName }} </strong>
                <span class="grey--text" v-if="item.question.user.gender === 'female'" v-html="$t('askedAQuestionFemale')">
                </span>
                <span class="grey--text" v-else v-html="$t('askedAQuestionMale')"></span>

                <i> "{{ item.question.title.trim() }}" </i>
                <span class="grey--text" v-if="item.question.lesson.isPractice" v-html="$t('inPractice')">
                </span>
                <span class="grey--text" v-else-if="item.question.lesson.isQuiz" v-html="$t('inQuiz')">
                </span>
                <span class="grey--text" v-else v-html="$t('inLesson')">
                </span>
                <span> "{{ item.question.lesson.title }}&nbsp;#<span v-text="item.question.lesson.number ? item.question.lesson.number: $t('deletedLessonNumber')"></span>"</span>
              </div>

              <div class="body-2 mt-1">
                <timeago :datetime="item.question.createdAt" :auto-update="60"></timeago>
              </div>
            </div>
          </div>
        </div>

        <div v-else-if="item.actionType === $constants.ACTIVITY_ANSWER_TO_QUESTION">
          <div class="d-flex">
            <v-avatar color="primary" size="48">
              <v-img
                  v-if="item.answer.user.photo"
                  :src="item.answer.user.photo"
              ></v-img>
              <span v-else class="white--text headline"
                    style="font-size: 1rem!important;">{{ item.answer.user.photoPlaceholder.toUpperCase() }}</span>
            </v-avatar>

            <div class="pl-3 body-1">
              <div>
                <strong>{{ item.answer.user.fullName }} </strong>
                <span class="grey--text" v-if="item.question.user.gender === 'female'" v-html="$t('answerQuestionFemale')">
                </span>
                <span class="grey--text" v-else v-html="$t('answerQuestionMale')"></span>

                <i> "{{ item.question.title.trim() }}" </i>
                <span class="grey--text" v-if="item.question.lesson.isPractice" v-html="$t('inPractice')">
                </span>
                <span class="grey--text" v-else-if="item.question.lesson.isQuiz" v-html="$t('inQuiz')">
                </span>
                <span class="grey--text" v-else v-html="$t('inLesson')">
                </span>
                <span> "{{ item.question.lesson.title }}&nbsp;#<span v-text="item.question.lesson.number ? item.question.lesson.number: $t('deletedLessonNumber')"></span>"</span>

                <div class="body-2 mt-1">
                  <timeago :datetime="item.answer.createdAt" :auto-update="60"></timeago>
                </div>
              </div>
            </div>
          </div>
        </div>
        <v-icon v-if="!item.isViewed" color="red" style="position: absolute;top:0;left:0">mdi-circle-small</v-icon>
      </div>
      <infinite-loading @infinite="infiniteHandler" ref="infiniteLoading">
        <div slot="spinner" class="pa-10">
          <v-progress-circular
            :width="2"
            indeterminate
          ></v-progress-circular>
        </div>
      </infinite-loading>
    </v-card>
  </v-navigation-drawer>
</template>
<script>
import ShowMixin from "@/mixins/ShowMixin";
import {mapState} from "vuex";
import InfiniteLoading from 'vue-infinite-loading';
import { BASE_COURSE_NAME } from '@/legacy'
export default {
  name: 'activity-drawer',
  mixins: [ShowMixin],
  components: {InfiniteLoading},

  data: () => ({
    loading: false,
    interval: null,
    reFetchInProgress: false
  }),

  computed: {
    ...mapState(['activity']),
  },

  mounted() {
    this.loading = true;
    this.$store.dispatch('activity/fetch').finally(() => {
      this.loading = false;
      this.$store.dispatch('activity/fetchNewNotificationsCount');

      /*this.interval = setInterval(() => {
        this.$store.dispatch('activity/fetchNewNotificationsCount');
      }, 60 * 1000);*/
    });
    this.$socket.subscribe(this.onSocketEvent)
  },

  destroyed() {
    this.$socket.unsubscribe(this.onSocketEvent)
    // clearInterval(this.interval)
  },

  methods: {
    onSocketEvent(event) {
      if (event.type === 'update.ui') {
        this.$store.dispatch('activity/fetchNewNotificationsCount');
      }
    },

    getClassesForItem(item) {
      const courseId = item.question.courseId || BASE_COURSE_NAME;
      let route = {
        name: 'questionDetail_', params: {
          sectionId: item.question.sectionId,
          lessonId: item.question.lessonId,
          courseId: courseId,
          id: item.question.id
        }
      }

      if (this.$router.resolve(route).resolved.fullPath === this.$router.currentRoute.fullPath) {
        return "pa-4 notification-item notification-item-active"
      }

      return "pa-4 notification-item"
    },

    reFetch() {
      this.loading = true;
      this.reFetchInProgress = true;
      this.$store.dispatch('activity/fetch').finally(() => {
        this.loading = false;
        this.$nextTick(() => {
          this.$refs.infiniteLoading.stateChanger.reset();
        });
        this.$store.dispatch('activity/fetchNewNotificationsCount').finally(() => {
          this.reFetchInProgress = false;
          this.$store.dispatch('activity/setViewedActivityAt');
        });
      });
    },

    infiniteHandler($state) {
      this.$store.dispatch('activity/fetchAfter').then((notifications) => {
        if (notifications.length === 0) {
          $state.complete();
        }
      }, () => {
        // change state to prevent infinite axios requests
        $state.complete();
      }).finally(()=> {
        $state.loaded();
      });
    },

    go(item) {
      const courseId = item.question.courseId || BASE_COURSE_NAME;
      item.isViewed = true;
      this.$store.dispatch('settings/updateUI', {'helperDrawer': true});
      let route = {
        name: 'questionDetail_', params: {
          sectionId: item.question.sectionId,
          lessonId: item.question.lessonId,
          courseId: courseId,
          id: item.question.id
        }
      }

     if (this.$router.resolve(route).resolved.fullPath === this.$router.currentRoute.fullPath) {
       return;
     }

      this.$router.push({
        name: 'questionDetail_', params: {
          sectionId: item.question.sectionId,
          lessonId: item.question.lessonId,
          courseId: courseId,
          id: item.question.id
        }
      })
    }
  },

  watch: {
    showMenuInner(isOpened) {
      if (isOpened) {
        this.$store.dispatch('activity/fetchNewNotificationsCount').finally(() => {
          this.$store.dispatch('activity/setViewedActivityAt')
        });
      } else {
        this.$store.dispatch('activity/fetchNewNotificationsCount');
      }
    },
  }
}

</script>
<style>
.notifications-container {
  height: calc(100% - 50px);
  overflow-y: scroll
}
</style>