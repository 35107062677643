<template>
  <section>
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="primary"
      height="1"
    ></v-progress-linear>

    <transition v-else name="fade" mode="out-in" appear>
      <v-card flat v-if="bookmarks.current !== null" :class="settings.preferences.darkTheme.value ? 'monokai-sublime-theme' : 'github-theme'">
        <v-card-actions class="pa-4 pb-0">
          <div class="title">{{ bookmarks.current.title }}&nbsp;<span>#<span v-text=" bookmarks.current.number ?  bookmarks.current.number: $t('deletedLessonNumber')"></span></span></div>
          <v-spacer/>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="grey"
                v-bind="attrs"
                v-on="on"
                icon
                :loading="deleteLoading"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list width="200px">
              <v-list-item :to="(bookmarks.current.courseId === null) ? `/lessons/${bookmarks.current.sectionId}.${bookmarks.current.lessonId}` : `/course/${bookmarks.current.courseId}/${bookmarks.current.sectionId}/${bookmarks.current.lessonId}`">
                <v-list-item-title>{{ $t('Go to the lesson page') }}</v-list-item-title>
                <v-list-item-action><v-icon>mdi-chevron-right</v-icon></v-list-item-action>
              </v-list-item>

              <v-list-item @click="confirmDelete = true">
                <v-list-item-title>{{ $t('Delete') }}</v-list-item-title>
                <v-list-item-action><v-icon color="red lighten-2">mdi-delete</v-icon></v-list-item-action>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-card-actions>
        <v-card-text class="body-1" v-html="bookmarks.current.text" v-hljs></v-card-text>

      </v-card>

    </transition>

    <confirm-delete-dialog
      :show-menu.sync="confirmDelete"
      text="Delete this bookmark?"
      @deletionConfirmed="removeBookmark"
    ></confirm-delete-dialog>
  </section>
</template>

<script>
import {mapState} from "vuex";
import ConfirmDeleteDialog from "@/components/dialogs/ConfirmDeleteDialog";

export default {
  name: 'bookmark-content-view',
  components: {ConfirmDeleteDialog},

  data: () => ({
    loading: false,
    deleteLoading: false,
    confirmDelete: false
  }),

  created() {
    if (this.$route.name === 'bookmarkContent')
      this.fetchCurrent()
  },

  destroyed() {
    this.$store.dispatch('bookmarks/setNullBookmark')
  },

  computed: {
    ...mapState(['bookmarks', 'settings'])
  },

  methods: {
    fetchCurrent() {
      this.loading = true
      return this.$store.dispatch('bookmarks/fetchBookmarkContent', {
        'id': this.$route.params.id
      }).finally(() => {
        this.loading = false;
      })
    },

    removeBookmark() {
      let data = {'id': this.bookmarks.current.id}
      this.deleteLoading = true
      this.$store.dispatch('bookmarks/remove', data).finally(() => {
        this.deleteLoading = false
        this.$store.dispatch(
            'notifications/show',
            {
              show: true,
              text: this.$t(`Bookmark removed`),
              color: 'primary'
            }
        )
      })
    }
  },

  watch: {
    '$route'(to, from) {
      if (to.name === 'bookmarkContent') {
        if ((from.name === 'bookmarkContent') || (from.name === 'bookmarks'))  {
          this.fetchCurrent().finally(()=>{
            this.$store.dispatch('navigation/setPath', {tab: 'bookmarks', path: to.path });
          });
        }
      }
    }
  }
}
</script>
<style scoped="true">
div.title > span {
  opacity: 0.5;
}
</style>

