import BookmarkService from '@/services/BookmarkService.js'


export const namespaced = true

export const state = {
  list: [],
  current: null
}

export const mutations = {
  SET_BOOKMARKS(state, bookmabrs) {
    state.list = bookmabrs
  },

  SET_CURRENT_BOOKMARK(state, bookmark) {
    state.current = bookmark
  },

  REMOVE_BOOKMARK(state, bookmark) {
    let toDelete = state.list.filter((item) => {
      return item.lessonId === bookmark.lessonId
    })[0]
    state.list.splice(state.list.indexOf(toDelete), 1)
  }
}

export const actions = {
  add(_, data) {
    return BookmarkService.post(data)
  },

  remove({ commit }, data) {
    let url = ''
    if (data.id) {
      url = `${data.id}`
    } else {
      if (data.courseId) {
        url = `${data.courseId}/${data.sectionId}/${data.lessonId}`;
      } else {
        url = `${data.sectionId}/${data.lessonId}`;
      }
    }
    
    return BookmarkService.delete(url).then(() => {
      commit('REMOVE_BOOKMARK', data)
      commit('SET_CURRENT_BOOKMARK', null)
    })
  },

  fetchBookmarks({ commit }) {
    return BookmarkService.get().then((response) => {
      commit("SET_BOOKMARKS", response.data)
    })
  },

  fetchBookmarkContent({ commit }, data) {
    return BookmarkService.get(`${data.id}`).then((response) => {
      commit('SET_CURRENT_BOOKMARK', response.data)
    }).catch((error) => {
      if (error.response.status === 404) {
        commit('SET_CURRENT_BOOKMARK', null)
      }
    })
  },

  setNullBookmark({ commit }) {
    commit('SET_CURRENT_BOOKMARK', null)
  }
}

export const getters = {

}