<template>
  <section v-if="!filesLoading">
    <sl-vue-tree
        v-model="tree"
        ref="filesTree"
        @nodecontextmenu="showContextMenu"
        @nodedblclick="doubleClick"
        @toggle="doubleClick"
        @drop="drop"
        :allow-multiselect="false"
    >
      <template slot="toggle" slot-scope="{ node }">
          <v-icon v-if="node.isExpanded">mdi-chevron-down</v-icon>
          <v-icon v-else>mdi-chevron-right</v-icon>
      </template>

      <template slot="title" slot-scope="{ node }">
        <div class="d-flex">
          <file-icon v-if="node.isLeaf" :name="node.title" style="margin-left: 24px;"></file-icon>
          <v-icon size="20" color="blue-grey" v-else-if="node.isExpanded">mdi-folder-open</v-icon>
          <v-icon size="20" color="blue-grey" v-else>mdi-folder</v-icon>
          <span style="padding-left: 5px;opacity: 0.7">{{ node.title }}</span>
        </div>
      </template>
    </sl-vue-tree>

    <v-menu ref="contextmenu" :position-x="cursorPositionX" :position-y="cursorPositionY" v-model="contextMenuIsVisible">
      <v-list>
        <v-list-item @click="createNode('file')">
          <v-list-item-title>{{ $t('New file') }}</v-list-item-title>
          <v-list-item-action><v-icon>mdi-file</v-icon></v-list-item-action>
        </v-list-item>
        <v-list-item @click="createNode('folder')">
          <v-list-item-title>{{ $t('New folder') }}</v-list-item-title>
          <v-list-item-action><v-icon>mdi-folder</v-icon></v-list-item-action>
        </v-list-item>

        <v-list-item @click="renameNode()">
          <v-list-item-title>{{ $t('Rename') }}</v-list-item-title>
          <v-list-item-action><v-icon>mdi-rename-box</v-icon></v-list-item-action>
        </v-list-item>

        <v-list-item @click="confirmDelete = true">
          <v-list-item-title>{{ $t('Delete') }}</v-list-item-title>
          <v-list-item-action><v-icon color="red lighten-1">mdi-delete</v-icon></v-list-item-action>
        </v-list-item>
      </v-list>
    </v-menu>

    <confirm-delete-dialog
      :show-menu.sync="confirmDelete"
      :text="confirmDeleteText"
      @deletionConfirmed="removeNode"
    ></confirm-delete-dialog>
  </section>

  <v-progress-linear
    v-else
    indeterminate
    color="primary"
  ></v-progress-linear>
</template>

<script>
import SlVueTree from 'sl-vue-tree';
import {mapGetters, mapState} from 'vuex'
import ConfirmDeleteDialog from "@/components/dialogs/ConfirmDeleteDialog.vue";
import FileExtensionMixin from "@/mixins/FileExtensionMixin";
import Interpreter from "@/plugins/interpreter/Interpreter";
import FileIcon from '@/components/FileIcon.vue';

export default {
  components: {SlVueTree, ConfirmDeleteDialog, FileIcon},
  mixins: [FileExtensionMixin],

  data: () => ({
    cursorPositionX: null,
    cursorPositionY: null,
    contextMenuIsVisible: false,
    filesLoading: true,
    confirmDelete: false,
    confirmDeleteText: '',
    tree: []
  }),

  computed: {
    ...mapState(['vfs', 'user']),
    ...mapGetters({currentFile: 'vfs/currentFile'}),

    currentNode() {
      const filesTree = this.$refs.filesTree
      const node = filesTree.getSelected()[0]
      return node
    },
  },

  created() {
    this.$store.dispatch('vfs/fetchVFSTree').finally(() => {
      this.filesLoading = false;
      let openedFiles = []

      for (let file of this.vfs.files) {
        if ((file.type === 'file') && file.isOpened) {
          openedFiles.push(this.$store.dispatch('vfs/openInTab', file))
        }
      }

      Promise.all(openedFiles).then(() => {
        if (this.vfs.tabs.length > 0) {
          let tabToActivate = this.vfs.tabs[this.vfs.tabs.length - 1];
          if (this.user.info.activeVfsItemId) {
            let storedActiveTab = this.vfs.tabs.find(tab => tab.item.id === this.user.info.activeVfsItemId);
            if (storedActiveTab) {
              tabToActivate = storedActiveTab
            }
          }
          this.$store.dispatch('vfs/setActiveTab', tabToActivate.item.id);
        }
      })
    })
  },

  methods: {
    showContextMenu(node, event) {
      event.preventDefault();
      const filesTree = this.$refs.filesTree
      this.contextMenuIsVisible = true;
      this.cursorPositionX = event.clientX;
      this.cursorPositionY = event.clientY;
      filesTree.select(node.path)
    },

    createNode(type) {
      const filesTree = this.$refs.filesTree
      const node = filesTree.getSelected()[0]
      const parent = ((node.isLeaf === false) ? node['data']['id']:null)
      this.$emit('openFilesDialog', type, parent)
    },

    removeNode() {
      this.contextMenuIsVisible = false
      const filesTree = this.$refs.filesTree
      const paths = filesTree.getSelected().map(node => node.path)
      const node = filesTree.getSelected()[0]
      this.$store.dispatch('vfs/removeNode', node['data']['id'])
      filesTree.remove(paths);
    },

    renameNode() {
      this.$emit('openRenameNodeDialog', this.currentNode)
    },

    doubleClick(node) {
      let data = {
        'id': node.data.id,
        'isSelected': node['isSelected'],
      }
      if (!node.isLeaf) {
        data = {
          ...data,
          ...{
            'isOpened': !node.isExpanded,
          }
        }
      } else {
        data['isOpened'] = true
        this.$store.dispatch('vfs/openInTab', {...node.data, setActive: true});
        // TODO: We must fire focus event here instead of duplicated code from CodeEditor.vue on Focus
        if (this.$vuetify.breakpoint.mdAndDown) {
          this.$store.dispatch('settings/updateUI', {'filesDrawer': false})
        }

        if (this.$vuetify.breakpoint.smAndDown) {
          this.$store.dispatch('settings/updateUI', {'helperDrawer': false});
        }
      }
      this.$store.dispatch('vfs/updateNode', data)
      this.$store.dispatch('vfs/selectNode', data)
    },

    drop(nodes, cursor) {
      let node = nodes[0],
          fileData = node.data
      fileData['parent'] = ((cursor.node.isLeaf) ? null:cursor.node.data.id)
      this.$store.dispatch('vfs/updateNode', fileData)
    },

    buildSlVueTree(array, parentID=null, parentPath='~') {
      let tree = []
      let currentLevelNodes = array.filter((item) => {
        return item.parent === parentID
      })
      for (let node of currentLevelNodes) {
        node.data['fullPath'] = parentPath + '/' + node.data.name
        if (node.type === 'folder') {
          node['children'] = this.buildSlVueTree(array, node.id, node.data.fullPath)
        }
        tree.push(node)
      }
      return tree
    }
  },

  watch: {
    confirmDelete(confirmed) {
      if (confirmed) {
        this.confirmDeleteText = `Delete this ${((this.currentNode.data.type === 'file') ? 'file':'folder')}?`
      } else {
          this.confirmDeleteText = ''
      }
    },

    'vfs.files': {
      handler: function (array) {
        this.tree = this.buildSlVueTree(array)
      },
      deep: true
    },

    'currentFile': {
      handler: function (file, newF) {
        let previousVfsItemId = newF ? newF.id : null
        let activeVfsItemId = file ? file.id : null
        if (activeVfsItemId !== previousVfsItemId) {
          this.$store.dispatch('user/updateProfile', {activeVfsItemId: activeVfsItemId})
        }
      }
    },

    // '$interpreter.reloadUserFilesFlag': function(val) {
    //   if (val) {
    //     this.$store.dispatch('vfs/fetchVFSTree');
    //   }
    //   Interpreter.resetReloadUserFilesFlag();
    // }
  }
}
</script>

<style>
@import  '~sl-vue-tree/dist/sl-vue-tree-minimal.css';

.sl-vue-tree-selected > .sl-vue-tree-node-item {
  background-color: rgba(170, 170, 170, 0.5);
}

.sl-vue-tree-node-item.sl-vue-tree-cursor-inside {
  outline: 1px solid rgba(170, 170, 170, 0.5);
}

.sl-vue-tree-cursor {
  position: absolute;
  border: 1px solid rgba(170, 170, 170, 0.5);
  height: 1px;
  width: 100%;
}

.sl-vue-tree-gap {
  width: 25px;
  min-height: 1px;
}

.contextmenu {
  position: absolute;
  background-color: #e5e4e4;
  color: black;
  border: 1px solid rgba(170, 170, 170, 0.5);;
  border-radius: 2px;
  cursor: pointer;
}
.sl-vue-tree-toggle {
  display: block;
  float: left;
}
.contextmenu > div {
  padding: 10px;
}

.contextmenu > div:hover {
  background-color: rgba(170, 170, 170, 0.5);
}

.sl-vue-tree-drag-info {
    display: none;
}
</style>