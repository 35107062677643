export default {
  methods: {
    getIconByFileExtension(file) {
      const extension = file.split('.').slice(-1)[0]
      const mapping = {
        'py': 'mdi-language-python',
        'json': 'mdi-code-json',
        'csv': 'mdi-chart-bar',
        'txt': 'mdi-text'
      }
      return mapping[extension] || 'mdi-file'
    },

    getColorByFileExtension(file) {
      const extension = file.split('.').slice(-1)[0]
      const mapping = {
        'py': 'orange lighten-1',
        'json': 'green',
        'csv': 'green'
      }
      return mapping[extension] || ''
    }
  }
}