<template>
  <section>

    <v-navigation-drawer
      clipped
      app
      right
      mobile-breakpoint="0"
      v-resizable="{gutter: 'right'}"
      v-model="helperDrawer"
      class="side-content"
      style="z-index: 6;"
      :width=width
      :touchless="true"
    >
      <keep-alive>
        <router-view name="HelperContent" :class="($vuetify.breakpoint.smAndDown) ? 'mr-10' : 'ml-12'"/>
      </keep-alive>
    </v-navigation-drawer>

    <helper-titles-drawer
      @lessonLoading="lessonLoading = true"
    ></helper-titles-drawer>
    <sidebar-navigation></sidebar-navigation>
  </section>
</template>

<script>
import HelperTitlesDrawer from "@/components/drawers/HelperTitlesDrawer";
import {mapState} from "vuex";
import SizeMixin from "@/mixins/SizeMixin";
import SidebarNavigation from "@/components/drawers/SidebarNavagation.vue"

export default {
  name: 'helper-drawers',
  components: {SidebarNavigation, HelperTitlesDrawer},
  mixins: [SizeMixin],

  props: {
    helperDrawer: Boolean,
    helperTitlesDrawerActive: Boolean,
  },


  mounted() {
    let content = document.querySelector('.side-content > .v-navigation-drawer__content');
    content.addEventListener('scroll', (event) => {
      this.$store.dispatch('navigation/setContainerScrollPosition', event.target.scrollTop)
    });
  },

  data: () => ({
    lessonLoading: false
  }),

  computed: {
    ...mapState(['lessons', 'navigation', 'settings']),
    width() {
      if (!this.$vuetify.breakpoint.smAndDown)
        return this.right
      return '100%';
    }
  },

  watch: {
    '$route'() {
      this.$store.dispatch('settings/updateUI', {'helperDrawer': true});
    },

    'navigation.desiredScrollPosition'(newV, oldV) {
      if (oldV === null) {
        this.$nextTick(()=> {
          let content = document.querySelector('.side-content > .v-navigation-drawer__content');
          content.scrollTop = newV;
          this.$store.dispatch('navigation/setDesiredScrollPosition', null);
        });
      }
    },

    'lessons.currentLesson'() {
      this.lessonLoading = false
    }
  }
}

</script>
<style>
.v-navigation-drawer__border {
  width: 2px !important;
}
</style>