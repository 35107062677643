import Interpreter from "@/plugins/interpreter/Interpreter";

import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-markdown";
import "ace-builds/src-noconflict/mode-text";
import "@/plugins/ace-editor/ace-editor-mode-python3";

import "@/plugins/ace-editor//theme-monokai";
import '@/plugins/ace-editor/theme-github';
import {mapState} from "vuex";


export default {
  render: function (h) {
    let height = this.height ? this.px(this.height) : '100vh'
    let minHeight = this.minHeight ? this.px(this.minHeight) : 'none'
    let width = this.width ? this.px(this.width) : '100%'
    let left = this.left ? this.px(this.left) : '0'
    let right = this.right ? this.px(this.right) : '0'
    let bottom = this.bottom ? this.px(this.bottom) : '0'
    let top = this.top ? this.px(this.top) : '0'
    let font_size = (this.options.fontSize || '12') + 'px'
    let transition = (this.transition || 'none')
    return h('div', {
      attrs: {
        style: `height: ${height}; min-height: ${minHeight};width: ${width}; font-size: ${font_size}; left: ${left}; right: ${right}; bottom: ${bottom}; top: ${top}; transition: ${transition}; z-index: 0`,
      }
    })
  },
  props: {
    value: String,
    lang: true,
    theme: String,
    height: true,
    width: true,
    left: true,
    right: true,
    bottom: true,
    top: true,
    transition: true,
    commands: Array,
    options: Object,
    focus: null,
    wrap: Boolean,
    minHeight: true
  },

  computed: {
    ...mapState(['settings'])
  },

  data: function () {
    return {
      editor: null,
      contentBackup: ""
    }
  },
  methods: {
    px: function (n) {
      if (/^\d*$/.test(n)) {
        return n + "px";
      }
      return n;
    }
  },
  watch: {
    'settings.UI.gutters.right': function() {
      /* We need this resize because of questions and messages editor's width is 100% */
      this.$nextTick(function () {
        this.editor.resize()
      });
    },

    '$interpreter.isRunning': function() {
      this.editor.session.clearBreakpoints();
    },

    '$interpreter.debug': function(debug) {
      this.editor.session.clearBreakpoints();
      if (this.$interpreter.isRunning) {
        this.editor.session.setBreakpoint(debug.line - 1);
        this.editor.gotoLine(debug.line);
      }
    },

    value: function (val) {
      if (this.contentBackup !== val) {
        this.editor.session.setValue(val, 1);
        this.contentBackup = val;
      }
    },

    theme: function (newTheme) {
      this.editor.setTheme('ace/theme/' + newTheme);
    },

    lang: function (newLang) {
      this.editor.getSession().setMode(typeof newLang === 'string' ? ('ace/mode/' + newLang) : newLang);
    },

    options: function (newOption) {
      this.editor.setOptions(newOption);
    },

    height: function () {
      this.$nextTick(function () {
        this.editor.resize()
      })
    },

    width: function () {
      this.$nextTick(function () {
        this.editor.resize()
      })
    },

    focus: function (value) {
      this.$nextTick(function () {
        this.editor.focus()
        this.editor.navigateFileEnd()
      })
    }
  },

  beforeDestroy: function () {
    this.editor.destroy();
    this.editor.container.remove();
  },

  mounted: function () {
    var vm = this;
    var lang = this.lang || 'text';
    var theme = this.theme || 'chrome';

    var editor = vm.editor = ace.edit(this.$el);

    editor.$blockScrolling = Infinity;

    this.$emit('init', editor);

    editor.setOption("wrap", this.wrap);
    editor.getSession().setMode(typeof lang === 'string' ? ('ace/mode/' + lang) : lang);
    editor.setTheme('ace/theme/' + theme);

    if (this.value)
      editor.setValue(this.value, 1);
      editor.getSession().setUndoManager(new ace.UndoManager());
    this.contentBackup = this.value;

    editor.on('change', function () {
      var content = editor.getValue();
      vm.$emit('input', content);
      vm.contentBackup = content;
    });

    editor.on('blur', () => {
      vm.$emit('blur', editor.getValue());
    });

    if (vm.options)
      editor.setOptions(vm.options);

    if (Array.isArray(vm.commands)) {
      vm.commands.forEach((command) => {
        editor.commands.addCommand(command);
      });
    }

    editor.on('focus', () => {
      this.$emit('onFocus')
    })

    editor.focus();

    editor.commands.addCommand({
      name: 'save',
      bindKey: {'win': 'Ctrl-S', 'mac': 'Cmd-S'},
      exec:  (editor) => {
        this.$emit('saveContent', editor.session.getValue())
      }
    })

    for (let key in editor.keyBinding.$defaultHandler.commandKeyBinding) {
      if (key === "ctrl-d")
        delete editor.keyBinding.$defaultHandler.commandKeyBinding[key]
    }

  }
}
