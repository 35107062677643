<template>
  <router-view></router-view>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: 'social-content-layout-view',

  data: () => ({
    loading: false,
  }),

  computed: {
    ...mapState(['social', 'settings'])
  },

  created() {

  },

  methods: {

  },

  watch: {
    '$route'(to) {
      if (to.path.startsWith('/social')) {
        this.$store.dispatch('navigation/setPath', {tab: 'social', path: to.path });
      }
    }
  }
}
</script>