<template>
  <section>
    <div class='flex items-center pa-4'>
      <div class='text-xl'>
        Вопросы
      </div>
      <v-spacer></v-spacer>
      <v-btn class="align-self-center" text color="primary" :to="{name: 'questionAdd_'}">{{ $t('Submit question') }}</v-btn>
    </div>
    <v-list v-if="questions.questions.length > 0">
      <v-list-item
        class="pl-4"
        v-for="(question, i) in questions.questions"
        :key="`question-${i}`"
        :to="{name: 'questionDetail_', params: {id: question.id}}"
      >
        <v-list-item-avatar color="primary">
          <v-img
              v-if="question.user.photo"
              :src="question.user.photo"
          ></v-img>
          <span v-else class="white--text subtitle-1" style="margin: 0 auto;">{{ question.user.photoPlaceholder.toUpperCase() }}</span>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title v-text="question.title"></v-list-item-title>
          <v-list-item-subtitle>
            {{ question.user.fullName }},
            <timeago :datetime="question.createdAt" :auto-update="60"></timeago>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          {{ $tc('repliesCount', question.messagesCount) }}
        </v-list-item-action>
      </v-list-item>
    </v-list>

    <infinite-loading @infinite="infiniteHandler">
      <div slot="spinner" class="pa-10">
        <v-progress-circular
          :width="2"
          indeterminate
        ></v-progress-circular>
      </div>
    </infinite-loading>
  </section>
</template>

<script>

import {mapState} from "vuex";
import InfiniteLoading from 'vue-infinite-loading';
import { BASE_COURSE_NAME } from '@/legacy.js'

export default {
  name: 'questions-list',
  components: {InfiniteLoading},

  computed: {
    ...mapState(['questions', 'lessons']),
  },

  data: () => ({
  }),
  mounted() {

  },

  methods: {
    infiniteHandler($state) {
      let params = {
        'sectionId': this.$route.params.sectionId,
        'lessonId': this.$route.params.lessonId
      }
      if ((this.$route.params.courseId) && (this.$route.params.courseId !== BASE_COURSE_NAME)) {
        params['courseId'] = this.$route.params.courseId
      } else {
        params['courseId__isnull'] = true
      }

      this.$store.dispatch('questions/fetch', params).then((questions) => {
        if (questions.length === 0) {
          $state.complete();
        }
      }, () => {
        // change state to prevent infinite axios requests
        $state.complete();
      }).finally(()=> {
        $state.loaded();
      });
    },
  }
}
</script>

<style scoped>
div.text-h5 > span {
  opacity: 0.5;
}
</style>