import FileService from '@/services/FileService.js'


export const namespaced = true

const toStoreContentFormat = (response) => {
  let data = {}
  data[response.data.vfsItemId] = response.data.content
  return data
}

export const state = {
  content: {},
  modifiedContent: {}
}

export const mutations = {
  UPDATE_FILE_CONTENT(state, data) {
    state.content = {...state.content, ...data}
  },

  MODIFY_FILE_CONTENT(state, data) {
    state.modifiedContent = {...state.modifiedContent, ...data}
  }
}

export const actions = {
  fetchFileContent({ commit, state }, nodeID) {
    return FileService.get(nodeID).then((response) => {
      let data = toStoreContentFormat(response)
      commit('UPDATE_FILE_CONTENT', data)
      commit('MODIFY_FILE_CONTENT', data)
      return response.data.content
    })
  },

  updateFileContent({ commit }, data) {
    commit('MODIFY_FILE_CONTENT', data)
    return FileService.patch({'content': data.content}, data.id).then((response) => {
      let data = toStoreContentFormat(response)
      commit('UPDATE_FILE_CONTENT', data)
      return data.content
    })
  }
}

export const getters = {
  getContentById: (state) => (id) => {
    return state.modifiedContent[id];
  }
}