<template>
  <!-- Here we can place not only the user solution, but the solutions shared by community -->
  <div>
  <!--div class='text-4xl px-4 py-2'>{{ $t('Your code') }}</div-->

    <div class="body-1 px-4 pa-4" v-hljs v-if='lessons.currentLesson.lastVerifiedCode !== null'>
      <pre>
        <code class="code python" v-html="lessons.currentLesson.lastVerifiedCode"></code>
      </pre>
    </div>
  </div>

</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'LessonSolutions',
  computed: {
    ...mapState(['lessons']),
  }
}
</script>