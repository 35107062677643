import Vue from 'vue'
import twemoji from "twemoji";

Vue.directive('emoji', {
  inserted(el) {
    const base = process.env.VUE_APP_MAIN_PUBLIC_PATH;

    el.innerHTML = twemoji.parse(el.innerHTML, {
      base: `${(base === '/' ? '' : base )}/twemoji/v/13.1.1/`
    })
  }
});
