<template>
  <v-progress-linear
    v-if="loading"
    indeterminate
    color="primary"
    height="1"
  ></v-progress-linear>

  <router-view v-else/>
</template>

<script>
export default {
  name: 'applications-layout',

  data: () => ({
    loading: true
  }),

  created() {
    this.fetchApps()
  },

  methods: {
    fetchApps() {
      this.$store.dispatch('applications/fetchAll').finally(() => {
        this.loading = false
      })
    }
  },

}
</script>
