import Vue from 'vue'
import VueRouter from 'vue-router'
import IDE from '../views/IDE.vue'
import LessonsTitles from "@/views/lessons/Titles";
import LessonContent from "@/views/lessons/LessonContent";
import CoursesContent from "@/views/lessons/Courses";

import BookmarksTitles from "@/views/bookmarks/Titles";
import BookmarkContent from "@/views/bookmarks/Content";
import WorkbookTitles from "@/views/workbook/Titles";
import WorkbookContent from "@/views/workbook/Content";
import WorkbookAdd from "@/views/workbook/Add";
import WorkbookViewUpdate from "@/views/workbook/ViewUpdate";

import SocialTitles from "@/views/social/Titles";
import SocialContentLayout from "@/views/social/Content";
import Purchases from "@/views/social/Purchases";
import FeedContent from "@/views/social/FeedContent";
import MyContent from "@/views/social/MyContent";
import Settings from "@/views/social/Settings";
import Applications from "@/views/social/applications/Layout";
import ApplicationsList from "@/views/social/applications/List";
import ApplicationsAdd from "@/views/social/applications/Add";
import ApplicationsDetail from "@/views/social/applications/Detail";
import Subscription from "@/views/social/Subscription";
import GiftPurchase from "@/views/social/GiftPurchase";

import MyCourses from '@/views/social/MyCourses.vue';

import QuestionsLayout from "@/views/lessons/questions/Layout";
import QuestionsAdd from "@/views/lessons/questions/Add";
import QuestionsDetail from "@/views/lessons/questions/Detail";
import QuestionsList from "@/views/lessons/questions/List";
import GetUnstuck from '@/views/lessons/GetUnstuck.vue'
import LessonSolutions from '@/views/lessons/LessonSolutions.vue'
import Lesson from '@/views/lessons/Lesson.vue'

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'IDE',
    component: IDE,
    // redirect: 'lessons',
    children: [
        {
          path: 'courses',
          name: 'courses',
          components: {
              default: LessonsTitles,
              HelperContent: CoursesContent,
            }
        },

      {
        path: 'course/:courseId/:sectionId/:lessonId',
        name: 'lesson',
        components: {
          default: LessonsTitles,
          HelperContent: Lesson
        },
        children: [
          {
            path: '',
            redirect: 'q'
          },
          {
            path: 'q',
            name: 'questionList_',
            component: QuestionsList
          },
          {
            path: 'q/add',
            name: 'questionAdd_',
            component: QuestionsAdd
          }, {
              path: 'q/:id',
              name: 'questionDetail_',
              component: QuestionsDetail
          },
          {
            path: 'unstuck',
            name: 'getUnstuck',
            component: GetUnstuck
          },
          {
            path: 'solutions',
            name: 'lessonSolutions',
            component: LessonSolutions
          },
        ]
      },
      {
        path: 'lessons',
        name: 'lessons',
        redirect: 'lessons/100.500',
        components: {
          default: LessonsTitles,
          HelperContent: LessonContent,
        },
        children: [
          {
            path: ':sectionId.:lessonId',
            component: QuestionsLayout,
            redirect: (to) => `/course/base/${to.params.sectionId}/${to.params.lessonId}/`,
            children: [
              {
                path: 'q',
                name: 'questionList',
                component: QuestionsList,
                redirect: (to) => `/course/base/${to.params.sectionId}/${to.params.lessonId}/q`,
              }, {
                path: 'q/add',
                name: 'questionAdd',
                component: QuestionsAdd,
                redirect: (to) => `/course/base/${to.params.sectionId}/${to.params.lessonId}/q/add`,
              }, {
                path: 'q/:id',
                name: 'questionDetail',
                component: QuestionsDetail,
                redirect: (to) => `/course/base/${to.params.sectionId}/${to.params.lessonId}/q/${to.params.id}`,
              }
            ]
          },
        ]
      },
      {
        path: '/bookmarks',
        name: 'bookmarks',
        components: {
          default: BookmarksTitles,
          HelperContent: BookmarkContent
        },
        children: [
          {
            path: ':id',
            name: 'bookmarkContent'
          }
        ]
      }, {
        path: '/workbook',
        name: 'workbook',
        components: {
          default: WorkbookTitles,
          HelperContent: WorkbookContent,
        },
        children: [
          {
            path: 'add',
            name: 'workbookAddRecord',
            component: WorkbookAdd
          }, {
            path: ':id',
            name: 'workbookViewUpdate',
            component: WorkbookViewUpdate
          }
        ]
      }, {
        path: 'social',
        redirect: 'social/feed',

        components: {
          default: SocialTitles,
          HelperContent: SocialContentLayout
        },

        children: [
          {
            path: 'feed',
            name: 'feedContent',
            component: FeedContent
          },
          {
            path: 'my',
            name: 'myContent',
            component: MyContent
          },
          {
            path: 'settings',
            name: 'Settings',
            component: Settings
          },
          {
            path: 'applications',
            component: Applications,
            children: [
              {
                path: '',
                name: 'ApplicationsList',
                component: ApplicationsList,
              }, {
                path: 'add',
                name: 'ApplicationsAdd',
                component: ApplicationsAdd,
              }, {
                path: ':id',
                name: 'ApplicationsDetail',
                component: ApplicationsDetail,
              }
            ]
          },
          {
            path: 'subscription',
            name: 'Subscription',
            component: Subscription
          },
          {
            path: 'gift-purchase',
            name: 'GiftPurchase',
            component: GiftPurchase
          },
          {
            path: 'purchases',
            name: 'Purchases',
            component: Purchases
          },
          {
            path: 'courses',
            name: 'MyCourses',
            component: MyCourses
          }
        ]
      },
    ]
  }, {
    name: 'facebook-login',
    path: '/_=_',
    redirect: '/'
  },
  {
    name: 'legacy-lessons',
    path: '/:sectionId.:lessonId',
    redirect: '/'
  },
]

const router = new VueRouter({
  routes
})

export default router
