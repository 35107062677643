<template>
  <v-dialog
    v-model="showMenuInner"
    width="500"
    @keypress.esc="showMenuInner = false"
    persistent
  >
    <v-card>

      <v-card-text class="pa-5">
        {{ $t($props.text) }}
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          text
          @click="showMenuInner = false"
        >
          {{ $t('Cancel') }}
        </v-btn>

        <v-btn
          color="red"
          text
          @click="confirm"
        >
          {{ $t('Delete') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ShowMixin from "@/mixins/ShowMixin";

export default {
  name: 'confirm-delete-dialog',
  mixins: [ShowMixin],
  props: {
    text: {
      type: String,
      default: 'Delete this?'
    }
  },

  methods: {
    confirm() {
      this.showMenuInner = false
      this.$emit('deletionConfirmed')
    }
  }
}
</script>
