<template>
  <section v-if="!filesLoading">
    <sl-vue-tree
        v-model="files"
        ref="filesTree"
        @toggle="toggleNode"
        @nodedblclick="open"
        @nodecontextmenu="showContextMenu"
        @drop="drop"
        @input="onInputHandler"
        :allow-multiselect="false"
    >
      <template slot="toggle" slot-scope="{ node }">
          <v-icon v-if="node.isExpanded">mdi-chevron-down</v-icon>
          <v-icon v-else>mdi-chevron-right</v-icon>
      </template>

      <template slot="title" slot-scope="{ node }">
        <div class="d-flex">
          <file-icon v-if="node.isLeaf" :name="node.title" style="margin-left: 24px;"></file-icon>
          <v-icon size="20" color="blue-grey" v-else-if="node.isExpanded">mdi-folder-open</v-icon>
          <v-icon size="20" color="blue-grey" v-else>mdi-folder</v-icon>
          <span style="padding-left: 5px;opacity: 0.7">{{ node.title }}</span>
        </div>
      </template>
    </sl-vue-tree>

    <v-menu ref="contextmenu" :position-x="cursorPositionX" :position-y="cursorPositionY" v-model="contextMenuIsVisible">
      <v-list>
        <v-list-item @click="createNode('file')">
          <v-list-item-title>{{ $t('New file') }}</v-list-item-title>
          <v-list-item-action><v-icon>mdi-file</v-icon></v-list-item-action>
        </v-list-item>
        <v-list-item @click="createNode('folder')">
          <v-list-item-title>{{ $t('New folder') }}</v-list-item-title>
          <v-list-item-action><v-icon>mdi-folder</v-icon></v-list-item-action>
        </v-list-item>

        <v-list-item @click="renameNode()">
          <v-list-item-title>{{ $t('Rename') }}</v-list-item-title>
          <v-list-item-action><v-icon>mdi-rename-box</v-icon></v-list-item-action>
        </v-list-item>

        <v-list-item @click="deleteNode()">
          <v-list-item-title>{{ $t('Delete') }}</v-list-item-title>
          <v-list-item-action><v-icon color="red lighten-1">mdi-delete</v-icon></v-list-item-action>
        </v-list-item>
      </v-list>
    </v-menu>
  </section>
  <v-progress-linear
    v-else
    indeterminate
    color="primary"
  ></v-progress-linear>
</template>

<script>
import SlVueTree from 'sl-vue-tree';
import FileIcon from "@/components/FileIcon.vue";
import UserFilesService from "@/services/UserFilesService";
import {mapGetters, mapState} from "vuex";
import path from "path-browserify";
import Interpreter from "@/plugins/interpreter/Interpreter";

export default {
  name: "UserFiles",
  components: {SlVueTree, FileIcon},
  data: () => ({
    filesLoading: true,
    cursorPositionX: null,
    cursorPositionY: null,
    contextMenuIsVisible: false,
  }),

  methods: {
    onInputHandler() {
      this.$store.commit('userFiles/BACKUP_TREE');
    },

    showContextMenu(node, event) {
      event.preventDefault();
      const filesTree = this.$refs.filesTree
      this.contextMenuIsVisible = true;
      this.cursorPositionX = event.clientX;
      this.cursorPositionY = event.clientY;
      filesTree.select(node.path)
    },

    createNode(type) {
      const filesTree = this.$refs.filesTree
      const node = filesTree.getSelected()[0]
      const parent = ((node.isLeaf === false) ? node['data']['path']:null)
      this.$emit('openFilesDialog', type, parent)
    },

    renameNode() {
      const filesTree = this.$refs.filesTree
      const node = filesTree.getSelected()[0]
      this.$emit('openRenameNodeDialog', node)
    },

    toggleNode(node) {
      UserFilesService.patch(node.data.path, {
        isExpanded: !node.isExpanded
      })
    },

    open(node) {
      if (node.isLeaf) {
        this.$store.dispatch('userFiles/openFile', {
          path: node.data.path,
          title: node.title
        }).then(() => {
          this.$store.dispatch('userFiles/setActiveTab', node.data);
        });
      }
    },

    drop(nodes, position, event) {
      let folder = position.node.data.path;
      if (position.placement !== 'inside') {
        folder = path.dirname(position.node.data.path);
      }

      for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i];
        if (path.dirname(node.data.path) !== folder) {
          UserFilesService.patch(node.data.path, {
            path: path.join(folder, path.basename(node.data.path))
          }).then(()=>{
            this.$store.commit('userFiles/UPDATE_PATH', {
              oldPath: node.data.path, newPath: path.join(folder, path.basename(node.data.path))
            });
          }).catch(() => {
            this.$store.commit('userFiles/RESTORE_TREE');
            this.$store.commit('userFiles/BACKUP_TREE');
            this.$store.dispatch('notifications/show', {
              text: this.$t("Can't move file."),
              color: 'red'
            });

          });
        }
      }
    },

    deleteNode() {
      const filesTree = this.$refs.filesTree
      const node = filesTree.getSelected()[0]

      this.$confirm(this.$t('Delete this file?'), {
        buttonTrueText: this.$t('Delete'),
        buttonFalseText: this.$t('Cancel'),
        buttonTrueColor: 'error'
      }).then((confirmed) => {
        if (confirmed) {
          const path = node.data.path;
          this.$store.commit('userFiles/CLOSE_TAB', {path: path});
          filesTree.remove([node.path]);

          UserFilesService.delete(path).catch(()=>{
            this.fetchUserFiles();
          });
        }
      });
    },

    fetchUserFiles() {
      this.$store.dispatch('userFiles/fetchUserFiles').finally(() => {
        this.filesLoading = false;

        const promises = []
        this.$store.getters['userFiles/getOpened'].forEach((file) => {
          promises.push(this.$store.dispatch('userFiles/openFile', file));
        });

        Promise.all(promises).finally(() => {
          this.$store.getters['userFiles/getOpened'].forEach((file) => {
            if (file.path === this.user.info.activeTab) {
              this.$store.dispatch('userFiles/setActiveTab', file);
            }
          });

          if (this.$store.getters['userFiles/getOpened'].length && !this.$store.getters['userFiles/current']) {
            this.$store.dispatch('userFiles/setActiveTab', this.$store.getters['userFiles/getOpened'][this.$store.getters['userFiles/getOpened'].length - 1]);
          }
        });
      })
    }
  },


  computed: {
    files: {
      get() {
        return this.$store.getters['userFiles/getUserFiles'];
      },
      set(value) {
        this.$store.commit('userFiles/SET_FILES', value);
      }
    },
    ...mapState(['user']),
    ...mapGetters({currentFile: 'userFiles/current'}),
  },

  watch: {
    'currentFile.path': {
      handler: function (newPath, oldPath) {
        if (newPath !== oldPath) {
          this.$store.dispatch('user/updateProfile', {activeTab: newPath || null})
        }
      },
      deep: true
    },

    '$interpreter.fileOpen': function(value) {
      Interpreter.public.fileOpen = null;
      if (value !== null) {
        this.$store.dispatch('userFiles/fetchUserFiles').finally(() => {});
      }
    },

    '$interpreter.fileClose': function(value) {
      Interpreter.public.fileClose = null;
      if (value !== null) {
        this.$store.dispatch('userFiles/reloadFile', value).finally(() => {});

//        console.log(value.path, 'x <--')
      }
    }
  },

  mounted() {
    this.fetchUserFiles();
  }
}
</script>

<style>
@import  '~sl-vue-tree/dist/sl-vue-tree-minimal.css';

.sl-vue-tree-selected > .sl-vue-tree-node-item {
  background-color: rgba(170, 170, 170, 0.5);
}

.sl-vue-tree-node-item.sl-vue-tree-cursor-inside {
  outline: 1px solid rgba(170, 170, 170, 0.5);
}

.sl-vue-tree-cursor {
  position: absolute;
  border: 1px solid rgba(170, 170, 170, 0.5);
  height: 1px;
  width: 100%;
}

.sl-vue-tree-gap {
  width: 25px;
  min-height: 1px;
}

.contextmenu {
  position: absolute;
  background-color: #e5e4e4;
  color: black;
  border: 1px solid rgba(170, 170, 170, 0.5);;
  border-radius: 2px;
  cursor: pointer;
}
.sl-vue-tree-toggle {
  display: block;
  float: left;
}
.contextmenu > div {
  padding: 10px;
}

.contextmenu > div:hover {
  background-color: rgba(170, 170, 170, 0.5);
}

.sl-vue-tree-drag-info {
    display: none;
}
</style>