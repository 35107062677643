<template>
  <v-app v-if="!loading">
    <router-view></router-view>

    <v-snackbar
      v-model="notifications.message.show"
      :timeout="notifications.message.timeout"
      multi-line
    >
      <div v-html="notifications.message.text"></div>

      <template v-slot:action="{ attrs }">
        <v-btn
          :color="notifications.message.color"
          text
          v-bind="attrs"
          @click="notifications.message.show = false"
        >
          {{ $t('Close') }}
        </v-btn>
      </template>
    </v-snackbar>

    <updates-available-dialog :showMenu.sync="notifications.update.show"></updates-available-dialog>
    <choose-theme-dialog :show-menu.sync="showChooseThemeDialog"></choose-theme-dialog>
  </v-app>

  <section v-else style="height: 100vh">
    <v-progress-circular
      style="display: block; margin: 20% auto"
      indeterminate
    ></v-progress-circular>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import UpdatesAvailableDialog from "./components/dialogs/UpdatesAvailableDiaog";
import ChooseThemeDialog from "@/components/dialogs/ChooseThemeDialog";
import Interpreter from "@/plugins/interpreter/Interpreter";
import {BASE_COURSE_NAME} from "@/legacy";

export default {
  components: {ChooseThemeDialog, UpdatesAvailableDialog},
  data: () => ({
    loading: true,
    showChooseThemeDialog: false
  }),

  computed: {
    ...mapState(['settings', 'user', 'notifications', 'lessons']),
  },

  created() {
    Promise.all([
      this.$store.dispatch('settings/fetchLocalUISettings', this.$vuetify.breakpoint),
      this.$store.dispatch('settings/fetchPreferences'),
      this.$store.dispatch('user/fetchUserInfo').then((response) => {
        if (this.$route.name === 'IDE') {
          this.$router.push({
            name: 'questionList_',
            params: {courseId: response.data.currentCourse, sectionId: response.data.currentSection, lessonId: response.data.currentLesson}
          })
        }
        if (response.data.appVersion === '2.1') {
          this.$_interpreter.loading = true;
          /*Promise.all([
            this.axios.get(`${process.env.VUE_APP_BRYTHON_PATH}brython.min.js`).then((response) => {
              Interpreter.brythonModule = response.data;
            }),
            this.axios.get(`${process.env.VUE_APP_BRYTHON_PATH}brython_stdlib.min.js`).then((response) => {
              Interpreter.brythonStdlibModule = response.data;
            })
          ]).then(() => {
            this.$_interpreter.loading = false;
          });*/
          Interpreter.initPyodide();
          this.$_interpreter.version = 2;
        }
      }),
      this.$store.dispatch('user/fetchPaymentInfo'),
      this.$store.dispatch('user/fetchGifts')
    ]).finally(() => {
      if (this['settings'].preferences.darkTheme.value === null) {
        this.showChooseThemeDialog = true;
        this['settings'].preferences.darkTheme.value = false;
      }
      // window.__letpy.locale = 'en';
      // this.$i18n.locale = 'en';
      this.loading = false;
    });
  },

  watch: {
    'settings.preferences.darkTheme': {
      handler(darkTheme) {
        this.$vuetify.theme.dark = darkTheme.value;
      },
      deep: true
    },

    'settings.UI': {
      handler(UI) {
        this.$store.dispatch('settings/updateUI', UI)
      },
      deep: true
    }
  }
}
</script>
<style>
html {
  overflow-y: hidden!important;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #959191;
  border: solid 1px #888;
}
</style>
