<template>
  <div>
    <inner-payment
        @onPaymentDialogClosed="onPaymentDialogClosed"
        @onFirstTimeLoad='loading = false'
    ></inner-payment>
    <v-skeleton-loader
        v-if="loading"
        class="mx-auto"
        type="list-item-three-line, list-item-three-line, card-heading, list-item-two-line, list-item-three-line"
    ></v-skeleton-loader>
  </div>
</template>

<script>
import {mapState} from "vuex";
import InnerPayment from "@/components/InnerPayment";

export default {
  name: 'subscription-content-view',
  components: {InnerPayment},
  data: () => ({
    loading: true,
    fetchPaymentInfoAttempts: 0
  }),

  computed: {
    ...mapState(['social', 'settings', 'user']),
  },

  created() {

  },

  methods: {
    fetchPaymentInfo() {
      this.fetchPaymentInfoAttempts += 1;
      this.$store.dispatch('user/fetchPaymentInfo').then((response) => {
        if (!response.data.hasPremiumAccess && this.fetchPaymentInfoAttempts < 5) {
          setTimeout(() => {
            this.fetchPaymentInfo();
          }, 5000)
        }
      });
    },

    onPaymentDialogClosed(status) {
      if (status === 'successful') {
        this.fetchPaymentInfoAttempts = 0;
        this.fetchPaymentInfo();
      }
    },
  },

  watch: {
    '$route.path'() {

    }
  }
}
</script>