import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

export default new Vuetify({
  iconfont: 'fa',
  theme: {
    themes: {
      light: {
        primary: colors.blue.darken1,
        secondary: colors.grey.darken1,
        accent: colors.shades.black,
        error: colors.red.accent3,
        background: colors.grey.lighten5, // Not automatically applied
      },
      dark: {
        // primary: colors.blue.lighten2,
        background: colors.grey.darken3, // If not using lighten/darken, use base to return hex
      },
    },
    options: { customProperties: true }
  },
});
