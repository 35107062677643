<template>
  <v-dialog
    transition="dialog-bottom-transition"
    max-width="400"
    v-model="showMenuInner"
  >
    <v-card>
      <v-card-title class="text-h7">{{ $t('Rename') }}</v-card-title>
      <v-card-text>
        <v-text-field
          autofocus
          v-model="name"
          append-outer-icon="mdi-send"
          @click:append-outer="submit"
          @keypress.enter="submit"
          :error-messages="(version >= '2.1') ? errorMessages['path'] : errorMessages['name']"
        ></v-text-field>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ShowMixin from "@/mixins/ShowMixin";
import {mapState} from "vuex";
import path from "path-browserify";
import UserFilesService from "@/services/UserFilesService";

export default {
  name: 'rename-node',
  mixins: [ShowMixin],
  props: {
    node: Object,
  },

  data: () => ({
    name: '',
    errorMessages: {}
  }),

  computed: {
    ...mapState({
      version: state => state.user.info.appVersion,
    })
  },

  methods: {
    submit() {
      if ((this.name.length > 0) && (this.name.indexOf('/') === -1) && (this.name.indexOf('\\') === -1)) {
        if (this.version >= '2.1') {
          const dirname = path.dirname(this.$props.node.data.path)
          const newPath = path.join(dirname, this.name)

          UserFilesService.patch(this.$props.node.data.path, {
            path: newPath
          }).then(()=>{
            this.$store.commit('userFiles/UPDATE_PATH', {
              oldPath: this.$props.node.data.path, newPath: newPath
            });
            this.setDefaultValues();
          }).catch((error) => {
            this.errorMessages = error.response.data
          })
        } else {
          this.$store.dispatch('vfs/updateNode', {
            'id': this.$props.node.data.id,
            'name': this.name,
            'title': this.name,
          }).then(() => {
            this.setDefaultValues()
          }).catch((error) => {
            this.errorMessages = error.response.data
          })
        }
      }
    },

    setDefaultValues() {
      this.showMenuInner = false
      this.name = ''
      this.errorMessages = []
    }
  },

  watch: {
    showMenuInner(isOpened) {
      if (!isOpened) {
        this.setDefaultValues()
      } else {
        if (this.version >= '2.1') {
          this.name = path.basename(this.$props.node.data.path)
        } else {
          this.name = this.$props.node.title
        }
      }
    }
  }
}
</script>