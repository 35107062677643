import ApplicationsService from '@/services/ApplicationsService.js'


export const namespaced = true

const defaultApp = {
  title: '',
  description: '',
  slug: '',
  file: '',
  isPublished: true,
  showConsole: false
}

export const state = {
  defaultApp: defaultApp,
  list: [],
}

export const mutations = {
  SET_APPS(state, appsArray) {
    state.list = appsArray
  },

  ADD_APP(state, appObject) {
    state.list.push(appObject)
  },

  DELETE_APP(state, appObject) {
    let index = state.list.indexOf(appObject)
    state.list.splice(index, 1)
  }
}

export const actions = {
  fetchAll({ commit }) {
    return ApplicationsService.get().then((response) => {
      commit('SET_APPS', response.data)
    })
  },

  publishApp({ commit }, data) {
    return ApplicationsService.post(data).then((response) => {
      commit("ADD_APP", response.data)
      return response
    })
  },

  updateApp(_, data) {
    return ApplicationsService.patch(data, data.id)
  },

  deleteApp({ commit, getters }, id) {
    return ApplicationsService.delete(id).then(() => {
      commit('DELETE_APP', getters.getAppById(id))
    })
  }
}

export const getters = {
  getAppById: (state) => (id) => {
    let app = state.list.find(app => app.id === id)
    return ((app !== undefined) ? app:defaultApp)
  }
}