<template>
  <div class="mb-5">
    <v-card flat outlined class="ml-4 mr-4 flex-grow-1 overflow-hidden">
      <div class="d-flex justify-space-between align-center pt-2 pb-2 pl-4 pr-1 comment-header">
        <div class="d-flex align-start">
          <avatar :id="item.user.id" :photo="item.user.photo" :placeholder="item.user.photoPlaceholder"
                  class="mt-1"></avatar>
          <div class="pl-3">
            <div>
              {{ item.user.fullName }} <span class="grey--text">задал вопрос к уроку &laquo;{{
                item.lesson.title
              }}&raquo;</span>
            </div>
            <div class="grey--text body-2">
              <timeago class="grey--text" :datetime="item.createdAt" :auto-update="60"></timeago>
            </div>
          </div>

        </div>
        <v-menu offset-y left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon small>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-if="(user.info.id === item.user.id) || user.info.isSuperuser" @click="editingItemId = item.id">
                  <v-list-item-title>{{ $t('Edit') }}</v-list-item-title>
                  <v-list-item-action>
                    <v-icon color="orange lighten-2">mdi-pencil</v-icon>
                  </v-list-item-action>
                </v-list-item>

                <v-list-item v-if="(user.info.id === item.user.id) || user.info.isSuperuser" @click="">
                  <v-list-item-title>{{ $t('Delete') }}</v-list-item-title>
                  <v-list-item-action>
                    <v-icon color="red lighten-1">mdi-delete</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-menu>
      </div>
      <div v-if="editingItemId === item.id">
        <entity-content-editor
          :message-object="item"
          :update-loading="editingQuestionLoading"
          :outlined="false"
          @cancel="editingItemId = null"
          @submit="submitEditedQuestion">

        </entity-content-editor>
      </div>
      <div v-else>
        <v-card-text class="body-1" v-hljs>
        <active-content :content="item.html"></active-content>
      </v-card-text>
      <v-card-text class="pt-0">
        <rate-content-2
            :can-rate="user.info.id !== item.user.id"
            :rating.sync="item.rating"
            :is-rated-by-user.sync="item.isRatedByUser"
            :item-id="item.id"
            :item-type="1"
            class="mr-4"
        ></rate-content-2>

        <v-btn outlined rounded color="grey" :loading="messagesLoading" @click="showMessages();">
          <v-icon small>mdi-comment-outline</v-icon>
          <span class="ml-1" v-if="item.messagesCount > 0" v-text="item.messagesCount"></span>
        </v-btn>
      </v-card-text>
      </div>


    </v-card>
    <div v-if="isMessagesOpened">

      <div v-for="(message, m) in messages" class="pt-4 message-container">
        <div class="d-flex align-center" style="margin-left: -16px;">
          <avatar :photo="message.user.photo" :placeholder="message.user.photoPlaceholder" :id="message.user.id"
                  class="mt-1"></avatar>
          <div class="pl-3">
            <div>
              {{ message.user.fullName }}, написал
              <timeago class="grey--text" :datetime="message.createdAt" :auto-update="60"></timeago>
            </div>
          </div>
        </div>
        <v-card class="ml-3 mr-6" style="background-color: transparent; box-shadow: none;">
          <v-card-text class="body-1 pt-0" v-hljs style="padding-bottom: 1px">
            <active-content :content="message.html"></active-content>
            <rate-content-2
                :can-rate="user.info.id !== message.user.id"
                :rating.sync="message.rating"
                :is-rated-by-user.sync="message.isRatedByUser"
                :item-id="message.id"
                :item-type="3"
            ></rate-content-2>
          </v-card-text>
        </v-card>
      </div>


      <div class="d-flex align-start">
        <div class="message-container pt-5">
          <avatar :photo="user.info.photo" :placeholder="user.info.photoPlaceholder" class="mt-1"
                  style="margin-left: -16px"></avatar>
        </div>

        <div class="flex-grow-1 ml-3 mr-5 pt-5">
          <v-card flat outlined>
            <public-editor v-if="messageEditor" @blur="onBlur" :text.sync="message"></public-editor>
            <div v-else class="pl-4 subtitle-1 grey--text pt-2 pb-2" style="cursor: auto" @click="messageEditor = true">
              Напишите комментарий...
            </div>
            <v-card-actions v-if="messageEditor">
              <v-spacer></v-spacer>
              <v-btn :loading="messageSubmitLoading" :disabled="message.length === 0" color="green" text @click="sendMessage();">{{ $t('Reply') }}</v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import Avatar from "@/components/Avatar";
import ActiveContent from "@/components/ActiveContent";
import PublicEditor from "@/components/editor/PublicEditor";
import RateContent2 from "@/components/RateContent2";
import EntityContentEditor from "@/components/editor/EntityContentEditor";

import {mapState} from "vuex";

export default {
  name: "FeedItem",
  components: {Avatar, ActiveContent, PublicEditor, RateContent2, EntityContentEditor},
  props: ['item'],
  computed: {
    ...mapState(['social', 'settings', 'user']),
  },

  methods: {
    submitEditedQuestion(item) {
      console.log(item)
      this.editingItemId = null;
    },

    fetchMessages() {
      return this.$store.dispatch('messages/fetch', {
        'itemType': this.$constants.LESSON_QUESTION_TYPE,
        'itemId': this.item.id,
      }).then((response) => {
        this.messages = response.data;
        this.item.messagesCount = this.messages.length;
      });

    },

    showMessages() {
      if (this.isMessagesOpened) {
        this.isMessagesOpened = false;
        return;
      }
      this.messagesLoading = true;
      this.fetchMessages().then(()=>{
        this.isMessagesOpened = true;
      }).finally(() => {
        this.messagesLoading = false
      });
    },

    onBlur() {
      if (this.message.length === 0) {
        this.messageEditor = false;
      }
    },

    sendMessage() {
      this.messageSubmitLoading = true;
      this.$store.dispatch('messages/add', {
        'itemType': this.$constants.LESSON_QUESTION_TYPE,
        'itemId': this.item.id,
        'text': this.message,
      }).then(() => {
        return this.fetchMessages();
      }).finally(() => {
        this.messageSubmitLoading = false;
        this.message = '';
      })
    }
  },

  data: () => ({
    messages: [],
    isMessagesOpened: false,
    messagesLoading: false,
    messageEditor: false,
    message: '',
    messageSubmitLoading: false,
    editingItemId: null,
    editingQuestionLoading: false

  }),
}
</script>

<style scoped>

</style>