<template>
  <v-card flat class="pb-2">
    <v-card-actions class="pa-4 pb-0">
      <v-btn small icon @click="$router.push({name: 'ApplicationsList'})">
        <v-icon>mdi-chevron-left</v-icon></v-btn>
      <div class="subtitle-1 ml-2">{{ title }}
        <div class="caption grey--text">{{ slug }}</div>
      </div>
      <v-spacer></v-spacer>
      <v-menu offset-y right :loading="deleteLoading">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-bind="attrs"
              v-on="on"
              icon
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="deleteAppDialog = !deleteAppDialog">
            <v-list-item-title>{{ $t('Delete') }}</v-list-item-title>
            <v-list-item-action><v-icon color="red lighten-1">mdi-delete</v-icon></v-list-item-action>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-actions>

    <v-card-text>
      <v-text-field
          :label="$t('Name')"
          v-model="app.title"
          :error-messages="errorMessages['title']"
      ></v-text-field>

      <v-textarea
          :label="$t('Description')"
          rows="2"
          v-model="app.description"
          :error-messages="errorMessages['description']"
      ></v-textarea>

      <v-text-field
          :label="$t('Slug')"
          v-model="app.slug"
          :hint="$t('Only latin letters, underline and minus')"
          :error-messages="errorMessages['slug']"
      ></v-text-field>

      <v-select
          :label="$t('Choose file')"
          v-model="app.vfsItem"
          :items="flatTree"
          item-text="path"
          item-value="id"
          :error-messages="errorMessages['vfsItem']"
      ></v-select>

      <v-switch
          :label="$t('Publish')"
          v-model="app.isPublished"
          :error-messages="errorMessages['isPublished']"
      ></v-switch>
      <div class="d-flex">
        <div class="mt-5 body-1 mr-5">{{ $t('Application theme') }}</div>
        <v-radio-group row v-model="app.theme">
          <v-radio :label="$t('lightTheme')" :value="`light`"></v-radio>
          <v-radio :label="$t('darkTheme')" :value="`dark`"></v-radio>
        </v-radio-group>
      </div>
      <v-slider :label="$t('Font size')" v-model="app.fontSize" max="34" min="10"></v-slider>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="submit" :disabled="!formIsValid" :loading="loading">{{ $t('Update') }}</v-btn>
    </v-card-actions>

    <confirm-delete-dialog
        :showMenu.sync="deleteAppDialog"
        text="Delete this application?"
        @deletionConfirmed="deleteApp"
    ></confirm-delete-dialog>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";
import ConfirmDeleteDialog from "@/components/dialogs/ConfirmDeleteDialog";

export default {
  name: 'applications-add-view',
  components: {ConfirmDeleteDialog},
  data: () => ({
    loading: false,
    deleteLoading: false,
    flatTree: [],
    errorMessages: {},
    deleteAppDialog: false,
    app: {
      title: '',
      description: '',
      slug: '',
      vfsItem: '',
      isPublished: true,
      fontSize: 14
    }
  }),

  computed: {
    ...mapGetters(['vfs/flatPathTree', 'applications/getAppById']),
    title() {
      return this.app.title.length > 0 ? this.app.title : this.$t('Name')
    },

    slug() {
      let slug = this.app.slug.length > 0 ? this.app.slug : `<${this.$t('PublishedApplicationAddress')}>`
      return `${process.env.VUE_APP_PUBLISHED_APPS_HOST}/${slug}`
    },

    formIsValid() {
      return this.app.title !== '' &&
          this.app.vfsItem !== '' &&
          this.app.slug !== ''
    }
  },

  created() {
    this.flatTree = this['vfs/flatPathTree']
    this.app = this['applications/getAppById'](Number(this.$route.params.id))
    console.log(this.app)
  },

  methods: {
    submit() {
      let data = {'id': this.$route.params.id, ...this.app}
      this.loading = true
      this.$store.dispatch('applications/updateApp', data).then(() => {
        this.$router.push({name: 'ApplicationsList'})
        this.$store.dispatch('notifications/show', {'text': this.$t('Application updated')})
      }).catch((error) => {
        this.errorMessages = error.response.data
      }).finally(() => {
        this.loading = false
      })

    },

    deleteApp() {
      this.deleteLoading = true
      this.$store.dispatch('applications/deleteApp', Number(this.$route.params.id)).then(() => {
        this.$router.push({name: 'ApplicationsList'})
        this.$store.dispatch('notifications/show', {'text': this.$t('Application deleted')})
      }).catch((error) => {
        this.$store.dispatch('notifications/show', {'text': error.message, 'color': 'error'})
      }).finally(() => {
        this.deleteLoading = false
      })
    }
  },
}
</script>