<template>
  <v-card :outlined="outlined" :elevation="elevation">
    <public-editor
        :text.sync="messageObject[text]"
    ></public-editor>
    <v-card-actions>
      <v-btn text color="grey darken-2" @click="$emit('cancel')">
        {{ $t('Cancel') }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="$emit('submit', messageObject)" :loading="updateLoading">
        {{ $t('Save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import PublicEditor from "@/components/editor/PublicEditor";

export default {
  name: 'entity-content-editor',
  components: {PublicEditor},

  props: {
    messageObject: {
      type: Object
    },
    text: {// prop in the object which represent the text
      type: String,
      default: 'text'
    },
    updateLoading: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    elevation: {
      type: Number,
      default: 0
    }
  },
}
</script>