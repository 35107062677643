export const messages = {
  assistant: {
    fixErrorWithoutSolution: {
      title: 'Подскажи, как исправить ошибку, но не показывай решение',
      description: 'Программа выдает ошибку или не проходит проверку. Подскажи, как это исправить, но не показывай решение.',
    },
    fixError: {
      title: 'Помоги исправить ошибку',
      description: 'Покажи, что нужно изменить в программе для исправления ошибки или прохождения проверки.',
    },
    pep8: {
      title: 'Соответствует ли мой код стандарту PEP8?',
      description: 'Соответствует ли мой код стандарту PEP8?',
    },
    hint: {
      title: 'С чего мне начать решение задачи?',
      description: 'С чего мне начать решение задачи?',
    },
    solution: {
      title: 'Покажи решение задачи',
      description: 'Как мы решаем эту задачу.',
    }
  },
  payment: {
    title: 'Разблокируйте Ваш путь в мир кода'
  }
}