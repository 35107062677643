<template>
  <v-list>
    <v-list-item :key="`item-social-menu-${i}`" :to="item.route" v-for="(item, i) in menuItems">
      <v-list-item-icon>
        <v-icon>{{ item.icon }}</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          {{ item.title }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: 'social-titles-view',

  data: () => ({
    loading: true
  }),

  computed: {
    ...mapState(['social']),
    menuItems() {
      if (process.env.VUE_APP_I18N_LOCALE === 'ru') {
        return [
          {
            title: this.$t('My page'),
            icon: 'mdi-account-circle-outline',
            route: '/social/my'
          },
          {
            title: this.$t('My applications'),
            icon: 'mdi-apps',
            route: '/social/applications'
          },
          {
            title: this.$t('Settings'),
            icon: 'mdi-cogs',
            route: '/social/settings'
          },
          {
            title: this.$t('Subscription'),
            icon: 'mdi-circle-multiple',
            route: '/social/subscription'
          },
          {
            title: this.$t('Buy gift card'),
            icon: 'mdi-gift-outline',
            route: '/social/gift-purchase'
          },
          {
            title: this.$t('My purchases'),
            icon: 'mdi-cart-outline',
            route: '/social/purchases'
          },
          {
            title: this.$t('My courses'),
            icon: 'mdi-school-outline',
            route: '/social/courses'
          }
        ]
      } else {
        return [
          {
            title: this.$t('My page'),
            icon: 'mdi-account-circle-outline',
            route: '/social/my'
          },
          {
            title: this.$t('Settings'),
            icon: 'mdi-cogs',
            route: '/social/settings'
          }
        ]
      }
    }
  },

  created() {

  },
}
</script>

