<template>
  <v-dialog v-model="showMenuInner" max-width="500" persistent>
      <v-card>
        <v-card-title>
          {{ $t('E-mail address required') }}
        </v-card-title>
        <v-card-text>
        <slot></slot>
        </v-card-text>
        <v-card-text class="pt-5">
          <v-text-field
                :label="$t('E-mail address')"
                v-model="username"
                :error-messages="usernameErrors"
              ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="cancel" text>{{ $t('Cancel') }}</v-btn>
          <v-btn :loading="usernameLoading" @click="saveUsername();" text color="primary">{{ $t('Save') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
import ShowMixin from "@/mixins/ShowMixin";

export default {
  name: 'set-username-dialog',
  mixins: [ShowMixin],
  props: {
    closable: {
      type: Boolean,
      default: false
    }
  },
  data: ()=> {
    return {
      usernameLoading: false,
      username: '',
      usernameErrors: null
    }
  },

  methods: {
    cancel() {
      if (this.closable) {
        this.showMenuInner = false;
      } else {
        this.$router.back();
      }
    },

    saveUsername() {
      this.usernameLoading = true;
      this.$store.dispatch('user/updateProfile', {'username': this.username}).then(() => {
        if (this.closable) {
          this.showMenuInner = false;
        }
      }, (error)=>{
        this.usernameErrors = error.response.data.username;
      }).finally(()=>{
        this.usernameLoading = false;
      })
    },
  }
}

</script>