import UsersPublicService from '@/services/UsersPublicService.js'


export const namespaced = true

export const state = {

}

export const mutations = {

}

export const actions = {
  fetchUserById(_, id) {
    return UsersPublicService.get(id)
  },

  likeUserById(_, id) {
    return UsersPublicService.post(undefined, `${id}/like`)
  }
}

export const getters = {

}