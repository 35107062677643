<template>
  <section>
    <div class="d-flex justify-space-between pt-4 pl-4 pb-4 pr-2">
      <span v-if="($route.name === 'questionList') || ($route.name === 'questionList_') " class="subheading align-self-center">{{ $t('Questions') }}</span>
      <v-btn v-else text @click="$router.push({name: $route.path.startsWith('/lessons') ? 'questionList': 'questionList_'})">{{ $t('BackToQList') }}</v-btn>
      <v-btn v-if="$route.path.startsWith('/lessons') || $route.path.startsWith('/course')" class="align-self-center" text color="primary" :to="{name: $route.path.startsWith('/lessons') ? 'questionAdd' : 'questionAdd_'}">{{ $t('Submit question') }}</v-btn>
    </div>

    <v-skeleton-loader
      v-if="questionsLoading"
      class="mx-auto"
      type="list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line"
    ></v-skeleton-loader>
    <router-view v-else></router-view>
  </section>
</template>
<script>
import { BASE_COURSE_NAME } from '@/legacy.js'
export default {
  name: 'lesson-questions',

  data: () => ({
    questionsLoading: true,
  }),

  created() {
    // Fetching questions here because this component render first
    // and then we use them in child components.
    // So if user hit reload button we don't need to fetch
    // questions list again in child components.
    // Also we need to reset questions only when navigating between lessons,
    // to avoid constant network data usage.
    this.$store.dispatch('questions/reset')
    let params = {
      'sectionId': this.$route.params.sectionId,
      'lessonId': this.$route.params.lessonId
    }
    if ((this.$route.params.courseId) && (this.$route.params.courseId !== BASE_COURSE_NAME)) {
      params['courseId'] = this.$route.params.courseId
    } else {
      params['courseId__isnull'] = true
    }
    this.$store.dispatch('questions/fetch', params).then(() => {
      this.questionsLoading = false
    })
  },

}
</script>
