import CommonToolsService from '@/services/CommonToolsService.js'

export const namespaced = true

export const state = {
  markdown: null
}

export const mutations = {
  SET_MD(state, markdown) {
    state.markdown = markdown
  }
}

export const actions = {
  markdown({ commit }, text) {
    return CommonToolsService.post({'text': text}, 'markdown').then((response) => {
      commit('SET_MD', response.data)
      return response
    })
  },

  setNullMarkdown({ commit }) {
    commit('SET_MD', null)
  },

  rateContent({ commit }, content) {
    return CommonToolsService.post(content, 'rate-content')
  },

  unRateContent({ commit }, content) {
    return CommonToolsService.delete('rate-content', content)
  }
}


export const getters = {

}