import Vue from 'vue'
import VueGtag from "vue-gtag";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const canUseAnalytics = (cookies.get('cookieConsent') || {}).analytics || process.env.VUE_APP_APPLICATION_MODE === 'letpy.com';

Vue.use(VueGtag, {
  enabled: (process.env.NODE_ENV === 'production') && canUseAnalytics,
  config: { id: process.env.VUE_APP_GTAG_ID }
});