var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.tab.isActive)?_c('AceEditor',{attrs:{"lang":_vm.editorMode,"theme":_vm.theme,"width":_vm.width,"height":_vm.height,"left":_vm.left,"right":_vm.right,"bottom":_vm.bottom,"focus":_vm.focus,"options":{
        fontSize: _vm.settings.preferences.fontSize.value,
        highlightActiveLine: true,
        showLineNumbers: true,
        tabSize: 4,
        showPrintMargin: false,
        showGutter: true,
        showInvisibles: _vm.settings.preferences.showInvisible.value
      }},on:{"init":_vm.editorInit,"saveContent":_vm.saveContent,"onFocus":_vm.onFocus},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }