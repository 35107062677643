class Tk {
  constructor(id) {
    this.id = id;
    this.w = document.createElement('div')
    this.w.className = 'tkinter__window'
    this.w.style.transform = 'translate(-50%, -50%)'
    this.w.style.top = `${window.innerHeight / 2}px`
    this.w.style.left = `${window.innerWidth / 2}px`
    this.w.style.minWidth = `30px`
    this.w.style.minHeight = `20px`
    let header = document.createElement('div')
    header.className = 'tkinter__window_title'
    header.addEventListener("mousedown", (e) => {
      let startX = e.clientX
      let startY = e.clientY

      let onMouseMove = (e) => {
        this.w.style.top = `${this.w.offsetTop - startY + e.clientY}px`
        this.w.style.left = `${this.w.offsetLeft - startX + e.clientX}px`
        startY = e.clientY;
        startX = e.clientX;
      }

      let onMouseUp = () => {
        document.removeEventListener('mousemove', onMouseMove)
        document.removeEventListener('mouseup', onMouseUp);
      }
      document.addEventListener('mousemove', onMouseMove)
      document.addEventListener('mouseup', onMouseUp);
    })

    let closeButton = document.createElement('a')
    closeButton.className = 'tkinter__window_title__close'
    closeButton.innerText = '×'

    closeButton.addEventListener('click', () => {
      if (interpreter.interruptExecution !== undefined) {
        interpreter.interruptExecution();
      }
    });

    this.titleContainer = document.createElement('div')
    this.titleContainer.innerText = 'tk'

    header.appendChild(this.titleContainer);
    header.appendChild(closeButton);

    this.w.appendChild(header)
    document.body.appendChild(this.w);

    this.container = document.createElement('div')
    this.container.style.position = 'relative'
    this.container.style.minWidth = `300px`
    this.container.style.minHeight = `200px`
    this.w.appendChild(this.container);
  }

  geometry(width, height) {
    this.container.style.width = `${width}px`
    this.container.style.height = `${height}px`
  }

  title(text) {
    this.titleContainer.innerText = text
  }
}

class BaseShape {
  constructor(el) {
    this.el = el;
    this.el.addEventListener('mouseover', () => {
      this.el.setAttributeNS(null, "fill",this.activefill || this.fill);
      this.el.setAttributeNS(null, "stroke",this.activeoutline || this.outline);
      this.el.setAttributeNS(null, "stroke-dasharray",this.activedash || this.dash)
    });

    this.el.addEventListener('mouseout', () => {
      this.el.setAttributeNS(null, "fill", this.fill || 'none');
      this.el.setAttributeNS(null, "stroke", this.outline || 'none');
      this.el.setAttributeNS(null, "stroke-dasharray", this.dash || 'none')
    });
  }

  config(kwargs) {
    for (let key in kwargs) {
      switch (key) {
        case 'fill':
          this.fill = kwargs[key] || 'none';
          this.el.setAttributeNS(null, "fill", this.fill);
          break

        case 'activefill':
          this.activefill = kwargs[key] || undefined;
          break

        case 'activeoutline':
          this.activeoutline = kwargs[key] || undefined;
          break

        case 'outline':
          this.outline = kwargs[key]
          this.el.setAttributeNS(null, "stroke", this.outline);
          break

        case 'width':
          this.width = kwargs[key]
          this.el.setAttributeNS(null, "stroke-width", this.width);
          break

        case 'dash':
          this.dash = kwargs[key]
          this.el.setAttributeNS(null, "stroke-dasharray", this.dash);
          break

        case 'activedash':
          this.activedash = kwargs[key]
          break

        default:
          console.warn(`Unknown config key ${key}`)
      }
    }
  }
}

class Rectangle extends BaseShape {
  constructor(x0, y0, x1, y1, kwargs) {
    super(document.createElementNS("http://www.w3.org/2000/svg", "rect"));
    this.coords(x0, y0, x1, y1);
    this.config(kwargs)
  }

  coords(x0, y0, x1, y1) {
    this.el.setAttributeNS(null, "x", (x0 < x1) ? x0 : x1)
    this.el.setAttributeNS(null, "y", (y0 < y1) ? y0 : y1)
    this.el.setAttributeNS(null, "width", Math.abs(x1 - x0).toString())
    this.el.setAttributeNS(null, "height", Math.abs(y1 - y0).toString())
  }
}

class Oval extends BaseShape {
  constructor(x0, y0, x1, y1, kwargs) {
    super(document.createElementNS("http://www.w3.org/2000/svg", "ellipse"));
    this.coords(x0, y0, x1, y1);
    this.config(kwargs)
  }

  coords(x0, y0, x1, y1) {
    this.el.setAttributeNS(null, "cx", ((x0 < x1) ? x0 : x1) + Math.abs(x1 - x0) / 2)
    this.el.setAttributeNS(null, "cy", ((y0 < y1) ? y0 : y1) + Math.abs(y1 - y0) / 2)
    this.el.setAttributeNS(null, "rx", Math.abs(x1 - x0) / 2)
    this.el.setAttributeNS(null, "ry", Math.abs(y1 - y0) / 2)
  }
}

class Arc extends BaseShape {
  constructor(x0, y0, x1, y1, kwargs) {
    super(document.createElementNS("http://www.w3.org/2000/svg", "path"));
    this.saveCoordinates(x0, y0, x1, y1);
    this.config(kwargs)
  }

  saveCoordinates(x0, y0, x1, y1) {
    this.x0 = x0
    this.y0 = y0
    this.x1 = x1
    this.y1 = y1
  }

  config(kwargs) {
    if (kwargs.style !== undefined) {
      this.style = kwargs.style;
      delete kwargs.style;
    }

    if (kwargs.start !== undefined) {
      this.start = kwargs.start;
      delete kwargs.start;
    }

    if (kwargs.extent !== undefined) {
      this.extent = kwargs.extent;
      delete kwargs.extent;
    }

    super.config(kwargs)
    this.coords(this.x0, this.y0, this.x1, this.y1);
  }

  coords(x0, y0, x1, y1) {
    this.saveCoordinates(x0, y0, x1, y1)

    let start = this.start
    let extent = this.extent
    let xRadius = Math.abs(x1 - x0) / 2
    let yRadius = Math.abs(y1 - y0) / 2
    let startX = Math.sin(start * (Math.PI/180)) * xRadius + (((x0 < x1) ? x0 : x1) + xRadius)
    let startY = Math.cos(start * (Math.PI/180)) * yRadius + (((y0 < y1) ? y0 : y1) + yRadius)

    let endX = Math.sin((start + extent) * (Math.PI/180)) * xRadius + (((x0 < x1) ? x0 : x1) + xRadius)
    let endY = Math.cos((start + extent) * (Math.PI/180)) * yRadius + (((y0 < y1) ? y0 : y1) + yRadius)

    let largeArcFlag = 0
    if (extent > 180) {
      largeArcFlag = 1
    }

    // PIESLICE
    let d = '';
    if (this.style === 'pieslice') {
      d = `M${endX} ${endY} L ${((x0 < x1) ? x0 : x1) + xRadius} ${((y0 < y1) ? y0 : y1) + yRadius} L ${startX} ${startY}`
    } else if (this.style === 'chord') {
      d = `M${endX} ${endY} L ${startX} ${startY}`
    } else {
      d = `M${startX} ${startY}`
    }
    d += ` A ${xRadius} ${yRadius} 0 ${largeArcFlag} 0 ${endX} ${endY}`

    this.el.setAttributeNS(null, "d", d)
  }

}

class Line extends BaseShape {
  constructor(...args) {
    super(document.createElementNS("http://www.w3.org/2000/svg", "path"));
    this.kwargs = args.pop();
    this.coordinates = args;
    this.config(this.kwargs)

  }

  config(kwargs) {
    if (kwargs.fill !== undefined) {
      kwargs.outline = kwargs.fill;
    }
    if (kwargs.arrow !== undefined) {
      this.arrow = kwargs.arrow;
      delete kwargs.arrow;
    }

    if (kwargs.arrowshape !== undefined) {
      this.arrowshape = kwargs.arrowshape;
      delete kwargs.arrowshape;
    }

    super.config(kwargs)

    this.coords(...this.coordinates);
  }

  coords(...args) {
    if (args.length % 2 !== 0) {
      args.pop();
    }

    this.coordinates = [...args];
    let coords_ = [...this.coordinates];
    let x0 = coords_.shift();
    let y0 = coords_.shift();
    let d = `M${x0} ${y0}`;

    while (coords_.length > 0) {
      let x = coords_.shift();
      let y = coords_.shift();
      d += ` L ${x} ${y}`
    }

    coords_ = [...this.coordinates];
    while (coords_.length > 2) {
      let y = coords_.pop();
      let x = coords_.pop();
      d += ` L ${x} ${y}`
    }
    this.el.setAttributeNS(null, "d", d)
  }
}

class Polygon extends BaseShape {
  constructor(...args) {
    super(document.createElementNS("http://www.w3.org/2000/svg", "path"));
    this.kwargs = args.pop();
    this.coordinates = args;
    this.config(this.kwargs)

  }

  config(kwargs) {
    super.config(kwargs)
    this.coords(...this.coordinates);
  }

  coords(...args) {
    if (args.length % 2 !== 0) {
      args.pop();
    }

    this.coordinates = [...args];
    let coords_ = [...this.coordinates];
    let x0 = coords_.shift();
    let y0 = coords_.shift();
    let d = `M${x0} ${y0}`;

    while (coords_.length > 0) {
      let x = coords_.shift();
      let y = coords_.shift();
      d += ` L ${x} ${y}`
    }
    d += ` L ${x0} ${y0}`
    this.el.setAttributeNS(null, "d", d)
  }
}

class Text extends BaseShape {
  constructor(x, y, text, anchor, font, width, justify, kwargs) {
    super(document.createElementNS("http://www.w3.org/2000/svg", "foreignObject"));

    kwargs.text = text
    kwargs.anchor = anchor
    kwargs.font = font
    kwargs.width = width
    kwargs.justify = justify
    kwargs.x = x
    kwargs.y = y

    this.config(kwargs)
    this.coords(this.x, this.y)
  }

  coords(x, y) {
    if ((this.anchor === 'n') || (this.anchor === 'center') || (this.anchor === 's')) {
      this.el.setAttributeNS(null, "x", x - this.cWidth / 2)
    } else if ((this.anchor === 'ne') || (this.anchor === 'e') || (this.anchor === 'se')) {
      this.el.setAttributeNS(null, "x", x - this.cWidth)
    } else {
      this.el.setAttributeNS(null, "x", x)
    }

    if ((this.anchor === 'w') || (this.anchor === 'center') || (this.anchor === 'e')) {
      this.el.setAttributeNS(null, "y", y - this.cHeight / 2)
    } else if ((this.anchor === 'sw') || (this.anchor === 's') || (this.anchor === 'se')) {
      this.el.setAttributeNS(null, "y", y - this.cHeight)
    } else {
      this.el.setAttributeNS(null, "y", y)
    }
  }

  config(kwargs) {
    for (let key in kwargs) {
      switch (key) {
        case 'x':
          this.x = kwargs[key]
          break
        case 'y':
          this.y = kwargs[key]
          break

        case 'text':
          this.text = kwargs[key]
          break
        case 'anchor':
          this.anchor = kwargs[key]
          break

        case 'font':
          this.font = kwargs[key]
          break

        case 'width':
          this.width = kwargs[key]
          break

        case 'justify':
          this.justify = kwargs[key]
          break

        case 'fill':
          this.fill = kwargs[key] || 'none';
          // this.el.setAttributeNS(null, "fill", this.fill);
          break
        /*
        case 'activefill':
          this.activefill = kwargs[key] || undefined;
          break

        case 'outline':
          this.outline = kwargs[key]
          this.el.setAttributeNS(null, "stroke", this.outline);
          break

        case 'width':
          this.width = kwargs[key]
          this.el.setAttributeNS(null, "stroke-width", this.width);
          break
        */
        default:
          console.warn(`Unknown config key ${key}`)
      }
    }

    let container = document.createElement('div')
    if (this.width === null) {
      container.style.whiteSpace = 'nowrap'
    } else {
      container.style.overflowWrap = 'break-word'
      container.style.maxWidth = `${this.width}px`
    }

    container.style.userSelect = 'none'
    container.innerText = this.text

    if (this.justify === 'left') {
      container.style.textAlign = 'left'
    } else if (this.justify === 'right') {
      container.style.textAlign = 'right'
    } else if (this.justify === 'center') {
      container.style.textAlign = 'center'
    }

    let font = this.font
    if (Array.isArray(font)) {
      if (font.length > 0) {
        container.style.fontFamily = font[0]
      }
      if (font.length > 1) {
        container.style.fontSize = `${font[1]}px`
      }
      if ((font.length > 2) && (font[2].toLowerCase().includes('bold'))) {
        container.style.fontWeight = 'bold';
      }
      if ((font.length > 2) && (font[2].toLowerCase().includes('italic'))) {
        container.style.fontStyle = 'italic';
      }
    }

    container.style.position = 'absolute'
    container.style.color = this.fill
    document.body.appendChild(container)
    this.cHeight = container.offsetHeight + 1
    this.cWidth = container.offsetWidth + 1
    //document.body.removeChild(container)

    container.setAttribute('xmlns', "http://www.w3.org/1999/xhtml")
    container.style.position = 'relative'

    this.el.setAttributeNS(null, "width", this.cWidth)
    this.el.setAttributeNS(null, "height", this.cHeight)
    if (this.el.childElementCount > 0) {
      this.el.replaceChild(container, this.el.children[0])
    } else {
      this.el.appendChild(container)
    }

    this.coords(this.x, this.y)
  }

}

class Widget {
  constructor(id, master) {
    this.id = id;
    this.master = tkObjects[master];
    this.el = null;
  }

  bind(sequence) {
    switch (sequence.toLowerCase()) {
      case '<button-1>':
        this.el.addEventListener('click', (event) => {
          interpreter.sendToWorker({"widget_id": this.id, 'sequence': '<button-1>', 'data': {
              x: event.offsetX,
              y: event.offsetY
            }}, dataArray, eventArray)
        });
        break
    }
  }
}

class Canvas extends Widget {
  constructor(id, master, kwargs) {
    super(id, master);
    const height = kwargs.height || 200;
    const width = kwargs.width || 300;

    let container = document.createElement('div');
    container.style.height = `${height}px`;
    container.style.width = `${width}px`;
    this.el = container;

    this.svg = document.createElementNS("http://www.w3.org/2000/svg", "svg")
    this.svg.setAttributeNS(null, "viewBox", `0 0 ${width} ${height}`)
    this.svg.setAttributeNS(null, "width", `${width}`)
    this.svg.setAttributeNS(null, "height", `${height}`)
    container.appendChild(this.svg);
    this.master.container.appendChild(container);
    this.objects = {};
  }

  create_rectangle(_id, x0, y0, x1, y1, kwargs) {
    this.objects[_id] = new Rectangle(x0, y0, x1, y1, kwargs);
    this.svg.appendChild(this.objects[_id].el);
  }

  create_oval(_id, x0, y0, x1, y1, kwargs) {
    this.objects[_id] = new Oval(x0, y0, x1, y1, kwargs);
    this.svg.appendChild(this.objects[_id].el);
  }

  create_arc(_id, x0, y0, x1, y1, kwargs) {
    this.objects[_id] = new Arc(x0, y0, x1, y1, kwargs);
    this.svg.appendChild(this.objects[_id].el);
  }

  create_line(_id, ...args) {
    this.objects[_id] = new Line(...args);
    this.svg.appendChild(this.objects[_id].el);
  }

  create_text(_id, x, y, text, anchor, font, width, justify, kwargs) {
    this.objects[_id] = new Text(x, y, text, anchor, font, width, justify, kwargs);
    this.svg.appendChild(this.objects[_id].el);
  }

  create_polygon(_id, ...args) {
    this.objects[_id] = new Polygon(...args);
    this.svg.appendChild(this.objects[_id].el);
  }

  coords(canvasElementId, ...args) {
    let o = this.objects[canvasElementId];
    o.coords(...args);
  }

  itemconfig(canvasElementId, kwargs) {
    let o = this.objects[canvasElementId];
    o.config(kwargs)
  }

  delete(canvasElementId) {
    let o = this.objects[canvasElementId];
    this.svg.removeChild(o.el)
    delete this.objects[canvasElementId];
  }
}


let tkObjects = null;
let interpreter = null
let dataArray = null;
let eventArray = null;

export function TkinterInit(interpreter_, dataArray_, eventArray_) {
  tkObjects = {};
  interpreter = interpreter_;
  dataArray = dataArray_;
  eventArray = eventArray_;
}

export function TkinterHandle(data) {

  if (data.method === '__init__') {
    if (tkObjects[data.id] !== undefined) {
      console.warn(`tkObject with id=${data.id} already exists`);
    }
    if (data.class_name === 'Tk') {
      tkObjects[data.id] = new Tk(data.id, ...data.args);
    } else if (data.class_name === 'Canvas') {
      tkObjects[data.id] = new Canvas(data.id, ...data.args, data.kwargs);
    } else {
      console.warn(`Unknown tk class ${data.class_name}`);
    }
  } else {
    tkObjects[data.id][data.method](...data.args, data.kwargs);
  }
}