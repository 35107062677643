<template>
  <section>
    <v-skeleton-loader v-if="getLessonsLoading"
          type="list-item-two-line, list-item, list-item, list-item, list-item-two-line, list-item, list-item, list-item-two-line, list-item, list-item-two-line, list-item, list-item, list-item"
        ></v-skeleton-loader>
    <section class="text-center pa-16" v-else-if="lessons.topics.length === 0">
      <h3 class="text-h5 grey--text">{{ $t("Choose the course" ) }}</h3>
      <p class="grey--text pa-2">{{ $t('The lessons list will appear here when you choose the course') }}</p>

      <v-icon size="64" class="grey--text">mdi-book-open-page-variant-outline</v-icon>
    </section>


    <v-list v-else :disabled="getLessonsLoading">
      <v-list-group
       :key="topic.slug"
       :value="$route.params.sectionId === topic.slug"
       v-for="topic in lessons.topics"
      >
        <template v-slot:activator>
          <v-list-item-title><strong>{{  topic.title }}</strong></v-list-item-title>
        </template>
        <v-list-item
          v-for="lesson in topic.lessons"
          :key="lesson.slug"
          :to="{'name': 'questionList_', 'params': {'courseId': getCourseId, 'lessonId': lesson.slug, 'sectionId': topic.slug}}"
        >
          <v-list-item-content>
            <v-list-item-title>{{ lesson.title }} <span>#{{ lesson.number }}</span></v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon v-if="!user.paymentInfo.hasPremiumAccess && !lesson.isPassed && lesson.isPaid" color="grey">mdi-lock-outline</v-icon>
            <v-icon v-else-if="lesson.isPassed && !lesson.isQuiz && !lesson.isPractice" color="green">mdi-check</v-icon>
            <v-icon v-else-if="lesson.isQuiz" :color="lesson.isPassed ? 'green': 'grey'">mdi-format-list-text</v-icon>
            <v-icon v-else-if="lesson.isPractice" :color="lesson.isPassed ? 'green': 'grey'">mdi-text</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list-group>
    </v-list>


  </section>
</template>

<script>
import {mapState, mapGetters} from "vuex";

export default {
  name: 'lesson-titles-view',

  data: () => ({
    loading: true
  }),

  computed: {
    ...mapState(['lessons', 'user']),
    ...mapGetters({
      'getCourseId': 'lessons/getCourseId',
      'getLessonsLoading': 'lessons/getLessonsLoading'
    })
  },
  // lessons fetches in the App.vue, because we use them in the router
  // and maybe some other places. So we need to fetch them as sooner as possible.
}
</script>

<style scoped>
.v-list-item__title > span {
  opacity: 0.5;
}
</style>
