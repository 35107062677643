<template>
  <v-card flat class="pb-10">
    <v-card-title>
      {{ $t('My applications') }}
    </v-card-title>

    <v-list v-if="applications.list.length > 0">
      <v-list-item
          two-line
          :key="`item-application-${i}`"
          v-for="(item, i) in applications.list"
          :to="`/social/applications/${item.id}`"
      >
        <v-list-item-content>
          <v-list-item-title>
            {{ item.title }}
          </v-list-item-title>
          <v-list-item-subtitle v-html="getApplicationAddress(item.slug)">
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action-text v-if="item.isPublished" class="green--text">
          {{ $t('Published') }}
        </v-list-item-action-text>
        <v-list-item-action>
          <v-btn icon @click.prevent="gotoApplication(item.slug)">
            <v-icon>mdi-link</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>

    <v-card-text class="body-1" v-else>
      <p class="font-weight-regular subheading" v-html="$t('PublishedApplicationsInfo')">
      </p>
      <p
          v-if="!user.paymentInfo.hasPremiumAccess"
          class="font-weight-regular subheading"
          v-html="$t('PublishedApplicationsPaymentInfo', {'url': $router.resolve({name: 'Subscription'}).href})">
      </p>
    </v-card-text>

    <v-btn v-if="user.paymentInfo.hasPremiumAccess"
        class="mx-2"
        fab
        dark
        color="primary"
        bottom
        right
        fixed
        to="/social/applications/add"
    >
      <v-icon dark>
        mdi-plus
      </v-icon>
    </v-btn>
  </v-card>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: 'applications-content-view',
  data: () => ({

  }),

  computed: {
    ...mapState(['applications', 'user']),

  },

  methods: {
    getApplicationAddress(slug) {
      return `${process.env.VUE_APP_PUBLISHED_APPS_HOST}/${slug}`
    },

    gotoApplication(slug) {
      let win = window.open(this.getApplicationAddress(slug), '_blank');
      win.focus();
    }
  }
}
</script>