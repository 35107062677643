<template>
  <transition name="fade" mode="out-in" appear>
    <div class="ide">
      <v-app-bar clipped-left clipped-right dense app fixed flat outlined>
        <v-app-bar-nav-icon small :color="filesDrawer ? '': 'grey'" @click="filesDrawer=!filesDrawer"><v-icon>mdi-file-tree</v-icon></v-app-bar-nav-icon>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">

                <img v-on="on" v-bind="attrs"  v-if="settings.preferences.darkTheme.value" :src="require('@/assets/logo-light.png')" class="app-logo"/>
                <img v-on="on" v-bind="attrs" v-else :src="require('@/assets/logo-dark.png')" class="app-logo"/>

            </template>
            <span>{{ $t('appVersion', {version: version}) }}</span>
           </v-tooltip>
        <v-spacer></v-spacer>
        <v-badge dot overlap left color="error" :value="$_interpreter.loading" class="pulse" transition="fab-transition">
          <v-btn color="green" :disabled="!isRunCodeAvailable" :loading="$interpreter.isRunning && !lessons.verificationInProgress" icon small @click="runCode"><v-icon>mdi-play</v-icon></v-btn>
        </v-badge>
        <v-btn
            v-if="isDebuggerAvailable"
            color="orange"
            :disabled="!isRunDebugAvailable"
            icon small @click="nextStep"><v-icon>mdi-step-forward</v-icon></v-btn>
        <v-btn color="red" :disabled="!$interpreter.isRunning || lessons.verificationInProgress" icon small @click="stopExecution"><v-icon>mdi-stop</v-icon></v-btn>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-badge
            :content="activity.newNotificationsCount"
            :value="activity.newNotificationsCount"
            color="red"
            overlap
        >
          <v-btn icon small @click="activityDrawer = !activityDrawer"><v-icon>mdi-bell-outline</v-icon></v-btn>
        </v-badge>

        <profile-menu></profile-menu>


      </v-app-bar>

      <v-navigation-drawer
          clipped
          app
          v-model="filesDrawer"
          v-resizable="{gutter: 'left'}"
          mobile-breakpoint="0"
          :height="height"
          :width="filesPanelWidth"
          :temporary="$vuetify.breakpoint.mdAndDown"
      >
        <v-toolbar dense flat color="transparent">
          <v-toolbar-title class="subtitle-2">
            {{ $t('myFiles') }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="grey"
                v-bind="attrs"
                v-on="on"
                icon
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>

            <v-list width="200px">
              <v-list-item @click="openFilesDialog('file')">
                <v-list-item-title>{{ $t('File') }}</v-list-item-title>
                <v-list-item-action><v-icon>mdi-file</v-icon></v-list-item-action>
              </v-list-item>
              <v-list-item @click="openFilesDialog('folder')">
                <v-list-item-title>{{ $t('Folder') }}</v-list-item-title>
                <v-list-item-action><v-icon>mdi-folder</v-icon></v-list-item-action>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
        <div :style="{height: filesHeight, overflowY: `auto`}">
          <user-files v-if="user.info.appVersion >= '2.1'" @openFilesDialog="openFilesDialog" @openRenameNodeDialog="openRenameNodeDialog"></user-files>
          <files-menu v-else @openFilesDialog="openFilesDialog" @openRenameNodeDialog="openRenameNodeDialog"></files-menu>
        </div>
      </v-navigation-drawer>

      <editor-tabs-2 v-if="user.info.appVersion === '2.1'"></editor-tabs-2>
      <editor-tabs v-else></editor-tabs>
      <div v-if="user.info.appVersion === '2.1'">
        <code-editor-2 ref="editor-2" v-for="tab in userFiles.tabs" :key="tab.path" :tab = tab></code-editor-2>
      </div>
      <div v-else>
        <code-editor ref="editor" v-for="tab in vfs.tabs" :key="tab.item.id" :tab = tab></code-editor>
      </div>



      <code-editor-placeholder v-if="(vfs.tabs.length === 0) && (userFiles.tabs.length === 0)"></code-editor-placeholder>

      <helper-drawers
        :helper-drawer.sync="helperDrawer"
        :helper-titles-drawer.sync="helperTitlesDrawer"
      ></helper-drawers>

      <activity-drawer :show-menu.sync="activityDrawer"></activity-drawer>

      <bottom-drawer
        v-resizable="{borderTarget: '.v-navigation-bottom-drawer__border', gutter: 'bottom'}"
      ></bottom-drawer>

      <create-node
        :showMenu.sync="filesDialog"
        :fileType.sync="fileType"
        :parentID.sync="fileParentID"
      ></create-node>

      <rename-node
        :showMenu.sync="renameDialog"
        :node.sync="renameNode"
      ></rename-node>

      <tkinter></tkinter>
      <turtle></turtle>
    </div>
  </transition>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import BottomDrawer from "@/components/drawers/BottomDrawer.vue";
import HelperDrawers from '@/components/drawers/HelperDrawers.vue'
import ActivityDrawer from "@/components/drawers/ActivityDrawer.vue";
import CodeEditor from "@/components/editor/CodeEditor.vue";
import CodeEditor2 from "@/components/editor/CodeEditor2.vue";
import CodeEditorPlaceholder from "@/components/editor/CodeEditorPlaceholder.vue";
import FilesMenu from "@/components/files/FilesMenu.vue";
import RenameNode from "@/components/files/RenameNode.vue";
import CreateNode from "@/components/files/CreateNode.vue";
import EditorTabs from "@/components/editor/CodeEditorTabs.vue";
import EditorTabs2 from "@/components/editor/CodeEditorTabs2.vue";
import Interpreter from "@/plugins/interpreter/Interpreter";
import SizeMixin from "@/mixins/SizeMixin";
import ProfileMenu from "@/components/ProfileMenu.vue";
import Tkinter from "@/plugins/interpreter/Tkinter";
import UserFiles from "@/components/files/UserFiles.vue";
import Turtle from '@/plugins/interpreter/Turtle'
import InnerActionsMixin from '@/mixins/InnerActionsMixin'

export default {
  components: {
    Turtle,
    UserFiles,
    Tkinter,
    ProfileMenu,
    CreateNode, RenameNode, FilesMenu, ActivityDrawer, BottomDrawer, CodeEditor, CodeEditor2, CodeEditorPlaceholder, EditorTabs, EditorTabs2, HelperDrawers},
  mixins: [SizeMixin, InnerActionsMixin],

  data: () => ({
    filesDialog: false,
    fileType: null,
    fileParentID: null,
    renameDialog: false,
    renameNode: null,
    activityDrawer: false
  }),

  mounted() {
    document.addEventListener("keydown", this.onKeyDown);
  },

  destroyed() {

    document.removeEventListener("keydown", this.onKeyDown);
  },

  watch: {
    '$_interpreter.loading': function (value) {
      if (!value) {
        this.registerInnerAction('interpreterSuccessfullyLoaded')
      }
    }
  },

  computed: {
    ...mapState(['settings', 'user', 'lessons', 'vfs', 'tabs', 'activity', 'userFiles']),
    ...mapGetters(['vfs/currentFile', 'userFiles/current']),

    isRunCodeAvailable() {
      if (this.$_interpreter.loading) {
        return false;
      }
      else if (((this['vfs/currentFile'] === undefined) || (!this['vfs/currentFile'].name.endsWith('.py'))) && ((this['userFiles/current'] === undefined) || (!this['userFiles/current'].title.endsWith('.py')))) {
        return false;
      }
      else if (this.$interpreter.isRunning && this.$interpreter.mode === 'debug') {
        return false;
      }
      else if (this.lessons.verificationInProgress) {
        return false;
      }
      else {
        return true;
      }
    },

    isDebuggerAvailable() {
      if (process.env.VUE_APP_APPLICATION_MODE === 'letpy.com') {
        return this.user.paymentInfo.hasPremiumAccess;
      } else {
        return true;
      }
    },

    isRunDebugAvailable() {
      // Old code from template
      // $_interpreter.loading || (_self['vfs/currentFile'] === undefined) || !_self['vfs/currentFile'].name.endsWith('.py') || ($interpreter.isRunning && $interpreter.mode === 'normal') || lessons.verificationInProgress

      if (this.$_interpreter.loading) {
        return false;
      }
      else if (((this['vfs/currentFile'] === undefined) || (!this['vfs/currentFile'].name.endsWith('.py'))) && ((this['userFiles/current'] === undefined) || (!this['userFiles/current'].title.endsWith('.py')))) {
        return false;
      }
      else if (this.$interpreter.isRunning && this.$interpreter.mode === 'normal') {
        return false;
      }
      else if (this.lessons.verificationInProgress) {
        return false;
      }
      else {
        return true;
      }
    },
    version() {
      return process.env.VUE_APP_VERSION
    },

    filesPanelWidth() {
      if ((this.$vuetify.breakpoint.width - 50) < this.settings.UI.gutters.left) {
        return this.$vuetify.breakpoint.width - 50;
      }
      return this.settings.UI.gutters.left
    },

    height() {
      if (this.$vuetify.breakpoint.mdAndDown) {
        return '100vh';
      }
      return `calc(100vh - ${this.settings.UI.gutters.top}px - ${this.bottom}px)`
    },

    filesHeight() {
      if (this.$vuetify.breakpoint.mdAndDown) {
        return 'calc(100vh - 48px)';
      }
      return `calc(100vh - ${this.settings.UI.gutters.top}px - ${this.bottom}px - 48px)`
    },

    helperTitlesDrawer: {
      get() {
        return this.settings.UI.helperTitlesDrawer
      },

      set(boolean) {
        this.settings.UI.helperTitlesDrawer = boolean
      }
    },

    filesDrawer: {
      get() {
        return this.settings.UI.filesDrawer
      },

      set(boolean) {
        this.settings.UI.filesDrawer = boolean
      }
    },

    helperDrawer: {
      get() {
        return this.settings.UI.helperDrawer
      },

      set(boolean) {
        this.$store.dispatch('settings/updateUI', {helperDrawer: boolean})
      }
    }
  },

  methods: {
    onKeyDown(event) {
      if (navigator.userAgent.indexOf('Mac') !== -1) {
        /**
         * Run code (mac)
         * Ctrl + R (Pycharm)
         */

        if ((event.code === 'KeyR') && (event.ctrlKey)) {
          event.preventDefault();
          this.runCode();
        }

        /**
         * Run debug mode (Mac)
         * Ctrl + D
         */
        if ((event.code === 'KeyD') && (event.ctrlKey)) {
          event.preventDefault();
          if (!this.$interpreter.isRunning) {
            this.nextStep();
          }
        }
      } else if (navigator.userAgent.indexOf('Win') !== -1) {
        /**
         * Run code (Win)
         * Shift + Alt + F10 (Pycharm)
         */

        if ((event.code === 'F10') && (event.shiftKey) && (event.altKey)) {
          event.preventDefault();
          this.runCode();
        }

        /**
         * Run debug mode (Win)
         * Shift + F9
         */
        if ((event.code === 'F9') && (event.shiftKey)) {
          event.preventDefault();
          if (!this.$interpreter.isRunning) {
            this.nextStep();
          }
        }
      }

      /**
       * Step over
       * F8
       */
      if (event.code === 'F8') {
        event.preventDefault();
        if (this.$interpreter.isRunning && this.$interpreter.mode === 'debug') {
          this.nextStep();
        }
      }
    },

    nextStep() {
      if (this.$interpreter.isRunning) {
        Interpreter.nextStep();
      } else {
        Interpreter.runFile(this['vfs/currentFile'] || this['userFiles/current'], true);
      }
    },

    runCode() {
      Interpreter.runFile(this['vfs/currentFile'] || this['userFiles/current'])
    },

    stopExecution() {
      Interpreter.stopExecution();
    },

    openFilesDialog(type, parentID=null) {
      this.filesDialog = true
      this.fileType = type
      this.fileParentID = parentID
    },

    openRenameNodeDialog(node) {
      this.renameDialog = true
      this.renameNode = node
    },

    saveCurrentFileContent() {
      this.$store.dispatch(
        'files/updateFileContent',
        {'id': this['vfs/currentFile'].id, 'content': this['vfs/currentFile'].content}
      )
    }
  },
}
</script>

<style>

img.app-logo  {
  height: 32px;
  margin-left: 20px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all .2s ease;
}
.slide-fade-leave-active {
  transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

@keyframes pulseAnimation  {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
      box-shadow: 0 0 0 10px rgba(255, 82, 82,  0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(255, 82, 82,  0);
      box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

.pulse .v-badge__wrapper span {
  animation: pulseAnimation 1.3s infinite;
}
</style>
