<template>
  <v-sheet>
    <router-view/>
  </v-sheet>
</template>

<script>
export default {
  name: 'workbook-content-view',

  watch: {
    '$route'(to) {
      if (to.path.startsWith('/workbook')) {
        this.$store.dispatch('navigation/setPath', {tab: 'workbook', path: to.path });
      }
    }
  }
}
</script>

