<template>
  <v-avatar :color="color" :size="size">
    <v-img v-if="photo" :src="photo"></v-img>
    <span v-else class="white--text subtitle-2" style="margin: 0 auto;">{{ placeholder.toUpperCase() }}</span>
  </v-avatar>
</template>

<script>
export default {
  name: "Avatar",
  props: {
    photo: {
      type: String
    },

    placeholder: {
      type: String
    },

    id: {
      type: Number
    },
    size: {
      type: Number,
      default: 32
    }
  },

  computed: {
    color() {
      let colors = [
          "red darken-1", "red darken-4", "pink darken-1", "pink darken-4", "purple darken-1", "purple darken-4", "deep-purple darken-1", "deep-purple darken-4",
          "indigo darken-1", "indigo darken-4", "blue darken-1", "blue darken-4", "light-blue darken-1", "light-blue darken-4", "cyan darken-1", "cyan darken-4",
          "teal darken-1", "teal darken-4", "green darken-1", "green darken-4", "light-green darken-1", "light-green darken-4", "yellow darken-4", "yellow darken-3",
          "amber darken-3", "orange darken-1", "orange darken-4", "brown darken-1", "brown darken-4", "blue-grey darken-1", "blue-grey darken-4"
      ]
      if (!this.id) {
        return 'transparent'
      } else {
        return colors[this.id % colors.length]
      }

    }
  }
}
</script>

<style scoped>

</style>