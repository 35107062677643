import {mapState} from "vuex";

export default {
  computed: {
    ...mapState(['settings']),

    theme() {
      if (this.settings.preferences.darkTheme.value) {
        return "monokai"
      } else {
        return "github"
      }
    }
  },
}