<template>
  <div class="d-flex">
    <div class="align-self-center green--text" >{{ rating }}</div>
    <div class="align-self-center pl-2">
      <v-btn :loading="loading" icon color="green" small :disabled="!canRate" @click="rate();">
        <v-icon small v-if="isRatedByUser">mdi-thumb-up</v-icon>
        <v-icon small v-else>mdi-thumb-up-outline</v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>
export default {
  name: "rate-content",
  props: ['itemType', 'itemId', 'canRate', 'isRatedByUser', 'rating'],

  methods: {
    rate() {
      this.loading = true;
      let payload = {
        itemType: this.itemType,
        itemId: this.itemId
      }
      if (!this.isRatedByUser) {
        this.$store.dispatch('commonTools/rateContent', payload).then((response) => {
          this.$emit('update:isRatedByUser', true);
          this.$emit('update:rating', response.data.rating);
          this.loading = false;
        })
      } else {
        this.$store.dispatch('commonTools/unRateContent', payload).then((response) => {
          this.$emit('update:rating', response.data.rating);
          this.$emit('update:isRatedByUser', false)
          this.loading = false;
        });
      }

    }
  },
  data() {
    return {
      loading: false
    }
  }
}
</script>