<template>
  <section v-if='lessons.currentLesson.isPractice'>
    <transition name="fade-transition" mode="out-in" appear>
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="primary"
        height="1"
      ></v-progress-linear>

      <div v-else>

      <transition name="slide-y-transition" mode="out-in" appear @enter='scrollToTheEnd'>
        <div v-if="assistantMessages.length === 0" :key='1'>
          <div class='text-3xl text-center pt-8 pb-4'>
            {{ $t('How can I help?') }}
          </div>
          <!--v-list-item @click="handleAction(item)" two-line
            v-for="(item, index) in actions"
            :key="index"
          >
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
              <v-list-item-subtitle>{{ item.text }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item-->
        </div>
      <ProseContainer :key='2' v-else >
      <v-card v-for="message in assistantMessages" :key='message.id' flat outlined class="mx-4 mb-2 flex-grow-1 overflow-hidden">

        <div class='dark:bg-darkest-2 py-2 px-4 flex justify-between bg-light'>
          <div v-if='!message.isReply'>
            <v-avatar size="24" color="primary" class='mr-4'>
              <v-img v-if="user.info.photo" :src="user.info.photo"/>
              <span v-else class="white--text text-sm">{{ user.info.photoPlaceholder.toUpperCase() }}</span>
            </v-avatar>
            <span class='font-bold'>{{ $t('You') }}</span>
          </div>

          <div v-else>
            <v-avatar size='24' class='mr-2'>
              <img :src="require('@/assets/logo-sm.png')"/>
            </v-avatar><span class="font-bold text-transparent bg-clip-text bg-gradient-to-r from-brand-yellow to-brand-green">{{ $t('AssistantName') }}</span>
          </div>

          <timeago class="grey--text" :datetime="message.createdAt" :auto-update="60"></timeago>
        </div>

          <div
            class="pa-4 "
            :class="(message.isReply && message.status === 'pending') ? `[&>*:last-child]:after:content-['●'] [&>*:last-child]:after:inline-block [&>*:last-child]:after:ml-2 [&>*:last-child]:after:animate-pulse` : ``"

               v-hljs v-html='message.html'>
          </div>
          </v-card>

      </ProseContainer>
      </transition>
      </div>
    </transition>
    <div class='px-4'>
      <v-chip @click="handleAction(item)"
              v-for="(item, index) in actions"
              :key="index" class='m-1'
      >{{ item.title }}</v-chip>
    </div>


    <div ref='marker'></div>
      <!-- v-menu top>
        <template v-slot:activator="{ on, attrs }">
          <v-fab-transition>
            <v-btn
              v-if='assistantMessages.length > 0'
              style='z-index: 100'
              class="mx-2 my-1"
              fab
              dark
              large
              v-bind="attrs"  v-on="on"
              fixed
              right
              bottom
              color='green'
              :loading='assistantLoading'
            >
              <v-icon dark>
                mdi-help
              </v-icon>
            </v-btn>
          </v-fab-transition>
        </template>
        <v-list>
          <div class="px-4 py-4 text-xl">{{ $t('How can I help?') }}</div>

          <v-list-item @click="handleAction(item)" two-line
            v-for="(item, index) in actions"
            :key="index"
          >
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
              <v-list-item-subtitle style='white-space: none'>{{ item.text }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu-->
  </section>
  <section v-else>
    <div class='text-4xl pa-4'>
      {{ $t("This isn't a practice lesson")}}
    </div>
    <p class='pa-4'>
      {{ $t("You can get help only in practice lessons") }}
    </p>
  </section>
</template>
<script>
import Avatar from "@/components/Avatar";
import SizeMixin from '@/mixins/SizeMixin'
import { mapGetters, mapState } from 'vuex'
import CourseService from '@/services/CourseService'
import { marked } from 'marked'
import Interpreter from '@/plugins/interpreter/Interpreter'
import ActiveContent from '@/components/ActiveContent.vue'
import ProseContainer from '@/components/ProseContainer.vue'

export default {
  name: 'GetUnstuck',
  mixins: [SizeMixin],
  components: {
    ProseContainer,
    ActiveContent,
    Avatar,
  },
  computed: {
    ...mapState(['lessons', 'settings', 'navigation', 'user']),
    ...mapGetters(['vfs/currentFile', 'userFiles/current', 'lessons/getAssistantMessages']),

    assistantMessages() {
      return this['lessons/getAssistantMessages'];
    },
    currentFile() {
      return (this['vfs/currentFile'] || this['userFiles/current']);
    },
    assistantLoading() {
      for (let message in this.assistantMessages) {
        if (this.assistantMessages[message].status === 'pending') {
          return true;
        }
      }
      return false;
    },

  },

  async mounted() {
    this.initActions();
    await this.fetch();
    this.$socket.subscribe(this.onSocketEvent)
  },

  destroyed() {
    this.$socket.unsubscribe(this.onSocketEvent)
  },

  methods: {
    initActions () {
      this.actions = [
        {
          title: this.$t('assistant.fixErrorWithoutSolution.title'),
          text: this.$t('assistant.fixErrorWithoutSolution.description'),
          type: 'error_no_solution'
        },
        {
          title: this.$t('assistant.fixError.title'),
          text: this.$t('assistant.fixError.description'),
          type: 'error'
        },
        {
          title: this.$t('assistant.pep8.title'),
          text: this.$t('assistant.pep8.description'),
          type: 'pep8'
        }
      ];
    },
    async fetch() {
      this.loading = true;
      const response = await this.$store.dispatch('lessons/fetchAssistantMessages', {lessonId: this.lessons.currentLesson.id});
      const currentLesson = this.lessons.currentLesson;

      if (currentLesson.hints && currentLesson.hints.length > 1) {
        this.actions.push({'title': this.$t('assistant.hint.title'), text: this.$t('assistant.hint.description'), type: 'hint'})
      }
      if (currentLesson.hints && currentLesson.hints.length > 0) {
        this.actions.push({'title': this.$t('assistant.solution.title'), text: this.$t('assistant.solution.description'), type: 'solution'})
      }


      this.loading = false;
    },
    scrollToTheEnd() {
      this.$refs.marker.scrollIntoView()
    },

    onSocketEvent(event) {
      if (event.type === 'update.assistant_message') {
        this.$store.dispatch('lessons/updateAssistantMessage', event.message);
      }
    },

    async handleErrorAction(action) {
      try {
        await Interpreter.verifyFile_(this.currentFile, this.lessons.currentLesson);
        await this.$store.dispatch('notifications/show', {'text': this.$t("Your program doesn't contain errors and is compliant with the task."), color: 'success', timeout: 10000})

      } catch (e) {
        await CourseService.post({
          sourceCode: this.currentFile.content,
          action: action,
          errorMessageType: e.error_message_type,
          errorMessage: e.error_message,
        }, `assist-me/${this.lessons.currentLesson.id}`)

        await this.$store.dispatch('lessons/fetchAssistantMessages', {lessonId: this.lessons.currentLesson.id});

      }
    },

    async handlePEP8Action() {
      try {
        await Interpreter.verifyFile_(this.currentFile, this.lessons.currentLesson);await Interpreter.verifyFile_(this.currentFile, this.lessons.currentLesson);
        const currentLesson = this.lessons.currentLesson;
        await CourseService.post({
          sourceCode: this.currentFile.content,
          action: 'pep8',
        }, `assist-me/${currentLesson.id}`)

        await this.$store.dispatch('lessons/fetchAssistantMessages', {lessonId: this.lessons.currentLesson.id});

      } catch (e) {
        if (e.error_message_type === "exception") {
          await this.$store.dispatch('notifications/show', {'text': this.$t("Your program contains errors. You should fix that before checking for PEP8 compliance."), color: 'success', timeout: 10000})
        } else {
          await this.$store.dispatch('notifications/show', {'text': this.$t("Your program doesn't comply with the task. You should fix that before checking for PEP8 compliance."), color: 'success', timeout: 10000})
        }
      }

    },

    async handleActionAndSolution(action) {
      const currentLesson = this.lessons.currentLesson;
      await CourseService.post({
        sourceCode: this.currentFile.content,
        action: action,
      }, `assist-me/${currentLesson.id}`)

      await this.$store.dispatch('lessons/fetchAssistantMessages', {lessonId: this.lessons.currentLesson.id});
    },


    async handleAction(action) {
      switch (action.type) {
        case 'error':
          await this.handleErrorAction('error')
          break
        case 'error_no_solution':
          await this.handleErrorAction('error_no_solution')
          break
        case 'pep8':
          await this.handlePEP8Action()
          break
        case 'hint':
          await this.handleActionAndSolution(action.type)
          break
        case 'solution':
          await this.handleActionAndSolution(action.type)
          break
      }
    }
  },
  watch: {
    'assistantMessages': {
      handler(val) {
        this.$nextTick(() => {
          if (this.$refs.marker) {
            this.$refs.marker.scrollIntoView({behavior: 'smooth'})
          }
        })
      },
      deep: true
    },
    'lessons.currentLesson.number': {
      handler(val) {
        this.initActions();
        this.fetch()
      }
    }

  },
  data: () => ({
    loading: true,
    actions: []
  }),
}
</script>
<style>
.test > *:last-child:after {
  content: "●";
  line-height: normal;
  margin-left: 0.25rem;
  vertical-align: baseline;
}
</style>