<template>
  <div v-if="pricesInfo">
    <v-card flat>

        <v-card-title class='text-4xl font-normal'>
          <slot>
            {{ title }}
          </slot>
        </v-card-title>

      <v-card-text>
        <ProseContainer>
          <div v-if="text" v-html="text"></div>
        </ProseContainer>
      </v-card-text>
          <div class="bg-brand-green px-4 py-5" v-if="pricesInfo.header && locale === 'ru'">
            <h2 class="text-white font-bold text-2xl " v-html="pricesInfo.header">
            </h2>
            <div class="text-white text-lg my-4" v-html="pricesInfo.description"></div>

            <div class="text-white text-lg my-4">
              Стоимость доступа к курсу «Python для начинающих» без ограничений по времени
            </div>

            <div class='text-3xl text-white text-center'>
              <span class="line-through opacity-60 text-4xl">{{ price.crossedPrice }}</span>
              {{ price.price }} {{ price.currencySign }}
            </div>

          </div>

      <v-card-text v-if="locale === 'ru'">
      <ProseContainer>
        <!--]div class="text-center my-4" v-if="pricesInfo.countdownHeader" v-html="pricesInfo.countdownHeader"></div>

        <div v-if="countdown" class="d-flex justify-center">
          <div class="d-flex countdown text-center">
            <div v-if="countdown.days > 0" class="display-3 mr-8">
              {{ countdown.days }}
              <p class="body-1">{{ $tc('daysCount', countdown.days) }}</p>
            </div>
            <div class="display-3">
              {{ countdown.hours }}
              <p class="body-1">{{ $tc('hoursCount', countdown.hours) }}</p>
            </div>
            <div class="display-3 ml-8">
              {{ countdown.minutes.toString().padStart(2, '0') }}
              <p class="body-1">{{ $tc('minutesCount', countdown.minutes) }}</p>
            </div>

            <div class="display-3 ml-8">
              {{ countdown.seconds.toString().padStart(2, '0') }}
              <p class="body-1">{{ $tc('secondsCount', countdown.seconds) }}</p>
            </div>
          </div>
        </div>

        <div>
          Для того чтобы узнать подробности, выбрать тариф и оплатить подписку
          перейдите на страницу оплаты.

        </div>

        <div class="d-flex justify-space-around mt-8">
          <v-btn depressed color="green" link :href="paymentUrl">{{ $t('Payment page') }}</v-btn>
        </div-->
        <h2>
          Выберите способ оплаты
        </h2>
          <v-radio-group v-model="selectedPaymentMethod" class='payment'>
            <v-radio :value="paymentMethod.currencyCode" v-for="(paymentMethod, index) in paymentMethods" style='align-items: start;' :key='index'>
              <template v-slot:label>
                <div class='p-2'>
                  <div class='font-normal dark:text-white text-brand-dark'>{{ paymentMethod.title }}</div>
                  <div class='flex gap-x-4 items-center pt-2'>
                    <v-img v-for="(icon, i) in paymentMethod.icons" :src="require(`@/assets/${icon.src}`)" :max-width="icon.width/6" :max-height="icon.height/6" :alt='icon.alt' :key='i'/>
                  </div>
                </div>
              </template>
            </v-radio>
          </v-radio-group>

        <!--v-select v-model="selectedPaymentMethod"
                  :items="paymentMethods"
                  outlined
                  item-value="currencyCode"
                  persistent-hint
                  single-line
        >
          <template v-slot:selection="{ item }">
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>
                <div class='flex gap-x-4 items-center pt-2'>
                  <v-img v-for="(icon, i) in item.icons" :src="require(`@/assets/${icon.src}`)" :max-width="icon.width/4" :max-height="icon.height/4" :alt='icon.alt' :key='i'/>
                </div>

              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-slot:item="{ item, attrs, on }">
            <v-list-item three-line v-bind="attrs" v-on="on">
              <v-list-item-content>
                <v-list-item-title :id="attrs['aria-labelledby']">
                  {{ item.title }}
                </v-list-item-title>
                <div class='flex gap-x-4 items-center pt-2'>
                  <v-img v-for="(icon, i) in item.icons" :src="require(`@/assets/${icon.src}`)" :max-width="icon.width/4" :max-height="icon.height/4" :alt='icon.alt' :key='i'/>
                </div>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-select-->

        <div class="d-flex justify-space-around">
          <v-btn :loading="paymentLoading" depressed rounded color='#50AB25' @click="pay" x-large dark>
            Оплатить<span style='opacity: 0.9;margin-left:8px;'>{{ price.price }} {{ price.currencySign }}</span>
          </v-btn>
        </div>


      </ProseContainer>
      </v-card-text>
    </v-card>
    <div class="text-center pa-10 text-gray-600 dark:text-gray-300" v-if="locale === 'ru'">
      <p v-html="$t('bottomPaymentInfo1')"></p>
      <p v-html="$t('bottomPaymentInfo2', {'url': priceUrl })"></p>
    </div>
  </div>
</template>

<script>
import PaymentTokenService from "@/services/PaymentTokenService";
import PricesService from "@/services/PricesService";
import {mapState} from "vuex";
import InnerActionsMixin from "@/mixins/InnerActionsMixin";
import ProseContainer from '@/components/ProseContainer.vue'

export default {
  name: 'inner-payment',
  components: { ProseContainer },
  mixins: [InnerActionsMixin],
  props: {
    isGiftPayment: {
      required: false,
      default: false
    }
  },

  data: () => ({
    paymentLoading: false,
    pricesInfo: null,
    pricesInfoInterval: null,
    countdownInterval: null,
    priceUrl: `${process.env.VUE_APP_MAIN_HOST}/price/`,
    price: null,
    selectedPaymentMethod: null,
    paymentMethods: [{
      title: 'Картой банка Республики Беларусь',
      id: 1,
      currencyCode: 'BYN',
      icons: [
          {
          src: 'cards/visa.png',
          alt: 'visa',
          width: 196,
          height: 60
        },
        {
          src: 'cards/master.png',
          alt: 'master',
          width: 129,
          height: 80
        },
        {
          src: 'cards/bel.png',
          alt: 'bel',
          width: 143,
          height: 160
        },
        {
          src: 'cards/apple.png',
          alt: 'apple',
          width: 126,
          height: 80
        },
        {
          src: 'cards/samsung.png',
          alt: 'samsung',
          width: 178,
          height: 80
        }
      ]

    }, {
      title: 'Картой или платежной системой РФ',
      id: 2,
      currencyCode: 'RUB',
      icons: [
        {
          src: 'cards/visa.png',
          alt: 'visa',
          width: 196,
          height: 60
        },
        {
          src: 'cards/master.png',
          alt: 'master',
          width: 129,
          height: 80
        },
        {
          src: 'cards/mir.png',
          alt: 'mir',
          width: 220,
          height: 60
        },
        {
          src: 'cards/yoomoney.png',
          alt: 'yoomoney',
          width: 382,
          height: 80
        },
        {
          src: 'cards/sber.png',
          alt: 'sber',
          width: 294,
          height: 80
        }
      ]
    }, {
      title: 'Зарубежной банковской картой',
      id: 2,
      currencyCode: 'EUR',
      icons: [
          {
          src: 'cards/visa.png',
          alt: 'visa',
          width: 196,
          height: 60
        },
        {
          src: 'cards/master.png',
          alt: 'master',
          width: 129,
          height: 80
        },
        {
          src: 'cards/apple.png',
          alt: 'apple',
          width: 126,
          height: 80
        },
        {
          src: 'cards/samsung.png',
          alt: 'samsung',
          width: 178,
          height: 80
        }
      ]
    }]
  }),

  computed: {
    ...mapState(['user']),
    locale() {
      return process.env.VUE_APP_I18N_LOCALE
    },
    // paymentUrl() {
    //   return `${process.env.VUE_APP_MAIN_HOST}/user/payment/`
    // },
    title() {
      if (this.isGiftPayment) {
        return this.$t('Buy gift card')
      } else {
        return this.$t('Subscription')
      }
    },

    text() {
      if (this.isGiftPayment && this.pricesInfo) {
        return this.pricesInfo.giftPurchaseText;
      } else if (this.pricesInfo) {
        return this.pricesInfo.text;
      }
      return null;
    },

    countdown() {
      if (this.pricesInfo !== null && this.pricesInfo.countdown !== null && this.pricesInfo.countdown.length > 0) {
        return this.pricesInfo.countdown[0];
      }
      return null;
    }
  },

  mounted() {
    this.registerInnerAction('viewPaymentInfo', {parent: this.$parent.$options.name})
    this.createIntervals();
  },

  activated() {
    this.createIntervals();
  },

  deactivated() {
    this.clearIntervals()
  },

  destroyed() {
    this.clearIntervals()
  },

  methods: {
    pay() {
      this.registerInnerAction('openPaymentExternal', {parent: this.$parent.$options.name})
      this.paymentLoading = true;
      PaymentTokenService.post({
        tariffId: this.price.tariffId,
        isGiftPayment: this.isGiftPayment,
        withReturnUrl: true,
        paymentSystem: (this.price.currencyCode === 'RUB') ? 'yookassa' : 'bepaid',
        currencyCode: this.selectedPaymentMethod
      }).then((response)=>{
        window.location.href = response.data.redirectUrl;
      }).finally(()=>{
        this.paymentLoading = false;
      })
    },

    createIntervals() {
      this.pricesInfoInterval = setInterval(() => {
        this.fetchPricesInfo();
      }, 60 * 1000)
      this.fetchPricesInfo();
    },

    clearIntervals() {
      clearInterval(this.countdownInterval);
      clearInterval(this.pricesInfoInterval);
    },

    fetchPricesInfo() {
      return PricesService.get('?all_currencies=true').then((response) => {
        if (this.pricesInfo === null) {
          this.pricesInfo = response.data;
          this.$emit('onFirstTimeLoad');
          for (let i in this.paymentMethods) {
            if (this.paymentMethods[i].currencyCode === this.pricesInfo.tariffs[0].currencyCode) {
              this.selectedPaymentMethod = this.paymentMethods[i].currencyCode;
              break;
            }
          }
        } else {
          let tariffInfo = response.data.tariffs.find(t => t.tariffId === this.pricesInfo.tariffId);
          if (tariffInfo) {
            delete response.data['tariffId'];
          }
          this.pricesInfo = {...this.pricesInfo, ...response.data}
          clearInterval(this.countdownInterval);
        }
        this.handleCountdown();
      });
    },


    handleCountdown() {
      if ((this.pricesInfo.countdown !== null) && (this.pricesInfo.countdown.length > 0)) {
        this.countdownInterval = setInterval(() => {
          if (this.pricesInfo.countdown.length > 0) {
            this.pricesInfo.countdown.shift();
            if (this.pricesInfo.countdown.length === 0) {
              this.fetchPricesInfo();
            }
          }
        }, 1000);
      }
    }
  },

  watch: {
    'selectedPaymentMethod': function (val) {
      // update price info
      this.price = this.pricesInfo.tariffs.find(t => t.currencyCode === val);
    }
    /*'user.info.registeredCountry': function(value) {
      this.updateRegisteredCountryLoading = true;
      this.$store.dispatch('user/updateProfile', {'registeredCountry': value}).then(() => {
        this.fetchPricesInfo().then(()=>{
          this.updateRegisteredCountryLoading = false;
        })
      });
    },*/
  }

}
</script>
<style>
.payment .v-input--selection-controls__input {
  padding-top: 12px !important;
}

.payment .v-input--selection-controls__ripple {
  top: calc(50% - 18px) !important;
}
</style>