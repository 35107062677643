<template>
  <section :class="[settings.preferences.darkTheme.value ? 'monokai-sublime-theme' : 'github-theme', 'pb-32']">
    <div ref='header'></div>
    <v-skeleton-loader
      v-if="loading"
      class="mx-auto"
      tile
      style='height: calc(100vh - 56px);'
      type="article, list-item-two-line, list-item-three-line, list-item, article, list-item-two-line, list-item-three-line, list-item, article, list-item-two-line, list-item-three-line, list-item"
    ></v-skeleton-loader>

    <div v-else-if='lessons.currentLesson' class="dark:bg-darkest bg-white pb-24">
      <div class='flex pa-4 items-start'>
        <h1 class='text-4xl'>
          <span v-html="lessons.currentLesson.title"></span>&nbsp;<span class='text-gray-400'>#{{ lessons.currentLesson.number }}</span>
        </h1>
        <div class='flex-1'></div>
        <div>

          <v-tooltip left>
          <template v-slot:activator="{on, attrs}">
            <v-btn
                icon
                v-on="on"
                v-bind="attrs"
                @click="bookmarkToggle()"
                :color="((lessons.currentLesson.isBookmarked) ? 'primary':null)"
                :loading="bookmarkLoading"
            >
              <v-icon v-if="!lessons.currentLesson.isBookmarked">mdi-bookmark-outline</v-icon>
              <v-icon v-else>mdi-bookmark-check-outline</v-icon>
            </v-btn>
          </template>
          <span v-if="!lessons.currentLesson.isBookmarked">{{ $t("Bookmark it") }}</span>
          <span v-else>{{ $t("Delete bookmark") }}</span>
          </v-tooltip>
        </div>
      </div>
      <div class="px-4" v-hljs v-plyr>
        <active-content :content="lessons.currentLesson.text"></active-content>
      </div>
      <lesson-quiz @onResultChanged="onQuizResultChanged" v-if="lessons.currentLesson.isQuiz" :items="lessons.currentLesson.quizItems"></lesson-quiz>

    </div>

    <LessonNotFound v-else-if='lessonError === 404'/>

    <InnerPayment v-if='lessonError === 402' @onFirstTimeLoad='loading = false'>
      {{ $t('payment.title') }}
    </InnerPayment>
    <router-view v-if="!loading && !lessonError"></router-view>

    <v-overlay :value="showPanel" @click='showPanel = !showPanel'></v-overlay>
    <div
      :style='{
        height: `${panelHeight}px`,
        transform: `translateY(${showPanel ? 0 : panelHeight - 8}px)`

      }'
      :class="[showPanel ? '': '', 'transition duration-150 ease-in-out absolute bottom-16 bg-white dark:bg-darkest right-0 left-0 md:left-[48px] z-50']">
      <div class='h-2 bg-green-600 hover:bg-green-500 transition duration-200 cursor-pointer' @click='showPanel = !showPanel'>
      </div>
      <div class='flex dark:bg-darkest-2 items-center bg-gray-light'>
        <v-tabs v-model="tab" background-color="transparent">
          <v-tab :key="'check'">
            Проверка
          </v-tab>
          <v-tab :key="'assistant'">
            Помощник
          </v-tab>
          <v-tab :key="'solution'">
            Мое решение
          </v-tab>
        </v-tabs>
        <v-btn icon @click='showPanel = !showPanel'>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <div class='overflow-scroll' :style='{height: `${panelHeight - 48}px`}' v-if='showPanel'>
        <v-tabs-items v-model='tab'>
        <v-tab-item :key="'check'" :transition="null" class='dark:bg-darkest'>
          <div ref="resultContainer" class="resultContainer">
            <transition name="fade" mode="out-in" appear>
              <v-card-text class="body-1" v-if="verificationInProgress">
                <transition name="fade" mode="out-in" appear>
                  <div class="text-center" v-if="!verification">
                    <verification-progress-animation :hasError="hasError"></verification-progress-animation>
                  </div>

                  <v-alert v-else-if="verification.result" text color="success" border="left" data-cy="verification-success">
                    <div v-html="verificationText" v-hljs style="padding-top: 15px;"></div>
                  </v-alert>

                  <v-alert v-else text color="error" border="left" data-cy="verification-error">
                    <div v-html="verificationText" v-hljs style="padding-top: 15px;"></div>
                  </v-alert>
                </transition>

              </v-card-text>
            </transition>
            </div>
        </v-tab-item>
        <v-tab-item :key="'assistant'" :transition="null" class='dark:bg-darkest'>
          <GetUnstuck/>
        </v-tab-item>
        <v-tab-item :key="'solution'" :transition="null" class='dark:bg-darkest'>
          <LessonSolutions/>
        </v-tab-item>

      </v-tabs-items>

      </div>
    </div>

    <div class='fixed bottom-0 pa-2 dark:bg-darkest-2 bg-gray-light left-0 md:left-[48px] right-0 z-50 h-16 flex items-center'>
      <div class='hidden md:flex md:items-center md:gap-2 md:w-full'>
        <v-btn
            v-if="lessons.currentLesson && lessons.currentLesson.prev !== null"
            :to="`/course/${this.$route.params.courseId}/${lessons.currentLesson.prev.sectionSlug}/${lessons.currentLesson.prev.slug}`"
            icon
        ><v-icon>mdi-arrow-left</v-icon></v-btn>

        <v-spacer/>


          <v-btn
            :disabled="lessons.verificationInProgress || $interpreter.loading"
            @click="verify"
            v-if="lessons.currentLesson && (lessons.currentLesson.isPractice || lessons.currentLesson.isQuiz)"
            rounded
            color="green"
            class="check-button"
            x-large
          >
            {{ $t('Check') }}
          </v-btn>

        <v-btn
            v-if="lessons.currentLesson && lessons.currentLesson.isPractice"
            outlined
            @click="assistant"
            rounded
            large
        >
          <v-icon left>mdi-help-circle-outline</v-icon>Помощник</v-btn
            >
          <v-btn
              v-if="lessons.currentLesson && lessons.currentLesson.next !== null"
              :loading="nextLoading"
              text
              rounded
              @click="next"
              data-cy="next-lesson"
          >{{ $t("Next") }}</v-btn>
      </div>



      <div class='flex items-center justify-between pr-12 md:hidden'>
        <v-btn
            v-if="lessons.currentLesson && lessons.currentLesson.prev !== null"
            :to="`/course/${this.$route.params.courseId}/${lessons.currentLesson.prev.sectionSlug}/${lessons.currentLesson.prev.slug}`"
            icon
            x-small
        ><v-icon>mdi-arrow-left</v-icon></v-btn>

          <v-btn
            :disabled="lessons.verificationInProgress || $interpreter.loading"
            @click="verify"
            v-if="lessons.currentLesson && (lessons.currentLesson.isPractice || lessons.currentLesson.isQuiz)"
            rounded
            large
            color="green"
            class="check-button"
          >
            {{ $t('Check') }}
          </v-btn>


        <v-btn
            v-if="lessons.currentLesson && lessons.currentLesson.isPractice"
            icon
            @click="assistant"

        ><v-icon>mdi-help-circle-outline</v-icon></v-btn>

          <v-btn
              v-if="lessons.currentLesson && lessons.currentLesson.next !== null"
              :loading="nextLoading"
              text
              rounded
              small
              @click="next"
              data-cy="next-lesson"
          >{{ $t("Next") }}</v-btn>
      </div>


    </div>

  </section>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import '@/styles/highlightjs/themes.scss'
import LessonNotFound from '@/views/lessons/LessonNotFound.vue'
import InnerPayment from '@/components/InnerPayment.vue'
import LessonQuiz from '@/views/lessons/Quiz.vue'
import ActiveContent from '@/components/ActiveContent.vue'
import GetUnstuck from '@/views/lessons/GetUnstuck.vue'
import LessonSolutions from '@/views/lessons/LessonSolutions.vue'
import VerificationProgressAnimation from '@/components/VerificationProgressAnimation.vue'
import Interpreter from '@/plugins/interpreter/Interpreter'
import { marked } from 'marked'
import InnerActionsMixin from '@/mixins/InnerActionsMixin'

export default {
  name: 'LessonComponent',
  components: { VerificationProgressAnimation, LessonSolutions, GetUnstuck, ActiveContent, LessonQuiz, InnerPayment, LessonNotFound },
  mixins: [InnerActionsMixin],
  computed: {
    ...mapState(['lessons', 'settings', 'navigation', 'user']),
    ...mapGetters(['vfs/currentFile', 'lessons/getLastPassedLesson', 'lessons/getDefinition', 'lessons/getCourseId', 'userFiles/current']),
    verificationText() {
      return marked.parse(this.verification.text);
    },

    currentFile() {
      return this['userFiles/current'];
    },

    panelHeight() {
      return (window.innerHeight / 2 + 64);
    }
  },

  created() {
    this.storeUrl();
    this.fetchCurrentLesson();
  },

  data: () => ({
    loading: false,
    lessonError: null,
    showPanel: false,
    nextLoading: false,
    tab: null,
    bookmarkLoading: false,
    quizResult: null,
    verification: null,
    verificationInProgress: false,
    hasError: false,
    verificationStartedAt: null,
  }),

  methods: {
    assistant() {
      this.showPanel = true;
      this.$nextTick (() => {
        this.tab = 1;
      })
    },

    showSnackbar(title, content) {
      this.$store.dispatch('notifications/show', {'text': `<div class='text-lg pb-2'>${title}</div> ${content}`, color: 'error', timeout: 10000})
    },

    next() {
      let currentLesson = this.lessons.currentLesson;

      if (!currentLesson.isPractice && !currentLesson.isQuiz) {
        if (currentLesson.prev && !currentLesson.prev.isPassed && !currentLesson.prev.canBeSkipped) {
          this.showSnackbar(this.$t('The previous lesson is not passed'), this.$t('prevLessonNotPassedText'));
        } else {
          this.nextLoading = true;
          this.$store.dispatch('lessons/passLesson', {
            lessonId: currentLesson.slug,
            sectionId: currentLesson.sectionSlug,
            courseId: this.$route.params.courseId // TODO: Replace with currentLesson property
          }).then(() => {
            this.registerInnerAction('passLesson', {
              'isPaid': currentLesson.isPaid,
              'sectionId': currentLesson.sectionSlug
            })
            this.nextLoading = false;
            this.$router.push({
              name: 'questionList_', params: {
                'sectionId': currentLesson.next.sectionSlug,
                'lessonId': currentLesson.next.slug,
                'courseId': this.$route.params.courseId // TODO: Replace with currentLesson property
              }
            });
          });
        }
      } else {
        if (!currentLesson.isPassed && !currentLesson.canBeSkipped) {
          this.showSnackbar(this.$t('Verification is required'), currentLesson.isPractice ? this.$t('practiceVerificationNeededText') : this.$t('quizVerificationNeededText'))
        } else {
          this.$router.push({
            name: 'questionList_',
            params: {
              'sectionId': currentLesson.next.sectionSlug,
              'lessonId': currentLesson.next.slug,
              'courseId': this.$route.params.courseId // TODO: Replace with currentLesson property
            }
          });
        }
      }
    },

    onQuizResultChanged(payload) {
      this.quizResult = payload;
    },

    bookmarkToggle() {
      this.bookmarkLoading = true
      let data = {
        'sectionId': this.lessons.currentLesson.sectionSlug,
        'lessonId': this.lessons.currentLesson.slug,
        'courseId': this.lessons.currentLesson.courseId
      }

      this.$store.dispatch(`bookmarks/${((!this.lessons.currentLesson.isBookmarked) ? 'add':'remove')}`, data).then(() => {
        this.$store.dispatch('lessons/isBookmarkedToggle', this.lessons.currentLesson)
        this.$store.dispatch(
            'notifications/show',
            {
              show: true,
              text: this.$t(`Bookmark ${((this.lessons.currentLesson.isBookmarked) ? 'added':'removed')}`),
              color: 'primary'
            }
        )
      }).finally(() => {
        this.bookmarkLoading = false
      })
    },

    showResultContainer() {
      //this.$refs['resultContainer'].style.height = 'auto';
    },

    startAnimationQueue() {
      this.$store.dispatch('lessons/startVerification');
      // Interpreter.hideCanvasWindow(); // is that still needed?
      new Promise(function(resolve) {
        setTimeout(() => resolve(), 200);  // At first, we show result container
      }).then(() => {
        // after that we show verification animation
        this.verificationInProgress = true;
        this.hasError = false;
        // scroll to result container
        //this.$refs.resultContainer.scrollIntoView({behavior: 'smooth', block: 'center'});

      });
    },

    stopInterpreterIfRunning(response) {
      if (this.$interpreter.isRunning) {
        Interpreter.stopExecution();
        return new Promise((resolve) => {
          let interval = setInterval(() => {
            if (!this.$interpreter.isRunning) {
              clearInterval(interval);
              resolve(response);
            }
          }, 300)
        })
      } else {
        return new Promise((resolve) => {
          resolve(response);
        })
      }
    },

    waitShowResultContainerAnimation(response) {
      return new Promise((resolve) => {
        let timeout = 300 - (new Date().getTime() - this.verificationStartedAt)
        setTimeout(() => resolve(response.data), timeout > 0 ? timeout : 0);
      });
    },

    verifyCurrentFile(response) {
      return new Promise((resolve, reject) => {
        Interpreter.verifyFile(this.currentFile, this.lessons.currentLesson).then((result)=>{
          resolve([result, response])
        }, (error)=> {
          reject([error, response])
        });
      });
    },

    handleVerificationSuccess(result) {
      let lessonVerificationHistory = result[1];
      let verificationResult = result[0];
      return Promise.all([
          this.updateLessonVerificationHistory(lessonVerificationHistory, true),
          this.$store.dispatch('lessons/passLesson', lessonVerificationHistory)
      ]).then(() => {  // [verificationHistoryResponse, passLessonResponse]
        let currentLesson = this.lessons.currentLesson;
        this.registerInnerAction('passLesson', {
          'isPaid': currentLesson.isPaid,
          'sectionId': currentLesson.sectionSlug
        })

        let timeout = 2000 - (new Date().getTime() - this.verificationStartedAt)
        setTimeout(() => {
          this.verification = verificationResult;
          this.$store.dispatch('lessons/finishVerification');
        }, timeout > 0 ? timeout : 0)
      });
    },

    handleVerificationFail(error) {
      let lessonVerificationHistory = error[1];
      let verificationResult = error[0];
      return this.updateLessonVerificationHistory(lessonVerificationHistory, false).then(() => {
        this.hasError = true;
        setTimeout(() => {
          this.verification = verificationResult;
          this.$store.dispatch('lessons/finishVerification');
        }, 500)
      });
    },

    updateLessonVerificationHistory(lessonVerificationHistory, status) {
      lessonVerificationHistory.status = status ? 1 : 0;
      lessonVerificationHistory.output = {
        'stdout': '' //this.$stdout  // TODO: replace with bot-stdout
      }
      return this.$store.dispatch('lessons/updateLessonVerificationHistory', lessonVerificationHistory)
    },

    async verify() {
      if (this.lessons.currentLesson.prev && !this.lessons.currentLesson.prev.isPassed && !this.lessons.currentLesson.prev.canBeSkipped) {
        this.showSnackbar(this.$t('The previous lesson is not passed'), this.$t('prevLessonPassingNeededForVerificationText'))
        return;
      }
      if (this.lessons.currentLesson.isPractice && this.currentFile === undefined) {
        this.showSnackbar(this.$t('Open a file with the code'), this.$t('currentFileNeededForVerificationText'))
        return;
      } else if (this.lessons.currentLesson.isPractice && this.currentFile.title.split('.').pop() !== 'py') {
        this.showSnackbar(this.$t('Open a file with the code'), this.$t('currentFileIsNotPythonProgramText'));
        return;
      }
      this.showPanel = true;
      this.tab = 0; // check

      this.verification = null;
      this.showResultContainer();
      this.startAnimationQueue();
      this.verificationStartedAt = new Date().getTime();
      if (this.lessons.currentLesson.isPractice) {
        this.$store.dispatch(
          'lessons/createLessonVerificationHistory', {
            'sectionId': this.$route.params.sectionId,
            'lessonId': this.$route.params.lessonId,
            'courseId': this.$route.params.courseId,
            'status': 100,
            'source': this.currentFile.content
          }
        )
          .then(this.stopInterpreterIfRunning)
          .then(this.waitShowResultContainerAnimation)
          .then(this.verifyCurrentFile)
          .then(this.handleVerificationSuccess, this.handleVerificationFail)
      } else {
        if (this.quizResult.result) {
          this.$store.dispatch('lessons/passLesson', {
            'sectionId': this.$route.params.sectionId,
            'lessonId': this.$route.params.lessonId,
            'courseId': this.$route.params.courseId
          }).then(() => {
            let currentLesson = this.lessons.currentLesson;
            this.registerInnerAction('passLesson', {
              'isPaid': currentLesson.isPaid,
              'sectionId': currentLesson.sectionSlug
            })

            let timeout = 2000 - (new Date().getTime() - this.verificationStartedAt)
            setTimeout(() => {
              this.verification = this.quizResult;
              this.$store.dispatch('lessons/finishVerification');
            }, timeout > 0 ? timeout : 0)
          })
        } else {
          setTimeout(() => {
            this.hasError = true;
            setTimeout(() => {
              this.verification = this.quizResult;
              this.$store.dispatch('lessons/finishVerification');
            }, 500)
          }, 1000)
        }
      }
    },

      // try {
      //   const result = await Interpreter.verifyFile_(this.currentFile, this.lessons.currentLesson);
      //   console.log(result)
      //
      // } catch (e) {
      //   console.log(e, 'error')
      // }
    fetchCurrentLesson() {
      if (this.$refs.header) {
        this.$refs.header.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      this.loading = true;

      if (this.$route.params.courseId !== this['lessons/getCourseId']) {
        this.$store.dispatch('lessons/fetchLessons', this.$route.params.courseId)
      }

      this.verificationInProgress = false;
      //this.verificationAttempts = 0;


      return this.$store.dispatch('lessons/fetchLessonContent', {
        'topicId': this.$route.params.sectionId,
        'lessonId': this.$route.params.lessonId,
        'courseId': this.$route.params.courseId
        // eslint-disable-next-line no-undef
      }).then((response) => {
        this.loading = false;
        this.lessonError = null;
        // this.registerInnerAction('visitLesson', response.data)
      }, (error) => {
        if (error.response && error.response.status === 402) {
          if (this.lessonError === 402) {
            this.loading = false;
          }
          this.lessonError = 402
          // Show payment info. Also, we need to clear current lesson.
          this.$store.dispatch('lessons/clearCurrentLesson');
        } else if (error.response && error.response.status === 404) {
          this.lessonError = 404
          this.loading = false;
        } else {
          this.loading = false;
          return Promise.reject(error);
        }
      })
    },

    storeUrl() {
      this.$store.dispatch('navigation/setPath', {tab: 'lessons', path: this.$router.currentRoute.path});
    },
  },

  watch: {
    '$route'(to) {
      if ((to.params.lessonId && to.params.sectionId && to.params.courseId)) {
        this.storeUrl();
        const newLessonId = `${to.params.courseId}/${to.params.sectionId}/${to.params.lessonId}`;
        if (!this.lessons.currentLesson) {
          this.fetchCurrentLesson();
        } else {
          const oldLessonId = `${this['lessons/getCourseId']}/${this.lessons.currentLesson.sectionSlug}/${this.lessons.currentLesson.slug}`;
          if (newLessonId !== oldLessonId) {
            this.$store.dispatch('questions/reset')
            this.showPanel = false;
            this.verification = null
            this.fetchCurrentLesson();
          }
        }

      }
    }
  }
}
</script>