import { Base64 } from 'js-base64';
import PyodideWorkerSrc from "!!raw-loader!../interpreter/Pyodide.worker";
import pyodideRunSrc from "!!raw-loader!../interpreter/scripts/pyodide/run.py";
import pyodideVerifySrc from "!!raw-loader!../interpreter/scripts/pyodide/verify.py";
import Cookies from "universal-cookie";
import utilsSrc from '!!raw-loader!../interpreter/scripts/utils.py'
import verificationSrc from '!!raw-loader!../interpreter/scripts/pyodide/verification.py'
import finderSrc from '!!raw-loader!../interpreter/scripts/pyodide/finder.py'
import tkinterSrc from '!!raw-loader!../interpreter/scripts/tkinter/__init__.py'
import tkinterSrcConstants from '!!raw-loader!../interpreter/scripts/tkinter/constants.py'
import tkinterSrcShapes from '!!raw-loader!../interpreter/scripts/tkinter/_shapes.py'
import turtleSrc from '!!raw-loader!../interpreter/scripts/turtle.py'
import dataSandboxSrc from '!!raw-loader!../interpreter/scripts/data_sandbox.py'
import requestsSrc from '!!raw-loader!../interpreter/scripts/requests/__init__.py'


import letpySrc from '!!raw-loader!../interpreter/scripts/pyodide/letpy/__init__.py'
import letpyHelpersSrc from '!!raw-loader!../interpreter/scripts/pyodide/letpy/helpers.py'
import letpyTranslationSrc from '!!raw-loader!../interpreter/scripts/pyodide/letpy/translation.py'
import letpyMessagesTxt from '!!raw-loader!../interpreter/scripts/pyodide/letpy/messages.ru.mo.txt'

import {TkinterInit, TkinterHandle} from "@/plugins/interpreter/TkinterClassWrappers";
import { TurtleBridgeHandle, TurtleBridgeInit } from '@/plugins/interpreter/TurtleBridge'

export default {
  inputElement: null,

  public: {
    // stdout: '',
    isRunning: false,
    debug: null,
    userInputInProgress: false,
    userInputValue: '',
    userInputInFocus: false,
    version: 1,
    loading: false,
    fileOpen: null,
    fileClose: null,
    friendlyTraceback: null
  },
  worker: null,
  brythonModule: null,
  brythonStdlibModule: null,
  terminal: null,
  dataListener: null,

  createWorker(sourceCode) {
    window.URL = window.URL || window.webkitURL
    let blob;
    try {
      blob = new Blob([sourceCode], { type: 'application/javascript' })
    } catch (e) {
      window.BlobBuilder = window.BlobBuilder || window.WebKitBlobBuilder || window.MozBlobBuilder
      // eslint-disable-next-line no-undef
      blob = new BlobBuilder()
      blob.append(sourceCode)
      blob = blob.getBlob()
    }
    return new Worker(URL.createObjectURL(blob))
  },

  sendToWorker(data, dataArray, eventArray) {
    let encoder = new TextEncoder();
    let result = encoder.encode(JSON.stringify(data));
    for (let i = 0; i < result.length; i++) {
      dataArray[i] = result[i];
    }
    // eslint-disable-next-line no-undef
    Atomics.store(eventArray, 0, 123);
    // eslint-disable-next-line no-undef
    Atomics.store(eventArray, 1, result.length);
    // eslint-disable-next-line no-undef
    Atomics.notify(eventArray, 0, 1);
  },


  _runPyodide(file, debug) {
    this.public.debug = debug ? {locals: [], line: 0} : null;
    const cookies = new Cookies();
    const csrfToken = cookies.get(process.env.VUE_APP_CSRF_COOKIE_NAME)
    const modules_list = [
        { name: '__utils__.py', source: utilsSrc },
        { name: '__finder__.py', source: finderSrc },
        { name: 'requests'},
        { name: 'requests/__init__.py', source: requestsSrc },
        { name: 'tkinter'},
        { name: 'tkinter/__init__.py', source: tkinterSrc },
        { name: 'tkinter/constants.py', source: tkinterSrcConstants },
        { name: 'tkinter/_shapes.py', source: tkinterSrcShapes },
        { name: 'turtle.py', source: turtleSrc },
        { name: 'data_sandbox.py', source: dataSandboxSrc }
      ]

    //this.public.stdout = '';
    this.public.isRunning = true;
    this.public.friendlyTraceback = null;
    this.terminal.reset();
    TkinterInit(this, this.dataArray, this.eventArray);
    TurtleBridgeInit(this, this.dataArray, this.eventArray);

    this.pyodideWorker.postMessage({
        src: file.content,
        fileName: file.title,
        csrfToken: csrfToken,
        openFilePath: `${process.env.VUE_APP_MAIN_HOST}/api/v2/user-files/${Base64.encode(file.path)}/open/`,
        pythonPath: [`${process.env.VUE_APP_MAIN_HOST}/api/v2/user-files/${Base64.encode(file.path)}/import/`],
        modules: modules_list,
        debug: debug,
        screenWidth: window.innerWidth,
        screenHeight: window.innerHeight,
      });

    this.pyodideWorker.onmessage = (event)=>{
      if (event.data.type === 'stdout.write') {
        this.terminal.write(event.data.value);
        //this.public.stdout += event.data.value;
      } else if (event.data.type === 'script.finished') {
        this.public.isRunning = false;
      } else if (event.data.type === 'stderr.write') {
        this.terminal.write(event.data.value);
        //this.public.stdout += event.data.value;
      } else if (event.data.type === 'file.open') {
        this.public.fileOpen = event.data.value;
      } else if (event.data.type === 'file.close') {
        this.public.fileClose = event.data.value;
      } else if (event.data.type === 'stdin.read') {
          this.terminal.focus();
          this.terminal.write('\x1b[?25h');  // Show cursor

          let line = '';
          let cursorPosition = 0;  // Track cursor position within the line

          // Register the onData callback
          this.dataListener = this.terminal.onData((data) => {
            // Handle Enter key to submit the input
            if (data === '\r') {
              this.sendToWorker({ value: line }, this.dataArray, this.eventArray);
              this.terminal.write('\n\x1b[?25l');  // Move to next line for output
              this.dataListener.dispose();
            }
            // Handle backspace for single-line editing
            else if (data === '\u007f') {  // Backspace character
              if (cursorPosition > 0) {
                // Remove the character before the cursor in `line`
                line = line.slice(0, cursorPosition - 1) + line.slice(cursorPosition);
                cursorPosition--; // Move cursor position back

                // Update the display: move back, clear character, reprint the remainder of the line
                this.terminal.write('\b');  // Move back one space
                this.terminal.write(line.slice(cursorPosition) + ' '); // Rewrite rest of the line
                this.terminal.write('\b'.repeat(line.length - cursorPosition + 1));  // Reset cursor to original position
              }
            }
            // Handle Arrow Left to move the cursor within bounds
            else if (data === '\u001b[D') {  // Arrow Left
              if (cursorPosition > 0) {
                this.terminal.write('\b');  // Move cursor back
                cursorPosition--;
              }
            }
            // Handle Arrow Right to move the cursor within bounds
            else if (data === '\u001b[C') {  // Arrow Right
              if (cursorPosition < line.length) {
                this.terminal.write(line[cursorPosition]);  // Move cursor right
                cursorPosition++;
              }
            }
            // Ignore any arrow up/down and disallow new lines
            else if (data === '\u001b[A' || data === '\u001b[B' || data === '\n') {
              // Do nothing (prevents multi-line movement)
            }
            // Handle printable characters for single line input
            else {
              // Insert the character at the cursor position in `line`
              line = line.slice(0, cursorPosition) + data + line.slice(cursorPosition);
              cursorPosition++;  // Move cursor forward

              // Write the updated line from the cursor position
              this.terminal.write(line.slice(cursorPosition - 1));  // Write character and remainder of line
              this.terminal.write('\b'.repeat(line.length - cursorPosition));  // Move cursor back to original position
            }
          });

        // this.public.userInputInProgress = true;
        //
        // this.inputElement.onfocus = () => {
        //   this.public.userInputInFocus = true;
        // }
        // this.inputElement.onblur = () => {
        //   this.public.userInputInFocus = false;
        // }
        //
        // this.inputElement.focus();
        // this.inputElement.value = '';
        //
        // this.inputElement.oninput = (e) => {
        //   this.public.userInputValue = e.target.value;
        //   let stdout = document.querySelector('.stdout > pre').getBoundingClientRect()
        //   this.inputElement.style.top = `${stdout.y + stdout.height}px`
        // }
        //
        // this.inputElement.onkeypress = (e) => {
        //   if ((e.keyCode === 13) && (this.public.userInputInProgress)){
        //     this.public.userInputInProgress = false;
        //     this.public.stdout += (this.public.userInputValue + '\n');
        //     this.public.userInputInProgress = false;
        //     this.sendToWorker({value: this.public.userInputValue}, this.dataArray, this.eventArray);
        //     this.public.userInputValue = '';
        //   }
        // }
      } else if (event.data.type === 'debug.info') {
        this.public.debug = event.data.value;
      } else if (event.data.type === 'tkinter') {
        TkinterHandle(event.data);
      } else if (event.data.type === 'turtle') {
        TurtleBridgeHandle(event.data);
      } else if (event.data.type === 'friendly.traceback') {
        this.public.friendlyTraceback = event.data.value;
      } else {
        console.log(event, event.data);
      }
    }
  },

  _runFile(file, debug) {
    this._runPyodide(file, debug);
  },

  stopWorkerExecution() {
    if (this.worker) {
      this.worker.terminate();
    } else {
      if (this.interruptExecution) {
        this.interruptExecution();
      }
    }
    if (this.dataListener !== null) {
      this.dataListener.dispose();
      this.terminal.write('\x1b[?25l');  // Hide cursor
    }
    // this.public.isRunning = false;
    // if (this.public.userInputInProgress) {
    //   this.public.stdout += this.public.userInputValue;
    // }
    this.public.userInputInProgress = false
    this.public.userInputValue = ''
  },

  initPyodide() {
    try {
      this.inputElement = document.createElement('input');
      this.inputElement.setAttribute('style', "opacity: 0; font-size: 1px; height: 1px; width: 1px; position: absolute; top:0;");
      this.inputElement.setAttribute('autocapitalize', 'off');
      this.inputElement.setAttribute('spellcheck', 'false');

      document.body.appendChild(this.inputElement);

      // eslint-disable-next-line no-undef
      const eventBuffer = new SharedArrayBuffer(8);
      // eslint-disable-next-line no-undef
      const dataBuffer = new SharedArrayBuffer(1024 * 1024);
      // eslint-disable-next-line no-undef
      const debugBuffer = new SharedArrayBuffer(8)

      this.debugArray = new Int32Array(debugBuffer);
      this.eventArray = new Int32Array(eventBuffer);
      this.dataArray = new Int8Array(dataBuffer);

      this.pyodideWorker = this.createWorker(`importScripts("${process.env.VUE_APP_PYODIDE_URL}");\n` + PyodideWorkerSrc);

      // eslint-disable-next-line no-undef
      let interruptBuffer = new Uint8Array(new SharedArrayBuffer(1));

    this.interruptExecution = () => {
      // 2 stands for SIGINT.
      interruptBuffer[0] = 2;

    }

    this.pyodideWorker.postMessage({
      type: 'init',
      eventBuffer: eventBuffer,
      dataBuffer: dataBuffer,
      debugBuffer: debugBuffer,
      pyodideRunSrc: pyodideRunSrc,
      pyodideVerifySrc: pyodideVerifySrc,
      interruptBuffer: interruptBuffer,
      locale: process.env.VUE_APP_I18N_LOCALE,
      troubleShootingLink: process.env.VUE_APP_TROUBLESHOOTING_LINK
    });

    this.pyodideWorker.onmessage = (event) => {
      if (event.data.type === 'pyidide.loaded') {
        this.public.loading = false;
      } else if (event.data.type === 'stderr.write') {
        // this.public.stdout += event.data.value;
        this.terminal.write(event.data.value);
      }
    }

    } catch (e) {
      //this.public.stdout += e.message + `\n\n${process.env.VUE_APP_TROUBLESHOOTING_LINK}`;
      const interval = setInterval(() => {
        if (this.terminal) {
          this.terminal.write(e.message + `\n\n${process.env.VUE_APP_TROUBLESHOOTING_LINK}`);
          clearInterval(interval);
        }
      }, 100);
      throw e;
    }
  },

  verifyFile_(fileObject, lessonObject) {
    return new Promise((resolve, reject) => {
      const modules_list = [
        { name: '__finder__.py', source: finderSrc },
        { name: '__utils__.py', source: utilsSrc },
        { name: 'requests'},
        { name: 'requests/__init__.py', source: requestsSrc },
        { name: 'tkinter'},
        { name: 'tkinter/__init__.py', source: tkinterSrc },
        { name: 'tkinter/constants.py', source: tkinterSrcConstants },
        { name: 'tkinter/_shapes.py', source: tkinterSrcShapes },
        { name: 'turtle.py', source: turtleSrc },
        { name: 'data_sandbox.py', source: dataSandboxSrc },
        { name: 'verification.py', source: verificationSrc },
        { name: 'letpy'},
        { name: 'letpy/__init__.py', source: letpySrc },
        { name: 'letpy/helpers.py', source: letpyHelpersSrc },
        { name: 'letpy/translation.py', source: letpyTranslationSrc },
        { name: 'lesson.py', source: lessonObject.vm_ },
      ]

      if (lessonObject.dependencies) {
        lessonObject.dependencies.forEach((v) => {
          modules_list.push({ name: v.name + '.py', source: v.source })
        })
      }

      const cookies = new Cookies();
      const csrfToken = cookies.get(process.env.VUE_APP_CSRF_COOKIE_NAME)

      //this.public.stdout = '';
      this.terminal.reset();
      this.public.isRunning = true;
      this.public.friendlyTraceback = null;

      // right now we should add locale domains only if we have ru-locale.
      const localeDomains = [];
      if (process.env.VUE_APP_I18N_LOCALE === 'ru') {
        localeDomains.push({
          name: 'letpy.mo',
          content: letpyMessagesTxt
        })

        if (lessonObject.messages) {
          localeDomains.push({
            name: 'messages.mo',
            content: lessonObject.messages
          })
        }
        // for cycle for all dependenciees
        lessonObject.dependencies.forEach((v) => {
          if (v.messages) {
            localeDomains.push({
              name: `${v.name}.mo`,
              content: v.messages
            })
          }
        })
      }
      this.pyodideWorker.postMessage({
          verify: true,
          src: fileObject.content,
          lessonConfig: lessonObject.config,
          csrfToken: csrfToken,
          openFilePath: `${process.env.VUE_APP_MAIN_HOST}/api/v2/user-files/${Base64.encode(fileObject.path)}/open/`,
          pythonPath: [`${process.env.VUE_APP_MAIN_HOST}/api/v2/user-files/${Base64.encode(fileObject.path)}/import/`],
          modules: modules_list,
          locale_domains: localeDomains
        });

      TkinterInit(this, );
      let message = null;

      let timeout = setTimeout(()=> {
        if (this.public.isRunning) {
          // worker.terminate();
          this.stopExecution();
          this.public.isRunning = false;
          if (message) {
            if (message.result) {
              resolve(message)
            } else {
              reject(message)
            }
          }
        }
      }, 10000)

      this.pyodideWorker.onmessage = (event) => {
          if (event.data.type === 'script.started') {
            this.terminal.reset();
            //this.public.stdout = '';
            this.public.isRunning = true;
            // TODO: set is verification
          } else if (event.data.type === 'script.finished') {
            clearTimeout(timeout);
            // this.stopExecution();
            // worker.terminate();
            this.public.isRunning = false;

            if (message) {
              if (message.result) {
                resolve(message)
              } else {
                reject(message)
              }
            }
          } else if (event.data.type === 'stdin.read') {

          } else if (event.data.type === 'tkinter') {
            TkinterHandle(event.data);
          } else if (event.data.type === 'verification') {
            message = null;
            clearTimeout(timeout);
            if (event.data.value.result) {
              resolve(event.data.value)
            } else {
              reject(event.data.value)
            }
          } else if (event.data.type === 'verification_set_message') {
            message = event.data.value;
            if (message.result) {
              clearTimeout(timeout);
              this.stopExecution();
              // worker.terminate();
              this.public.isRunning = false;
              resolve(message)
            }
          } else {
            console.log(event);
          }
        }
    });
  },


  verifyFile(fileObject, lessonObject) {
    if (this.public.version > 1) {
      return this.verifyFile_(fileObject, lessonObject);
    }
  },

  runFile(file, debug) {
    if (this.public.version > 1) {
      this._runFile(file, debug);
      // return;
    }
  },

  nextStep() {
    if (this.public.version > 1) {
      // eslint-disable-next-line no-undef
      Atomics.store(this.debugArray, 0, 123);
      // eslint-disable-next-line no-undef
      Atomics.notify(this.debugArray, 0, 1);
    }
  },

  stopExecution() {
    if (this.public.isRunning) {
      this.stopWorkerExecution();
    }
  }
}



