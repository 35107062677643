<template>
  <div>
  <div>Courses index page</div>
    <router-link :to="{'name': 'questionList_', 'params': {'courseId': 'basics', 'lessonId': 'instant-practice', 'sectionId': 'intro'}}">first lesson</router-link>
  </div>
</template>

<script>
export default {
  name: 'courses-view'
}
</script>
