import UserService from '@/services/UserService.js'


export const namespaced = true

export const state = {
  info: {},
  paymentInfo: {},
  gifts: []
}

export const mutations = {
  UPDATE_INFO(state, userData) {
    state.info = {...state.info, ...userData}
  },

  UPDATE_PAYMENT_INFO(state, paymentInfo) {
    state.paymentInfo = {...state.paymentInfo, ...paymentInfo}
  },

  UPDATE_GIFTS(state, gifts) {
    state.gifts = gifts
  },
}

export const actions = {
  fetchUserInfo({ commit}) {
    return UserService.getUserInfo().then((response) => {
      commit('UPDATE_INFO', response.data);
      return response
    })
  },

  fetchGifts({ commit }) {
    return UserService.getUserGifts().then((response) => {
      commit('UPDATE_GIFTS', response.data)
    });
  },

  fetchPaymentInfo({ commit}) {
    return UserService.getUserPaymentInfo().then((response) => {
      commit('UPDATE_PAYMENT_INFO', response.data);
      return response
    })
  },

  uploadAvatar({ commit }, dataBase64) {
    return UserService.uploadAvatar(dataBase64).then((response) => {
      commit('UPDATE_INFO', response.data)
    })
  },

  removeAvatar({ commit }) {
    return UserService.removeAvatar().then(() => {
      commit('UPDATE_INFO', {'photo': null, 'photoThumbnail': null})
    })
  },

  updateProfile({ commit  }, data) {
    return UserService.patchUserInfo(data).then((response) => {
      commit('UPDATE_INFO', response.data)
    })
  },

  changePassword(_, data) {
    return UserService.changePassword(data)
  }
}

export const getters = {

}