import Vue from 'vue'
import VueTimeago from 'vue-timeago'

export default Vue.use(VueTimeago, {
  name: 'Timeago',
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  locales: {
    'ru': require('date-fns/locale/ru'),
    'en': require('date-fns/locale/en'),
  },
})