export const namespaced = true

export const state = {
    lessons: {
      path: null,
      scroll: 0
    },

    bookmarks: {
      path: '/bookmarks',
      scroll: 0
    },

    workbook: {
      path: '/workbook',
      scroll: 0
    },

    social: {
      path: '/social/my',
      scroll: 0
    },

    questions: {
      path: null,
      scroll: 0
    },

    containerScrollPosition: 0,
    desiredScrollPosition: null
}

export const mutations = {
  SET_PATH(state, data) {
    state[data.tab].path = data.path;
  },

  SET_SCROLL(state, data) {
    state[data.tab].scroll = data.scroll;
  },

  SET_CONTAINER_SCROLL_POSITION(state, data) {
    state.containerScrollPosition = data;
  },

  SET_DESIRED_SCROLL_POSITION(state, data) {
    state.desiredScrollPosition = data;
  }
}

export const actions = {
  setPath({commit}, data) {
    commit('SET_PATH', data);
  },

  setContainerScrollPosition({ commit }, position) {
    commit('SET_CONTAINER_SCROLL_POSITION', position)
  },

  setDesiredScrollPosition({ commit }, position) {
    commit('SET_DESIRED_SCROLL_POSITION', position)
  },

  setDesiredScrollPositionFor({ commit, state }, tab) {
    commit('SET_DESIRED_SCROLL_POSITION', state[tab].scroll)
  },

  setScroll({ commit }, data) {
    commit('SET_SCROLL', data);
  }
}