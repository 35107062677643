<script>
export default {
  name: 'test-markdown-component',
  props: {
    string_property: {
      default: null
    },
    list_property: {
      default: null
    }
  },

  computed: {
    error() {
      if (!this.string_property) {
        return 'stringProperty parameter is required.';
      }
      if (!this.list_property) {
        return 'listProperty parameter is required.';
      }
      if (!Array.isArray(this.list_property)) {
        return 'listProperty parameter must be an array.';
      }
      return null;
    }
  }
}
</script>

<template>
  <p v-if="error" class="error my-2">{{ error }}</p>
  <div v-else-if="list_property" class='rounded-2xl p-4 bg-brand-dark text-white my-2'>
    <ul>
      <li v-for="item in list_property" :key='item.name' v-html="item.name"></li>
    </ul>
    <div class='rounded-2xl p-4 bg-brand-dark text-white' v-html='string_property'>
    </div>
  </div>
</template>

<style scoped>

</style>