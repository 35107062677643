import SocialService from "@/services/SocialService";


export const namespaced = true

export const state = {

}

export const mutations = {

}

export const actions = {

}

export const getters = {

}