export default {
  props: {
    'showMenu': {
      required: true,
      type: Boolean,
      default: false
    }
  },

  computed: {
    showMenuInner: {
      get: function () {
        return this.showMenu;
      },

      set: function (v) {
        this.$emit('update:showMenu', v);
      }
    }
  }
}