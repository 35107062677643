<template>
  <v-dialog
    transition="dialog-transition"
    max-width="400"
    v-model="showMenuInner"
  >
    <v-card>
      <v-card-title class="text-h7">
        {{ $t('Create') }}
      </v-card-title>
      <v-card-text>
        <v-text-field
          autofocus
          v-model="name"
          :prepend-icon="$options.$fileTypes[$props.fileType]"
          append-outer-icon="mdi-send"
          @click:append-outer="submit"
          @keypress.enter="submit"
          :error-messages="(version >= '2.1') ? errorMessages['path'] : errorMessages['name']"
          :loading="loading"
        ></v-text-field>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ShowMixin from "@/mixins/ShowMixin";
import {mapState} from "vuex";
import path from "path-browserify";

export default {
  name: 'create-node',
  mixins: [ShowMixin],
  props: {
    fileType: String,
    parentID: null
  },

  $fileTypes: {
    'file': 'mdi-file',
    'folder': 'mdi-folder',
  },

  data: () => ({
    name: '',
    errorMessages: {},
    loading: false
  }),

   computed: {
     ...mapState({
           filesCount: state => state.user.info.appVersion >= '2.1' ? state.userFiles.files.length : state.vfs.files.length,
          version: state => state.user.info.appVersion,
         },
     ),
    fileTypeInner: {
      get: function () {
        return this.fileType;
      },

      set: function (v) {
        this.$emit('update:fileType', v);
      }
    },

   parentInner: {
      get: function () {
        return this.parentID;
      },

      set: function (v) {
        this.$emit('update:parentID', v);
      }
    }
  },

  methods: {
    submit() {
      if ((this.name.length > 0) && (this.name.indexOf('/') === -1) && (this.name.indexOf('\\') === -1)) {
        if (this.version >= '2.1') {
          this.loading = true;
          this.$store.dispatch('userFiles/createPath', {
            'path': path.join(this.parentID || '/', this.name),
            'isLeaf': this.fileType === 'file',
          }).then((response) => {
            this.$store.dispatch('userFiles/fetchUserFiles').then(()=>{
              if (this.fileType === 'file') {
                this.$store.dispatch('userFiles/openFile', response.data);
                this.$store.commit('userFiles/SET_ACTIVE_TAB', response.data)
              }
              this.setDefaultValues();
              this.loading = false;
            })
          }).catch((error) => {
            this.errorMessages = error.response.data
            this.loading = false;
          })
        } else {
          this.$store.dispatch('vfs/createNode', {
            'name': this.name,
            'parent': this.parentID,
            'type': this.fileType,
            'isOpened': true
          }).then((response) => {
            if (response.data.isLeaf) {
              this.$store.dispatch('vfs/openInTab', {...response.data, setActive: true});
            }
            this.setDefaultValues()
          }).catch((error) => {
            this.errorMessages = error.response.data
          })
        }
      }
    },

    setDefaultValues() {
      this.showMenuInner = false
      this.fileTypeInner = null
      this.parentInner = null
      this.errorMessages = []
    }
  },

  watch: {
    showMenuInner(isOpened) {
      if (!isOpened) {
        this.setDefaultValues();
      } else {
        if (this.fileTypeInner === 'file') {
          this.name = this.filesCount === 0 ? 'hello.py' : '';
        } else {
          this.name = '';
        }
      }
    }
  }
}
</script>