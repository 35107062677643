<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon>
        <v-avatar
            color="primary"
            size="30"
            v-bind="attrs"
            v-on="on"
        >
          <img v-if="user.info.photo !== null" :src="user.info.photo">
          <span v-else class="white--text headline"
                style="font-size: 1rem!important;">{{ user.info.photoPlaceholder.toUpperCase() }}</span>
        </v-avatar>
      </v-btn>
    </template>
    <v-card min-width="250">
    <v-list>
      <v-list-item to="/social/my">
        <v-list-item-icon>
          <v-icon>mdi-account-circle-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>{{ $t('My page') }}</v-list-item-content>
      </v-list-item>
      <v-list-item to="/social/settings">
        <v-list-item-icon>
          <v-icon>mdi-cogs</v-icon>
        </v-list-item-icon>
        <v-list-item-content>{{ $t('Settings') }}</v-list-item-content>
      </v-list-item>
      <v-list-item to="/social/subscription" v-if="locale === 'ru'">
        <v-list-item-icon>
          <v-icon>mdi-circle-multiple</v-icon>
        </v-list-item-icon>
        <v-list-item-content>{{ $t('Subscription') }}</v-list-item-content>
      </v-list-item>
      <v-list-item to="/social/gift-purchase" v-if="locale === 'ru'">
        <v-list-item-icon>
          <v-icon>mdi-gift-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>{{ $t('Buy gift card') }}</v-list-item-content>
      </v-list-item>
      <v-list-item to="/social/purchases" v-if="locale === 'ru'">
        <v-list-item-icon>
          <v-icon>mdi-cart-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>{{ $t('My purchases') }}</v-list-item-content>
      </v-list-item>
      <v-list-item to="/social/courses" v-if="locale === 'ru'">
        <v-list-item-icon>
          <v-icon>mdi-school-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>{{ $t('My courses') }}</v-list-item-content>
      </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list>
        <v-list-item @click="openHandbook()" v-if="locale === 'ru'">
          <v-list-item-icon>
            <v-icon>mdi-information-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>{{ $t('Handbook') }}</v-list-item-content>
        </v-list-item>

        <v-list-item  @click="signOut">
          <v-list-item-icon>
            <v-icon>mdi-exit-to-app</v-icon>
          </v-list-item-icon>
          <v-list-item-content>{{ $t('Sign out') }}</v-list-item-content>
        </v-list-item>
    </v-list>
    </v-card>
  </v-menu>
</template>
<script>
import { mapState } from 'vuex';
export default {
  computed: {
    ...mapState(['user']),
    locale() {
      return process.env.VUE_APP_I18N_LOCALE
    },
    // paymentUrl() {
    //   return `${process.env.VUE_APP_MAIN_HOST}/user/payment/`
    // }
  },

  methods: {
    openHandbook() {
      let win = window.open(`${process.env.VUE_APP_MAIN_HOST}/handbook/`, '_blank');
      win.focus();
    },

    signOut() {
      this.$confirm(this.$t("Are you sure you want to sign out?"), {
        buttonTrueText: this.$t('Sign out'),
        buttonFalseText: this.$t('Cancel')
      }).then((confirmed) => {
        if (confirmed) {
          window.location.href = process.env.VUE_APP_SIGN_OUT_PATH
        }
      });
    }
  }
}
</script>