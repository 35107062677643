import Vue from 'vue'
import store from '../store/index'

let currentEl = null


Vue.directive('resizable', {
  bind: function (el, binding) {
    const defaults = {
      borderTarget: '.v-navigation-drawer__border'
    }
    Object.assign(defaults, binding.value || {})

    const UISettings = store.getters["settings/getUISettings"]
    const border = el.querySelector(defaults.borderTarget);

    border.addEventListener("mouseenter", () => {
      if (defaults.gutter !== 'bottom') {
          Object.assign(border.style, {
          cursor: 'ew-resize',
          width: '2px',
          backgroundColor: '#aea9a9'
        })
      } else {
        Object.assign(border.style, {
          cursor: 'ns-resize',
          height: '2px',
          backgroundColor: '#aea9a9'
        })
      }
    });

    border.addEventListener('mouseleave', () => {
      border.style = {}
    });

    const resize = (e) => {
      if (binding.value.gutter === 'right') {
        let right = document.body.scrollWidth - e.clientX;
        if (right < 300) {
          right = 300
        }
        store.dispatch('settings/updateGutterPosition', {name: 'right', value: right})
      } else if (binding.value.gutter === 'left') {
        store.dispatch('settings/updateGutterPosition', {name: 'left', value: e.clientX > 200 ? e.clientX : 200})
      } else if (binding.value.gutter === 'bottom') {
        let bottom = document.body.offsetHeight - e.clientY;
        if (bottom < 100) {
          bottom = 100;
        }
        store.dispatch('settings/updateGutterPosition', {name: 'bottom', value: bottom})
      }
    }

    border.addEventListener("mousedown", (e) => {
      currentEl = el
      document.body.style.cursor = ((defaults.gutter !== 'bottom' ? 'ew-resize':'ns-resize'));
      el.style.transition = "initial";
      el.style.userSelect = "none";
      UISettings['editor']['transition'] = 'initial'
      document.addEventListener("mousemove", resize, false);
    }, false)

    document.addEventListener("mouseup", () => {
      if (el === currentEl) {
        el.style.transition = "";
        el.style.userSelect = "text";
        document.body.style.cursor = "";
        UISettings['editor']['transition'] = 'all .2s'
        document.removeEventListener("mousemove", resize, false);
        currentEl = null
      }
    }, false)
  }
})