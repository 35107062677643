<template>

  <AceEditor
      v-if="tab.isActive"
      v-model="content"
      @init="editorInit"
      :lang=editorMode
      :theme=theme
      :width="width"
      :height="height"
      :left="left"
      :right="right"
      :bottom="bottom"
      :focus="focus"
      :options="{
          fontSize: settings.preferences.fontSize.value,
          highlightActiveLine: true,
          showLineNumbers: true,
          tabSize: 4,
          showPrintMargin: false,
          showGutter: true,
          showInvisibles: settings.preferences.showInvisible.value
        }"
      @saveContent="saveContent"
      @onFocus="onFocus"
  />
</template>

<script>
import { mapState } from 'vuex';
import AceEditor from '@/plugins/ace-editor/ace-editor';
import SizeMixin from "@/mixins/SizeMixin";
import ThemeMixin from "@/mixins/ThemeMixin";
import _ from 'lodash'

export default {
  name: 'code-editor-2',
  components: {
    AceEditor,
  },
  mixins: [SizeMixin, ThemeMixin],
  props: {
    tab: Object,
    focus: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    saveLoading: false,
    contentLoading: false,
  }),

  methods: {
    editorInit() {

    },

    onFocus() {
      this.$store.dispatch('settings/updateUI', {'helperTitlesDrawerActive': null});
      if (this.$vuetify.breakpoint.mdAndDown) {
        this.$store.dispatch('settings/updateUI', {'filesDrawer': false})
      }

      /* if (this.$vuetify.breakpoint.smAndDown) {
        this.$store.dispatch('settings/updateUI', {'helperDrawer': false});
      } */
    },

    saveContent(content) {
      this.$store.dispatch('userFiles/saveFile', {path: this.tab.path, content: content});
      // this.$store.dispatch('vfs/updateTab', {...this.tab, ...{loading: true}})
      // this.$store.dispatch('files/updateFileContent', {'id': this.tab.item.id, 'content': content}).finally(() => {
      //   this.$store.dispatch('vfs/updateTab', {...this.tab, ...{loading: false}})
      // });
    }
  },

  mounted() {

  },

  computed: {
    ...mapState(['settings', 'files', 'userFiles']),

    editorMode() {
      if (this.tab.title.endsWith('.py')) {
        return 'python';
      } else if (this.tab.title.endsWith('.json')) {
        return 'json';
      } else {
        return 'text';
      }
    },

    content: {
      get() {
        return this.tab.content;
      },

      set(value) {
        this.$store.commit('userFiles/UPDATE_TAB', {path: this.tab.path, content: value});
        // let payload = {}
        // payload[this.tab.item.id] = value;
        // this.$store.commit('files/MODIFY_FILE_CONTENT', payload);
      }
    },

    height() {
      if (this.userFiles.tabs.length > 0) {
        return `calc(100vh - ${Number(this.settings.UI.gutters.top) + 40}px - ${this.bottom}px)`
      }
      return `calc(100vh - ${Number(this.settings.UI.gutters.top)}px - ${this.bottom}px)`
    },
  },

  watch: {
    content: _.debounce(function handler(content) {
      if (this.settings.preferences.autoSave.value && content !== this.tab.initialContent) {
        this.saveContent(content)
      }
    }, 1500),
  }
}
</script>
<style lang="scss">
  .v-application .ace_folding-enabled > .ace_gutter-cell {
    padding-right: 25px;
    text-align: left;
  }

  .ace_gutter-cell:after {
    content: "●";
    color: rgba(0,0,0,0);
    padding-left: 10px;
  }

  .ace_gutter-cell.ace_breakpoint:after {
    color: #F4433A;
    content: "●";
    padding-left: 10px;
  }
</style>
