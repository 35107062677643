import UserFilesService from "@/services/UserFilesService.js";
import path from "path-browserify";

export const namespaced = true

const toSlVueTreeStructure = (nodes) => {
  return nodes.map((node) => {
    node['title'] = node.path.split('/').pop()
    node['data'] = {
      path: node.path
    }
    if (node['children']) {
      node['children'] = toSlVueTreeStructure(node['children'])
    }
    return node
  })
}

const getOpenedNodes = (nodes) => {
  let result = [];
  nodes.forEach((node) => {
    if (node.isOpened) {
      result.push(node);
    }
    if (node.children) {
      result.push(...getOpenedNodes(node.children));
    }
  });
  return result;
}



export const state = {
  files: [],
  tabs: [],
  backup: []
}

const getTabByPath = (path) => {
  return state.tabs.find((tab) => tab.path === path);
}

export const mutations = {
  SET_FILES(state, files) {
    state.files = files;
  },

  ADD_TAB(state, data) {
    state.tabs.push(data);
  },

  CLOSE_TAB(state, data) {
    for (let i = 0; i < state.tabs.length; i++) {
      if (state.tabs[i].path.startsWith(data.path)) {
        const removed = state.tabs.splice(i, 1);
        if (removed.length === 1 && removed[0].isActive && state.tabs.length > 0) {
          const tab = state.tabs[i] || state.tabs[i - 1];
          tab.isActive = true;
        }
        i--;
      }
    }
  },

  UPDATE_TAB(state, data) {
    let tab = getTabByPath(data.path)
    if (tab) {
      if (data.content !== undefined) {
        tab.content = data.content;
      }
      if (data.initialContent !== undefined) {
        tab.initialContent = data.initialContent;
      }
      if (data.loading !== undefined) {
        tab.loading = data.loading;
      }
    }
  },

  SET_ACTIVE_TAB(state, data) {
    state.tabs.map((tab) => tab.isActive = false);
    let activeTab = getTabByPath(data.path)
    activeTab.isActive = true;
  },

  UPDATE_PATH(state, { oldPath, newPath }) {
    const update = (nodes) => {
      for (let i = 0; i < nodes.length; i++) {
        if (nodes[i].path.startsWith(oldPath)) {
          nodes[i].path = newPath + nodes[i].path.substring(oldPath.length);
          nodes[i].title = path.basename(nodes[i].path)
          if (nodes[i].data && nodes[i].data.path) {
            nodes[i].data.path = newPath + nodes[i].data.path.substring(oldPath.length);
          }
        }
        if (nodes[i].children) {
          update(nodes[i].children);
        }
      }
    }
    update(state.files);
    update(state.tabs);
  },

  BACKUP_TREE(state) {
    state.backup.push(JSON.parse(JSON.stringify(state.files)));
    if (state.backup.length > 2) {
      state.backup.shift();
    }
  },

  RESTORE_TREE(state) {
    if (state.backup.length > 0) {
      state.files = state.backup[0];
    }
  }
}

export const actions = {
  fetchUserFiles({ commit }) {
    return UserFilesService.tree().then((response) => {
      commit('SET_FILES', toSlVueTreeStructure(response.data));
      commit('BACKUP_TREE');
      return response.data
    })
  },

  createPath({ commit }, data) {
    return UserFilesService.post(data).then((response) => {
      return response
    });
  },

  openFile({ commit }, data) {
    let tab = getTabByPath(data.path)
    if (!tab) {
      commit('ADD_TAB', { loading: true, isActive: false, content: null, initialContent: null, ...data })
      return UserFilesService.get(data.path).then((response) => {
        commit('UPDATE_TAB', { path: data.path, content: response.data.content, initialContent: response.data.content, loading: false});
        UserFilesService.patch(data.path, { isOpened: true})
      });
    }
  },

  reloadFile({ commit }, data) {
    let tab = getTabByPath(data.path)
    if (tab) {
      return UserFilesService.get(data.path).then((response) => {
        commit('UPDATE_TAB', { path: data.path, content: response.data.content, initialContent: response.data.content, loading: false});
      });
    }
  },

  setActiveTab({ commit }, data) {
    commit('SET_ACTIVE_TAB', { path: data.path} );
  },

  saveFile({ commit }, data) {
    let tab = getTabByPath(data.path)
    if (tab) {
      commit('UPDATE_TAB', { path: data.path, loading: true });
      return UserFilesService.patch(data.path, { content: data.content }).then((response) => {
        commit('UPDATE_TAB', { path: data.path, initialContent: data.content, loading: false});
      });
    }
  },

  closeTab({ commit }, tab) {
    commit('CLOSE_TAB', tab);
    UserFilesService.patch(tab.path, { isOpened: false, is_active: false })
  }
}

export const getters = {
  getUserFiles: (state) => {
    return state.files
  },

  current: (state) => {
    return state.tabs.find((tab) => tab.isActive)
  },

  getOpened: (state) => {
    return getOpenedNodes(state.files);
  }
}
