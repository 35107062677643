import Vue from 'vue'
const moment = require('moment')
if (process.env.VUE_APP_I18N_LOCALE !== 'en') {
    console.log('not IO')
    require(`moment/locale/${process.env.VUE_APP_I18N_LOCALE}`)
}

export default Vue.use(require('vue-moment'), {
    moment
})
