import VFSService from "@/services/VFSService";

export const namespaced = true

const toSlVueTreeStructure = (node, index) => {
  delete node['data']
  node['index'] = index || node.index
  node['title'] = node['name']
  node['isLeaf'] = node['type'] === 'file'
  node['isExpanded'] = node['isOpened']
  node['data'] = {...node}
  return node
}

export const state = {
  files: [],
  modified: [],
  tabs: []
}

export const mutations = {
  SET_FILES(state, nodesArray) {
    state.files = nodesArray.map((node, index) => {
      return toSlVueTreeStructure(node, index)
    })
  },

  APPEND_FILE(state, file) {
    let index = state.files.length
    state.files.push(toSlVueTreeStructure(file, index))
  },

  DELETE_FILE(state, id) {
    for (let node of state.files) {
      if (node.id === id) {
        state.files.splice(state.files.indexOf(node) , 1)
      }
    }
  },

  UPDATE_NODE(state, file) {
    let node = getters.getNodeById(state)(file.id)
    Object.entries(file).forEach(([key, value]) => {
      node[key] = value
    })
    node = toSlVueTreeStructure(node, node.index)
  },

  SELECT_NODE(state, nodeObject) {
    for (let file of state.files) {
      if (nodeObject.id === file.id) {
        file.isSelected = true
      } else {
        file.isSelected = false
      }
    }
  },

  ADD_TAB(state, data) {
    state.tabs.push(data);
  },

  SET_ACTIVE_TAB(state, vfsItemId) {
    state.tabs.map((tab) => tab.isActive = false);
    let activeTab = state.tabs.find(tab => tab.item.id === vfsItemId);
    activeTab.isActive = true;
  },

  UPDATE_TAB(state, data) {
    let tab = state.tabs.find(tab => tab.item.id === data.item.id);
    Object.entries(data).forEach(([key, value]) => {
      tab[key] = value;
    })
  },

  CLOSE_TAB(state, data) {
    let index = state.tabs.findIndex(tab => tab.item.id === data.item.id);
    if (index !== -1) {
      let removed = state.tabs.splice(index, 1);
      if (removed.length === 1 && removed[0].isActive && state.tabs.length > 0) {
        if (index > 0) {
          index--;
        }
        state.tabs[index].isActive = true;
      }
    }
  }
}

export const actions = {
  fetchVFSTree({ commit }) {
    return VFSService.get().then((response) => {
      commit('SET_FILES', response.data);
    });
  },

  createNode({ commit }, nodeObject) {
    return VFSService.post(nodeObject).then((response) => {
      commit('APPEND_FILE', response.data);
      return response;
    })
  },

  removeNode({ commit }, nodeID){
    return VFSService.delete(nodeID).then(() => {
      commit('DELETE_FILE', nodeID);
      commit('CLOSE_TAB', {item: {id: nodeID}});
    })
  },

  updateNode({ commit }, nodeObject) {
    commit('UPDATE_NODE', nodeObject)
    return VFSService.patch(nodeObject, nodeObject.id)
  },

  selectNode({ commit }, nodeObject) {
    commit('SELECT_NODE', nodeObject)
  },

  openInTab({ commit, dispatch }, data) {
    let index = state.tabs.findIndex(tab => tab.item.id === data.id);

    if (index !== -1) {
      commit('SET_ACTIVE_TAB', data.id);
      return;
    }

    let tab = {
      'loading': true,
      'item': data,
      'isActive': false
    }
    commit('ADD_TAB', tab);
    return dispatch('files/fetchFileContent', tab.item.id, { root: true }).then(() => {
      tab.loading = false;
      if (data.setActive) {
        commit('SET_ACTIVE_TAB', tab.item.id);
      }
    });
  },

  setActiveTab({ commit }, vfsItemId) {
    commit('SET_ACTIVE_TAB', vfsItemId);
  },

  closeTab({ commit }, tab) {
    commit('CLOSE_TAB', tab);
  },

  updateTab({ commit }, tab) {
    commit('UPDATE_TAB', tab);
  }
}

export const getters = {
  flatPathTree(state, getters) {
    let array = []
    for (let node of state.files) {
      if (node.isLeaf)
        array.push(getters.getPathByNodeId(node.id))
    }
    return array
  },

  getNodeById: (state) => (id) => {
    let node = state.files.find(file => file.id === id)
    return ((node === undefined) ? undefined:node)
  },

  currentFile(state, getters, rootState) {
    let currentTab = state.tabs.find(tab => tab.isActive);
    if (!currentTab) {
      return undefined;
    }

    let fileId = currentTab.item.id;

    let file = getters.getNodeById(fileId)
    if (file !== undefined) {
      file.content = rootState.files.modifiedContent[fileId]
      return file
    } else {
      return {}
    }
  },

  getPathByNodeId: (state, getters) => (id) => {
    let node = getters.getNodeById(id)
    let path = `/${node.name}`
    if (node.parent !== null) {
      path = getters.getPathByNodeId(node.parent).path + path
    }
    return {'id': node.id, 'path': path}
  }
}