<template>
  <v-btn v-if="!(!canRate && rating === 0)" outlined rounded :color="isRatedByUser ? 'green' : 'grey'" :disabled="!canRate" :loading="loading" @click="rate();">
    <v-icon small v-if="isRatedByUser">mdi-thumb-up</v-icon>
    <v-icon small v-else>mdi-thumb-up-outline</v-icon>
    <span class="ml-1" v-if="rating > 0" v-text="rating"></span>
  </v-btn>
</template>
<script>
export default {
  name: "rate-content",
  props: ['itemType', 'itemId', 'canRate', 'isRatedByUser', 'rating'],

  methods: {
    rate() {
      this.loading = true;
      let payload = {
        itemType: this.itemType,
        itemId: this.itemId
      }
      if (!this.isRatedByUser) {
        this.$store.dispatch('commonTools/rateContent', payload).then((response) => {
          this.$emit('update:isRatedByUser', true);
          this.$emit('update:rating', response.data.rating);
          this.loading = false;
        })
      } else {
        this.$store.dispatch('commonTools/unRateContent', payload).then((response) => {
          this.$emit('update:rating', response.data.rating);
          this.$emit('update:isRatedByUser', false)
          this.loading = false;
        });
      }

    }
  },
  data() {
    return {
      loading: false
    }
  }
}
</script>