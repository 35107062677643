<template>
<div :style="styles" class="navigation-sidebar">
      <div class="py-2">
        <v-btn elevation="0" plain @click="toggleHelperDrawer()">
          <v-icon v-if="helperDrawer">mdi-chevron-right</v-icon>
          <v-icon v-else>mdi-chevron-left</v-icon>
        </v-btn>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" elevation="0" :color="($router.currentRoute.path.startsWith('/lessons') || $router.currentRoute.path.startsWith('/course')) ? 'primary' : ''" :plain="helperTitlesDrawerActive !== 'lessons'" @click="changeActive('lessons')"><v-icon>mdi-book-open-page-variant-outline</v-icon></v-btn>
          </template>
          <span>{{ $t('Lessons') }}</span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs"  elevation="0" :color="$router.currentRoute.path.startsWith('/bookmarks') ? 'primary': ''" :plain="helperTitlesDrawerActive !== 'bookmarks'" @click="changeActive('bookmarks')"><v-icon >mdi-bookmark-outline</v-icon></v-btn>
          </template>
          <span>{{ $t('Bookmarks') }}</span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs"  elevation="0" :color="$router.currentRoute.path.startsWith('/workbook') ? 'primary': ''" :plain="helperTitlesDrawerActive !== 'workbook'" @click="changeActive('workbook')"><v-icon >mdi-book-open-outline</v-icon></v-btn>

          </template>
          <span>{{ $t('Workbook') }}</span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs"  elevation="0" :color="$router.currentRoute.path.startsWith('/social') ? 'primary': ''" :plain="helperTitlesDrawerActive !== 'social'" @click="changeActive('social')"><v-icon >mdi-account-group</v-icon></v-btn>
          </template>
          <span>{{ $t('Social') }}</span>
        </v-tooltip>
      </div>
    </div>
</template>

<script>
import SizeMixin from "@/mixins/SizeMixin";
import {mapState} from "vuex";
import {BASE_COURSE_NAME} from '@/legacy'

export default {
  name: "SidebarNavigation",
  mixins: [SizeMixin],
  computed: {
    ...mapState(['lessons', 'navigation', 'settings', 'user']),
    styles() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return {
          right: `0`,
          borderLeft: 'none'
        }
      }
      if (this.settings.UI.helperDrawer) {
        return {
          right: `${this.right - 50}px`,
          borderLeft: 'none'
        }
      }
      return {
        right: `${this.right}px`
      }
    },

    helperDrawer: {
      get() {
        return this.settings.UI.helperDrawer
      },

      set(boolean) {
        this.$store.dispatch('settings/updateUI', {helperDrawer: boolean})
      }
    },

    helperTitlesDrawerActive: {
      get() {
        return this.settings.UI.helperTitlesDrawerActive
      },

      set(tab) {
        this.$store.dispatch('settings/updateUI', {helperTitlesDrawerActive: tab})
      }
    }
  },

  methods: {
    toggleHelperDrawer() {
      this.helperDrawer = !this.helperDrawer
      if (!this.helperDrawer) {
        this.helperTitlesDrawerActive = null;
      }
    },

    changeActive(tab) {
      if (this.helperTitlesDrawerActive !== tab) {
        //this.active = tab;
        this.helperTitlesDrawerActive = tab;
        this.helperDrawer = true;

        let path = null;
        switch (tab) {
          case 'lessons':
            path = this.navigation.lessons.path
            if (path === null) {
              path = `/course/${BASE_COURSE_NAME}/${this.user.info.currentSection}/${this.user.info.currentLesson}`
            }
            break
          case 'bookmarks':
            path = this.navigation.bookmarks.path
            break
          case 'workbook':
            path = this.navigation.workbook.path
            break
          case 'social':
            path = this.navigation.social.path
            break
          default:
            break
        }
        if (path !== null && (path !== this.$router.currentRoute.path)) {
          this.$router.push(path)
        }

      } else {
        this.helperTitlesDrawerActive = null;
      }
    }
  },

  watch: {
    '$route'(to, from) {
      if ((to.path.startsWith('/lessons') || to.path.startsWith('/course')) && this.helperTitlesDrawerActive !== null) {
        //this.$store.dispatch('navigation/setPath', {tab: 'lessons', path: to.path });
        this.helperTitlesDrawerActive = 'lessons'
      }
      else if (to.path.startsWith('/bookmarks') && this.helperTitlesDrawerActive !== null) {
        //this.$store.dispatch('navigation/setPath', {tab: 'bookmarks', path: to.path });
        this.helperTitlesDrawerActive = 'bookmarks'
      }

      else if (to.path.startsWith('/workbook') && this.helperTitlesDrawerActive !== null) {
        //this.$store.dispatch('navigation/setPath', {tab: 'workbook', path: to.path });
        this.helperTitlesDrawerActive = 'workbook'
      }

      else if (to.path.startsWith('/social') && this.helperTitlesDrawerActive !== null) {
        //this.$store.dispatch('navigation/setPath', {tab: 'social', path: to.path });
        this.helperTitlesDrawerActive = 'social'
      }
      else {
        this.helperTitlesDrawerActive = null
      }

    },
  }
}
</script>

<style scoped>
.navigation-sidebar {
  position: fixed;
  top: 48px;
  width: 48px;
  height: 100vh;
  z-index: 6;
  transition-duration: 0.15s;
  transition-property: all
}

.navigation-sidebar .v-btn {
  min-width: 48px;
  height: 48px;
  border-radius: 0;
  padding: 0 12px;
}

</style>