export default {
  computed: {
    left() {
      // On lower displays there is no need to move content
      if (this.settings.UI.filesDrawer && !this.$vuetify.breakpoint.mdAndDown)
        return this.settings.UI.gutters.left
      return '0'
    },

    right() {
      // On lower displays there is no need to move content
      if (this.settings.UI.helperDrawer && !this.$vuetify.breakpoint.smAndDown) {
        if ((this.$vuetify.breakpoint.width - 50) < this.settings.UI.gutters.right) {
          return this.$vuetify.breakpoint.width - 50;
        }
        return this.settings.UI.gutters.right
      }
      if (this.$vuetify.breakpoint.smAndDown) {
        return 48;
      }
      return 0;
    },

    bottom() {
      if (this.settings.UI.debuggerDrawer) {
        if (this.settings.UI.gutters.bottom > (this.$vuetify.breakpoint.height - 150)) {
          return this.$vuetify.breakpoint.height - 150;
        }
        return this.settings.UI.gutters.bottom
      }
      return '0'
    },

    top() {
      return this.settings.UI.gutters.top
    },

    width() {
      return `calc(100% - ${this.left}px - ${this.right}px)`
    },

    height() {
      return `calc(100vh - ${this.top}px - ${this.bottom}px)`
    }
  },
}