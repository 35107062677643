<template>
  <v-card flat>
    <v-card-title>
      {{ $t('settings') }}
    </v-card-title>
    <v-list>
      <v-list-item
          v-for="[key, item] of Object.entries(vSwitchPreferences)"
          :key="key"
      >
        <v-list-item-content>
          <v-list-item-title v-text="$t(item.label)"></v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
          <component
              :is="item.component"
              inset
              v-model="item.value"
          ></component>
        </v-list-item-action>
      </v-list-item>

      <v-list-item
          v-for="[key, item] of Object.entries(vSliderPreferences)"
          :key="key"
      >
        <v-list-item-content>
          <component
            class="slider-preference-item"
            :label="$t(item.label)"
            :is="item.component"
            min="14"
            max="24"
            v-model="item.value"
          ></component>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-list-item>
      <v-list-item-content>
        <v-list-item-title><span v-if="user.info.hasAutoUsername">{{ $t('Not set') }}</span><span v-else>{{ user.info.username }}</span></v-list-item-title>
        <v-list-item-subtitle>{{ $t('E-mail address') }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn text @click="showUsernameDialog();">{{ $t('Change') }}</v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-dialog v-model="usernameDialog" max-width="500">
      <v-card>
        <v-card-text class="pt-5">
          <v-text-field
                :label="$t('E-mail address')"
                v-model="username"
                :error-messages="usernameErrors"
              ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="usernameDialog = false;" text>{{ $t('Cancel') }}</v-btn>
          <v-btn :loading="usernameLoading" @click="saveUsername();" text color="primary">{{ $t('Change') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>



    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>{{ $t('Password') }}</v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn text @click="showChangePasswordDialog();">{{ $t('Change') }}</v-btn>
      </v-list-item-action>
    </v-list-item>

    <v-dialog v-model="changePasswordDialog" max-width="500">
      <v-card>
        <v-card-text class="pt-5">
          <v-text-field
            v-model="passwordModel.oldPassword"
            :append-icon="show.oldPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show.oldPassword ? 'text' : 'password'"
            :label="$t('Old password')"
            @click:append="show.oldPassword = !show.oldPassword"
            :error-messages="passwordModelErrors.oldPassword"
          ></v-text-field>

          <v-text-field
            v-model="passwordModel.password"
            :append-icon="show.password ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show.password ? 'text' : 'password'"
            :label="$t('Password')"
            @click:append="show.password = !show.password"
            :error-messages="passwordModelErrors.password"
          ></v-text-field>

          <v-text-field
            v-model="passwordModel.passwordRepeat"
            :append-icon="show.passwordRepeat ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show.passwordRepeat ? 'text' : 'password'"
            :label="$t('Repeat password')"
            @click:append="show.passwordRepeat = !show.passwordRepeat"
            :error-messages="passwordModelErrors.passwordRepeat"
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="changePasswordDialog = false;" text>{{ $t('Cancel') }}</v-btn>
          <v-btn :loading="changePasswordLoading" @click="changePassword();" text color="primary">{{ $t('Change') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-list-item v-if="$i18n.locale === 'ru'">
      <v-list-item-content>
        <v-list-item-title>{{ $t('Gender') }}</v-list-item-title>
        <v-list-item-subtitle>{{ $t('GenderInfo') }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action-text>
        <v-radio-group v-model="user.info.gender" row>
          <v-radio :label="$t('I\'m a man')" value="male"></v-radio>
          <v-radio :label="$t('I\'m a woman')" value="female"></v-radio>
        </v-radio-group>
      </v-list-item-action-text>
    </v-list-item>

    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>{{ $t('Country') }}</v-list-item-title>
      </v-list-item-content>
      <v-list-item-action-text>
        <v-autocomplete
            outlined
            :items="countries"
            item-value="code"
            item-text="name"
            :label='$t("Country")'
            v-model="user.info.registeredCountry"
        ></v-autocomplete>
      </v-list-item-action-text>
    </v-list-item>


  </v-card>

</template>

<script>
import { VSwitch, VSlider } from 'vuetify/lib';
import {mapState} from "vuex";
import _ from "lodash";
import Country from "@/utils/Country";

export default {
  name: 'settings-content-view',
  components: {VSlider, VSwitch},
  data: () => ({
    row: null,
    countries: Country.list,
    usernameDialog: false,
    usernameLoading: false,
    username: '',
    usernameErrors: null,
    passwordModel: {
      'oldPassword': '',
      'password': '',
      'passwordRepeat': ''
    },
    show: {
      'oldPassword': false,
      'password': false,
      'passwordRepeat': false
    },
    passwordModelErrors: {},
    changePasswordDialog: false,
    changePasswordLoading: false


  }),

  computed: {
    ...mapState(['social', 'settings', 'user']),
    vSwitchPreferences() {
      let preferences = {}
      for (const [key, item] of Object.entries(this.settings.preferences)) {
        if (item.component === 'v-switch') preferences[key] = item
      }
      return preferences
    },

    vSliderPreferences() {
      let preferences = {}
      for (const [key, item] of Object.entries(this.settings.preferences)) {
        if (item.component === 'v-slider') preferences[key] = item
      }
      return preferences
    }
  },

  created() {

  },

  methods: {
    showUsernameDialog() {
      if (this.user.info.hasAutoUsername) {
        this.username = '';
      } else {
        this.username = this.user.info.username;
      }
      this.usernameErrors = null;
      this.usernameDialog = true;
    },

    saveUsername() {
      this.usernameLoading = true;
      this.$store.dispatch('user/updateProfile', {'username': this.username}).then(() => {
        this.usernameDialog = false;
      }, (error)=>{
        this.usernameErrors = error.response.data.username;
      }).finally(()=>{
        this.usernameLoading = false;
      })
    },

    showChangePasswordDialog() {
      this.show = {
        'oldPassword': false,
        'password': false,
        'passwordRepeat': false
      }
      this.passwordModelErrors = {};
      this.passwordModel = {
        'oldPassword': '',
        'password': '',
        'passwordRepeat': ''
      }
      this.changePasswordDialog = true;
    },

    changePassword() {
      this.changePasswordLoading = true;
      this.$store.dispatch('user/changePassword', this.passwordModel).then(() => {
        this.changePasswordDialog = false;
      }, (error) => {
        this.passwordModelErrors = error.response.data;
      }).finally(() => {
        this.changePasswordLoading = false;
      })
    }
  },

  watch: {
    '$route.path'() {

    },

    'settings.preferences': {
      handler: _.debounce(function (preferences) {
        this.$store.dispatch('settings/updatePreferences', preferences);
      }, 2000),
      deep: true
    },

    'user.info.gender': {
      handler: _.debounce(function(value) {
        this.$store.dispatch('user/updateProfile', {'gender': value}).then(() => {

        });
      }, 1000)
    },

    'user.info.registeredCountry': function(value) {
      this.$store.dispatch('user/updateProfile', {'registeredCountry': value}).then(() => {

      });
    },
  }
}
</script>