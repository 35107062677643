<template>
  <v-card>
    <v-list>
      <v-list-item>
        <v-list-item-avatar>
          <v-img v-if="user.photo" :src="user.photo"/>
          <span v-else class="text-center subtitle-1 text-h1">{{ user.photoPlaceholder.toUpperCase() }}</span>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{ user.fullName }}</v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
          <v-btn
              v-if="me.id !== user.id "
              :class="user.alreadyLiked ? 'red--text' : ''"
              icon
              @click="likeUser"
              :loading="loading || likeLoading"
          >
            <v-icon>mdi-heart</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <v-list>
      <v-list-item>
        <v-list-item-avatar>
          <v-icon color="green">mdi-thumb-up</v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          {{ $t('Rating') }}
        </v-list-item-content>

        <v-list-item-action>
          <v-skeleton-loader
            v-if="loading"
            type="avatar"
          ></v-skeleton-loader>

          <span class="text-h5" v-else>{{ user.rating }}</span>
        </v-list-item-action>
      </v-list-item>

      <v-list-item>
        <v-list-item-avatar>
          <v-icon color="red">mdi-heart</v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          {{ $t('Karma') }}
        </v-list-item-content>

        <v-list-item-action>
          <v-skeleton-loader
            v-if="loading"
            type="avatar"
          ></v-skeleton-loader>

          <span class="text-h5" v-else>{{ user.karma }}</span>
        </v-list-item-action>
      </v-list-item>
    </v-list>

    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn
        text
        @click="showMenuInner = false"
      >
        {{ $t('Close') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ShowMixin from "@/mixins/ShowMixin";

export default {
  name: 'profile-popup',
  mixins: [ShowMixin],
  props: {
    user: {
      required: true,
      type: Object,
      default: () => ({})
    }
  },

  data: () => ({
    loading: true,
    likeLoading: false
  }),

  computed: {
    me() {
      return this.$store.state.user.info
    },

    userInner: {
      get() {
        return this.user
      },

      set(value) {
        this.$emit('update:user', value)
      }
    }
  },

  created() {
    this.fetchUser()
  },

  methods: {
    fetchUser() {
      this.$store.dispatch('usersPublic/fetchUserById', this.user.id).then((response) => {
        this.userInner = response.data
        this.loading = false
      })
    },

    likeUser() {
      this.likeLoading = true
      this.$store.dispatch('usersPublic/likeUserById', this.user.id).then((response) => {
        this.userInner.alreadyLiked = !this.userInner.alreadyLiked
        this.userInner.karma = response.data.karma
        this.likeLoading = false
      })
    }
  },

  watch: {
    showMenuInner(isOpened) {
      if (isOpened)
        this.fetchUser()
    },
  }
}
</script>