<template>
  <section>
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="primary"
      height="1"
    ></v-progress-linear>
    <div v-else>
      <v-card v-if="update">
        <v-card-actions class="pa-4 pb-0">
          <v-btn small icon @click="update = false">
            <v-icon>mdi-chevron-left</v-icon></v-btn>
          <div class="subtitle-1 ml-2">{{ title }}</div>
        </v-card-actions>
        <v-card-text>
          <v-text-field :label="$t('Label')" v-model="title"></v-text-field>
          <public-editor :editorHeight="500"  :text.sync="text"></public-editor>
        </v-card-text>

        <v-card-actions class="pr-4">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :disabled="title === ''"
            :loading="updateLoading"
            @click="submit"
          >
            {{ $t('Save') }}
          </v-btn>
        </v-card-actions>

      </v-card>
      <v-card v-else flat class="pb-2" :class="settings.preferences.darkTheme.value ? 'monokai-sublime-theme' : 'github-theme'">
        <v-card-actions class="pa-4 pb-0">
          <div class="title">{{ title }}</div>
          <v-spacer></v-spacer>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="grey"
                v-bind="attrs"
                v-on="on"
                icon
                :loading="deleteLoading"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list width="200px">
              <v-list-item @click="update = true">
                <v-list-item-title>{{ $t('Edit') }}</v-list-item-title>
                <v-list-item-action><v-icon color="orange">mdi-pencil</v-icon></v-list-item-action>
              </v-list-item>

              <v-list-item @click="confirmDelete = true">
                <v-list-item-title>{{ $t('Delete') }}</v-list-item-title>
                <v-list-item-action><v-icon color="red lighten-2">mdi-delete</v-icon></v-list-item-action>
              </v-list-item>
            </v-list>
          </v-menu>

        </v-card-actions>
        <v-card-text class="body-1" v-html="html" v-hljs>
        </v-card-text>
      </v-card>
    </div>


    <confirm-delete-dialog
      :show-menu.sync="confirmDelete"
      @deletionConfirmed="deleteCurrentRecord"
      text="Delete this page?"
    ></confirm-delete-dialog>

  </section>

</template>

<script>
import {mapState} from "vuex";
import ConfirmDeleteDialog from "@/components/dialogs/ConfirmDeleteDialog";
import PublicEditor from "@/components/editor/PublicEditor";

export default {
  name: 'workbook-content-add-view',
  components: {PublicEditor, ConfirmDeleteDialog},

  data: () => ({
    loading: false,
    updateLoading: false,
    deleteLoading: false,
    confirmDelete: false,
    title: '',
    text: '',
    html: '',
    update: false
  }),

  computed: {
    ...mapState(['workbook', 'settings'])
  },

  mounted() {
    this.fetchCurrentRecord()
  },

  methods: {
    submit() {
      this.updateLoading = true
      this.$store.dispatch(
          'workbook/updateRecord',
          {'title': this.title, 'text': this.text, 'id': this.$route.params.id},
      ).then(() => {
        this.$store.dispatch('notifications/show', {text: this.$t('Workbook record updated')})
      }).finally(() => {
        this.updateLoading = false;
        this.update = false;
      })
    },

    deleteCurrentRecord() {
      this.deleteLoading = true
      this.$store.dispatch('workbook/deleteRecord', this.$route.params.id).finally(() => {
        this.$store.dispatch('workbook/setNullRecord')
        this.$router.push('/workbook')
        this.$store.dispatch('notifications/show', {text: this.$t('Workbook record deleted')})
        this.deleteLoading = false
      })
    },

    fetchCurrentRecord() {
      this.update = false;
      this.loading = true
      return this.$store.dispatch('workbook/getRecord', this.$route.params.id).then(() => {
        this.title = this.workbook.current.title
        this.text = this.workbook.current.text
        this.html = this.workbook.current.html
      }).finally(() => {
        this.loading = false
      })
    }
  },

  watch: {
    '$route'(to, from) {
      if (from.path.startsWith('/workbook') && to.path.startsWith('/workbook')) {
        this.fetchCurrentRecord();
      }
    }
  }
}
</script>

<style scoped>
.v-md-editor {
  box-shadow: none;
  border: solid 1px grey;
  border-radius: 0;
}
</style>

