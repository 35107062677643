<template>
  <v-progress-linear
    v-if="loading"
    indeterminate
    color="primary"
    height="1"
  ></v-progress-linear>

  <v-card v-else flat class="pb-10">
    <v-card-title>
      {{ $t('My courses') }}
    </v-card-title>
    <v-list v-if="courses.length > 0">
      <v-list-item
          two-line
          :key="`item-course-${i}`"
          v-for="(item, i) in courses"
          :to="`/course/${item.course.slug}/${item.currentSectionSlug}/${item.currentLessonSlug}`"
      >
        <v-list-item-content>
          <v-list-item-title>
            {{ item.course.title }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ item.course.description }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-card-text class="body-1" v-else>
      <p class="font-weight-regular subheading">
        {{ $t("You haven't enrolled in any courses yet.") }}
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
import CourseService from '@/services/CourseService';

export default {
  name: 'my-courses',

  data: () => ({
    loading: true,
    courses: []
  }),

  created() {
    this.fetchCourses()
  },

  methods: {
    fetchCourses() {
      CourseService.get('my')
        .then(response => {
          this.courses = response.data
          console.log(response.data)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        })
    }
  },

}
</script>
