<template>
  <section :style="style" class="d-flex justify-center align-center code-editor-placeholder pa-16">
  </section>
</template>

<script>
import { mapState } from 'vuex';
import SizeMixin from "@/mixins/SizeMixin";

export default {
  name: 'code-editor',
  mixins: [SizeMixin],

  data: () => ({

  }),

  computed: {
    style() {
      return `position: absolute; height: ${this.height}px; width: ${this.width}px; left: ${this.left}px; right: ${this.right}px; bottom: ${this.bottom}px; top: ${this.top}px;`
    },
    ...mapState(['settings', 'files', 'vfs']),


    height() {
      if (this.vfs.tabs.length > 0) {
        return `calc(100vh - ${Number(this.settings.UI.gutters.top) + 40}px - ${this.bottom}px)`
      }
      return `calc(100vh - ${Number(this.settings.UI.gutters.top)}px - ${this.bottom}px)`
    },
  },
}
</script>
<style lang="scss">
</style>
