"use strict";

import Vue from 'vue';
import Interpreter from "./Interpreter";


Interpreter.install = function (Vue) {
  Vue.prototype.$_interpreter = Vue.observable(Interpreter.public);

  Vue.mixin({
    methods: {
      $focusOnInputElement() {
        if (this.$interpreter.inputInProgress || this.$_interpreter.userInputInProgress) {
          this.$inputElement.focus();
        }
      },
    },

    computed: {
      $inputElement: {
        get() {
          return Interpreter.inputElement;
        }
      },

      $interpreter: {
        get() {
          let result = {
            currentDebugStep: 0, // window.__BRYTHON__.executionBindings.current_step_number,
            isVerification: false, //window.__letpy_reactive.isVerification && window.__BRYTHON__.executionBindings.is_running,
            inputInProgress: false, ///window.__letpy_reactive.inputInProgress,
            reloadUserFilesFlag: false //window.__BRYTHON__.executionBindings.reload_user_files_flag
          }

          if (this.$_interpreter.version > 1) {
            result.isRunning = this.$_interpreter.isRunning
            result.debug = this.$_interpreter.debug
            result.mode = this.$_interpreter.debug ? 'debug' : 'normal'
            result.fileOpen = this.$_interpreter.fileOpen
            result.fileClose = this.$_interpreter.fileClose
            result.friendlyTraceback = this.$_interpreter.friendlyTraceback
            result.loading = this.$_interpreter.loading
          }
          return result
        },
      },
    },

    data() {
      return {
      }
    }
  })
}

Vue.use(Interpreter);
export default Interpreter;
