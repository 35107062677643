<template>
  <v-card flat>
    <div v-if="(gifts && gifts.length > 0) || (paymentInfo.payments && paymentInfo.payments.length > 0)">
      <div v-if="paymentInfo.payments && paymentInfo.payments.length > 0">
        <v-card-title>{{ $t('Subscriptions') }}</v-card-title>
        <v-list-item two-line v-for="(payment, idx) in paymentInfo.payments" :key="`p${idx}`">
          <v-list-item-action>
            <v-icon color="green" v-if="payment.isCurrent">mdi-arrow-right</v-icon>
            <v-icon color="grey" v-else>mdi-close</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title v-html="payment.title"></v-list-item-title>
            <v-list-item-subtitle>
              {{ payment.startedAt | moment("D MMMM Y")}} &mdash;
               <span v-if="payment.paidTo">{{ payment.paidTo | moment("D MMMM Y")}}</span>
              <span v-else>∞</span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </div>

      <div v-if="gifts && gifts.length > 0">
        <v-card-title>{{ $t('Gift cards') }}</v-card-title>
        <v-list-item v-for="(gift, idx) in gifts" :key="`g${idx}`">
          <v-list-item-action>
            <v-icon v-if="gift.usedBy" color="grey">mdi-gift-off-outline</v-icon>
            <v-icon v-else color="green">mdi-gift-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-text="gift.code"></v-list-item-title>
            <v-list-item-subtitle class="text--primary" v-text="gift.title"></v-list-item-subtitle>
            <v-list-item-subtitle v-if="!!gift.usedAt">{{ $t('Used at', {date: $moment(gift.usedAt).format("D MMMM Y")}) }}</v-list-item-subtitle>
            <v-list-item-subtitle v-else v-text="$t('Not used')"></v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon :disabled="!!gift.usedBy" @click="downloadGiftPdf(gift.id)">
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </div>
    </div>

    <v-card-text v-else class="text-center pa-12">
      {{ $t('There are no purchases yet') }}
    </v-card-text>


    <v-card-title>
      {{ $t('Gift code') }}
    </v-card-title>
    <v-card-text>
      <p class="font-weight-regular subheading">{{ $t('GiftCodeInfo') }}</p>
      <v-text-field :label="$t('Gift code')"
            :placeholder="$t('Gift code')"
            outlined v-model="giftCode" :error-messages="giftCodeError"></v-text-field>
      <div class="d-flex justify-space-around">
        <v-btn :loading="useGiftLoading" depressed color="green" @click="useGift()">{{ $t('Use gift') }}</v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapState} from "vuex";
import UserService from "@/services/UserService";

export default {
  name: 'purchases-content-view',

  data: () => ({
    loading: false,
    useGiftLoading: false,
    giftCode: '',
    giftCodeError: null
  }),

  computed: {
    ...mapState('user', ['paymentInfo', 'gifts'])
  },

  created() {

  },

  methods: {
    downloadGiftPdf(giftId) {
      const url = `${process.env.VUE_APP_MAIN_HOST}/gift-certificate${giftId}.pdf`
      let win = window.open(url, '_blank');
      win.focus();
    },

    useGift() {
      this.useGiftLoading = true;
      this.giftCodeError = null;
      UserService.useGift(this.giftCode).then(() => {
        this.$store.dispatch('user/fetchPaymentInfo').then(() => {
          //
        }).finally(() => {
          this.$store.dispatch('notifications/show', {
            text: this.$t('useGiftSuccessful'),
            color: 'green',
            timeout: 5000
          });
          this.useGiftLoading = false;
          this.giftCode = '';
        })

      }, (error) => {
        if (error.response && error.response.status === 400) {
          this.giftCodeError = error.response.data.code;
        }
        this.useGiftLoading = false;
      })
    }
  },

  watch: {
    '$route.path'() {

    }
  }
}
</script>