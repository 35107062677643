import LessonService from '@/services/LessonService.js'
import CourseService from '@/services/CourseService.js'
import { BASE_COURSE_NAME } from '@/legacy.js'

import Decoder from '@/plugins/decoder'

// todo: refactor from topicId to sectionId
export const namespaced = true

export const state = {
  topics: [],
  currentLesson: null,
  verificationInProgress: false,
  lessonsLoading: false,
  courseId: null,
  assistantMessages: []
}


export const mutations = {
  SET_VERIFICATION_IN_PROGRESS(state, verificationInProgress) {
    state.verificationInProgress = verificationInProgress;
  },

  SET_COURSE_ID(state, courseId) {
    state.courseId = courseId;
  },

  SET_LESSONS(state, lessonsArray) {
    state.topics = lessonsArray
  },

  SET_LESSONS_LOADING(state, lessonsLoading) {
    state.lessonsLoading = lessonsLoading
  },

  SET_CURRENT_LESSON(state, lessonObject) {
    state.currentLesson = lessonObject
  },

  MARK_LESSON_AS_PASSED(state, lessonObject) {
    let lesson = getters.getLessonBySectionAndId(state)(lessonObject.sectionId, lessonObject.lessonId);
    if (lesson) {
      lesson.isPassed = true;
    }
    if ((state.currentLesson.slug === lessonObject.lessonId) &&
        (state.currentLesson.sectionSlug === lessonObject.sectionId))  {
      state.currentLesson.isPassed = true;
    }
  },

  SET_ASSISTANT_MESSAGES(state, messages) {
    state.assistantMessages = messages;
  }
}

export const actions = {
  fetchAssistantMessages({ commit }, data) {
    return CourseService.get(`assistant-messages/${data.lessonId}`).then((response) => {
      commit('SET_ASSISTANT_MESSAGES', response.data);
      return response;
    });
  },

  updateAssistantMessage({ commit }, newMessageObject) {
    let message = state.assistantMessages.find(message => message.id === newMessageObject.id)
    if (message) {
      Object.assign(message, newMessageObject)
    }
  },

  startVerification({ commit }) {
    commit('SET_VERIFICATION_IN_PROGRESS', true)
  },

  finishVerification({ commit }) {
    commit('SET_VERIFICATION_IN_PROGRESS', false)
  },

  fetchLessons({ commit }, courseId) {
    commit('SET_LESSONS_LOADING', true)
    return CourseService.get(`${courseId}/lessons`).then((response) => {
      commit('SET_LESSONS', response.data);
      commit('SET_COURSE_ID', courseId);
      commit('SET_LESSONS_LOADING', false);
    })
  },

  clearCurrentLesson({ commit }) {
    commit('SET_CURRENT_LESSON', null)
  },

  fetchLessonContent({ commit }, data) {
    return CourseService.get(`${data.courseId}/lessons/${data.topicId}/${data.lessonId}`).then((response) => {
      response.data['vm'] = Decoder.unpack(response.data['vm'])
      response.data['vm_'] = Decoder.unpack(response.data['vm_'])
      response.data['hashproc'] = Decoder.unpack(response.data['hashproc'])
      response.data['cover'] = Decoder.unpack(response.data['cover'])
      response.data['vmAsync'] = Decoder.unpack(response.data['vmAsync'])

      if (response.data['dependencies']) {
        response.data['dependencies'] = response.data['dependencies'].map((dep) => {
          return {
            name: Decoder.unpack(dep.hash),
            source: Decoder.unpack(dep.module),
            messages: dep.messages
          }
        })
      }

      commit("SET_CURRENT_LESSON", response.data)
      if (!response.data.isCurrent) {
        CourseService.put(null, `${data.courseId}/current/${data.topicId}/${data.lessonId}`)
      }
      return response
    })
  },

  createLessonVerificationHistory({ commit }, data) {
    return LessonService.post(data, `verification-history`).then((response) => {
      return response;
    })
  },

  updateLessonVerificationHistory({ commit }, data) {
    return LessonService.patch(data, `verification-history/${data.id}`).then((response) => {
      return response;
    })
  },

  passLesson({ commit, state }, data) {
    if (data.courseId === BASE_COURSE_NAME) {
      delete data.courseId;
    }
    if (data.source) {
      // Is it okay to modify state here?
      state.currentLesson.lastVerifiedCode = data.source;
    }

    const url = data.courseId ? `pass/${data.courseId}/${data.sectionId}/${data.lessonId}` : `pass/${data.sectionId}/${data.lessonId}`;
    return LessonService.put(null, url).then((response) => {
      commit('MARK_LESSON_AS_PASSED', data);
      return response;
    })
  },

  isBookmarkedToggle(_ , data) {
    data.isBookmarked = !data.isBookmarked
  },
}

export const getters = {
  getAssistantMessages: (state) => {
    return state.assistantMessages;
  },
  getCourseId: (state) => {
    return state.courseId
  },

  getLessonsLoading: (state) => {
    return state.lessonsLoading;
  },

  getLessonBySectionAndId: (state) => (sectionSlug, slug) => {
    for (let section of state.topics) {
      if (section.slug === sectionSlug) {
        for (let lesson of section.lessons) {
          if (lesson.slug === slug) {
            return lesson;
          }
        }
      }
    }
    return null;
  },

  getDefinition: (state) => (id) => {
    for (let dfn of state.currentLesson.definitions) {
      if (dfn.id === id) {
        return dfn
      }
    }
  },

  getLastPassedLesson(state) {
    let lastPassedLesson = null;

    for (let section of state.topics) {
      let sectionLessons = [...section.lessons]
      for (let lesson of sectionLessons) {
        if (!lesson.isPassed && lastPassedLesson !== null) {
          return lastPassedLesson
        }
        lastPassedLesson = lesson
        lastPassedLesson['sectionId'] = section.id
      }
    }
    return lastPassedLesson;
  }
}