<template>
  <section>
  <v-card flat>
    <v-card-actions v-if="!settings.UI.helperTitlesDrawer" class="pa-4 pb-0" style="position: absolute">
      <v-btn small icon @click="$router.back()"><v-icon>mdi-chevron-left</v-icon></v-btn>
    </v-card-actions>

    <v-card-text class="d-flex justify-space-around align-center">
      <div style="width: 30%">
        <div class="d-flex justify-space-between align-center">
          <div>
            <span class="title green--text">{{ user.info.xp }}</span><span class="caption">&nbsp;xp</span>
          </div>
        </div>
        <v-progress-linear height="2" :value="user.info.passedPercents" color="green"></v-progress-linear>
        <div class="caption grey--text">
          {{ user.info.passedPercentsText }}
        </div>
      </div>
      <v-avatar color="primary" size="128" style="cursor: pointer" @mouseenter="showAvatarOverlay=true" @mouseleave="showAvatarOverlay=false">

        <v-overlay :value="showAvatarOverlay" :absolute="true">
          <div>
            <v-btn text x-small @click="$refs.uploadAvatarInput.click()">{{ $t('Upload') }}</v-btn>
          </div>
          <div v-if="user.info.photo !== null">
            <v-btn text x-small @click="removeAvatar()">{{ $t('Remove') }}</v-btn>
          </div>
        </v-overlay>

        <v-progress-circular v-if="avatarLoading" :size="128" color="white" indeterminate></v-progress-circular>

        <img v-if="user.info.photo !== null" :src="user.info.photo"/>
        <div v-else-if="!avatarLoading" class="white--text headline" style="font-size: 1rem!important;">{{ user.info.photoPlaceholder.toUpperCase() }}</div>
        <input
            type="file"
            style="display: none"
            ref="uploadAvatarInput"
            accept="image/*"
            @change="uploadAvatar"
        />
      </v-avatar>
      <div style="width: 30%">
        <div class="title">{{ user.info.firstName }}
          <v-btn @click="showEditNamesDialog();" icon small color="grey" class="ml-3" v-if="user.info.lastName.length === 0">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </div>
        <div class="title">{{ user.info.lastName }}
          <v-btn @click="showEditNamesDialog();" icon small color="grey" class="ml-3" v-if="user.info.lastName.length !== 0">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </div>
      </div>
    </v-card-text>
    <v-card-text class="d-flex justify-space-around align-center">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div class="text-center" v-bind="attrs"
               v-on="on">
            <v-icon color="green" size="32">mdi-thumb-up</v-icon>
            <div class="caption grey--text">{{ $t('Rating') }}</div>
            <div class="text-h4">{{ user.info.rating }}</div>
          </div>

        </template>
        <span>{{ $t('Rating') }}</span>
      </v-tooltip>


      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div class="text-center" v-bind="attrs"
               v-on="on">
            <v-icon color="red" size="32">mdi-heart</v-icon>
            <div class="caption grey--text">{{ $t('Karma') }}</div>
            <div class="text-h4">{{ user.info.karma }}</div>
          </div>
        </template>
        <span>{{ $t('Karma') }}</span>
      </v-tooltip>


      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div class="text-center" v-bind="attrs"
               v-on="on">
            <v-icon color="orange" size="32">mdi-apps</v-icon>
            <div class="caption grey--text">{{ $t('Applications') }}</div>
            <div class="text-h4">{{ user.info.publishedAppsCount }}</div>
          </div>
        </template>
        <span>{{ $t('Published applications') }}</span>
      </v-tooltip>
    </v-card-text>
    <v-dialog v-model="editNamesDialog" max-width="500">
      <v-card>
        <v-card-text class="pt-5">
          <v-text-field
                :label="$t('First name')"
                v-model="userModel.firstName"
                :error-messages="userModelErrors.firstName"
              ></v-text-field>
          <v-text-field
                :label="$t('Last name')"
                :error-messages="userModelErrors.lastName"
                v-model="userModel.lastName"
              ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="editNamesDialog = false;" text>{{ $t('Cancel') }}</v-btn>
          <v-btn :loading="userModelLoading" @click="saveUserModel();" text color="primary">{{ $t('Save') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-card>

  <div class="pa-10">

    <v-card max-width="450" flat style="margin: 0 auto;">
      <v-img
          min-height="330"
          :src="user.info.certificate !== null ? certificateImageUrl : require('@/assets/certificate.png')"
      />
      <v-card-text class="body-1" v-if="user.info.certificate !== null">
        <p v-html="$t('CertificateLink')"></p>
        <p>
          <a :href="certificateUrl" @click.prevent="gotoCertificate" class="grey--text">{{ certificateUrl }}</a>
          <v-btn icon
                 v-clipboard:copy="certificateUrl"
                 v-clipboard:success="() => { $store.dispatch('notifications/show', {'text': this.$t('CertificateLinkCopyInfo')}) }"
          >
            <v-icon small>mdi-content-copy</v-icon>
          </v-btn>
        </p>
      </v-card-text>
      <v-card-text class="body-1" v-else v-html="$t('CertificateInfo')">
      </v-card-text>
      <v-card-actions v-if="user.info.certificate !== null">
        <v-btn-toggle
          v-model="certificateLanguage"
          tile
          group
        >
          <v-btn value="ru">
            {{ $t('In Russian') }}
          </v-btn>

          <v-btn value="en">
            {{ $t('In English') }}
          </v-btn>
        </v-btn-toggle>
      </v-card-actions>
    </v-card>
  </div>
</section>

</template>

<script>
import {mapState} from "vuex";

export default {
  name: 'my-content-view',

  data: () => ({
    loading: false,
    showAvatarOverlay: false,
    avatarLoading: false,
    editNamesDialog: false,
    userModel: {
      firstName: '',
      lastName: ''
    },
    userModelErrors: {},
    userModelLoading: false,
    certificateLanguage: 'ru'
  }),

  computed: {
    ...mapState(['social', 'settings', 'user']),

    certificateImageUrl() {
      return `${process.env.VUE_APP_MAIN_HOST}/certificate/${this.certificateLanguage}/${this.user.info.certificate}.png`;
    },

    certificateUrl() {
      return `${process.env.VUE_APP_MAIN_HOST}/certificate/${this.user.info.certificate}/${this.certificateLanguage}/`
    }
  },

  created() {
    // TODO: Fetch user only if we really need it. We should make flags for example.
    this.$store.dispatch('user/fetchUserInfo');
  },

  activated() {
    this.$store.dispatch('user/fetchUserInfo');
  },

  methods: {
    gotoCertificate() {
      let win = window.open(this.certificateUrl, '_blank');
      win.focus();
    },

    uploadAvatar(e) {
      const newAvatar = e.target.files[0]
      if ((newAvatar !== null) && (newAvatar !== undefined))  {
        this.avatarLoading = true
        new Promise(resolve => {
          let reader = new FileReader()
          reader.onload = () => resolve(reader.result)
          reader.readAsDataURL(newAvatar)
        }).then((dataUrl) => {
          this.$store.dispatch('user/uploadAvatar', dataUrl).finally(() => {
            this.avatarLoading = false
          })
        })
      }
    },

    removeAvatar() {
      this.avatarLoading = true
      this.$store.dispatch('user/removeAvatar').then(() => {
        this.avatarLoading = false
      })
    },

    showEditNamesDialog() {
      this.editNamesDialog = true;
      this.userModelErrors = {};
      this.userModel.firstName = this.user.info.firstName;
      this.userModel.lastName = this.user.info.lastName;
    },

    saveUserModel() {
      this.userModelLoading = true;
      this.userModelErrors = {};
      this.$store.dispatch('user/updateProfile', this.userModel).then(() => {
        this.editNamesDialog = false;
      }, (error)=>{
        this.userModelErrors = error.response.data;
      }).finally(()=>{
        this.userModelLoading = false;
      })

    }
  },

  watch: {
    '$route.path'() {

    }
  }
}
</script>