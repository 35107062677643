<template>
  <div>
    <div class="text-xl pa-4"><router-link class="underline" :to='{name: "questionList_"}'>К списку вопросов</router-link></div>

    <div class="pl-4 pr-4 pb-16">

      <div v-if="!questionLoading && question" class="d-flex align-start">
        <v-menu
              left
              :close-on-content-click="false"
              :nudge-width="400"
              v-model="question.isProfileOpened"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-avatar size="32" color="primary" v-bind="attrs" v-on="on" class="mt-2">
                <v-img v-if="question.user.photo" :src="question.user.photo"/>
                <span v-else class="white--text subtitle-1">{{ question.user.photoPlaceholder.toUpperCase() }}</span>
              </v-avatar>
            </template>

            <profile-popup :user.sync="question.user" :showMenu.sync="question.isProfileOpened"></profile-popup>
          </v-menu>

          <v-card flat outlined class="mb-5 ml-4 flex-grow-1" style="overflow: hidden">
            <div class="d-flex justify-space-between pt-3 pb-2 pl-4 pr-1 comment-header">
              <div>
                {{ question.user.fullName }} <span class="grey--text">&mdash;</span>&nbsp;<timeago class="grey--text" :datetime="question.createdAt" :auto-update="60"></timeago>
              </div>
              <v-menu offset-y left v-if="(user.info.id === question.user.id) || user.info.isSuperuser">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon small>
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="editingQuestion = !editingQuestion">
                    <v-list-item-title>{{ $t('Edit') }}</v-list-item-title>
                    <v-list-item-action>
                      <v-icon color="orange lighten-2">mdi-pencil</v-icon>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item @click="removeOwnQuestion(question);">
                    <v-list-item-title>{{ $t('Delete') }}</v-list-item-title>
                    <v-list-item-action>
                      <v-icon color="red lighten-1">mdi-delete</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>

            <v-card-title v-if="!editingQuestion">
              {{ question.title }}
            </v-card-title>
            <div v-else class="pa-3">
              <v-text-field
                outlined
                full-width
                :label="$t('Label')"
                v-model="question.title"
              ></v-text-field>
            </div>
            <div v-if="!editingQuestion">
              <v-card-text class="body-1" v-hljs>
                <active-content :content="question.html"></active-content>
              </v-card-text>
              <div class="pl-5 pb-5">
                <rate-content
                  :can-rate="user.info.id !== question.user.id"
                  :rating.sync="question.rating"
                  :is-rated-by-user.sync="question.isRatedByUser"
                  :item-id="question.id"
                  :item-type="1"
                ></rate-content>
              </div>
            </div>


            <entity-content-editor
            v-else
            :message-object="question"
            :update-loading="editingQuestionLoading"
            :outlined="false"
            @cancel="editingQuestion = false"
            @submit="submitEditedQuestion"
        />
          </v-card>
      </div>

      <v-skeleton-loader
          v-else
          class="mx-auto"
          type="list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line"
      ></v-skeleton-loader>

      <v-skeleton-loader
          v-if="messagesLoading"
          class="mx-auto"
          type="list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line"
      ></v-skeleton-loader>

      <div v-else v-for="(message, index) in messages.messages" :key="`message-${index}`" class="d-flex align-start">
        <v-menu
              v-model="message.isProfileOpened"
              :close-on-content-click="false"
              :nudge-width="400"
              left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-avatar size="32" class="mt-2" color="primary"  v-bind="attrs" v-on="on">
                <v-img v-if="message.user.photo" :src="message.user.photo"/>
                <span v-else class="white--text subtitle-1">{{ message.user.photoPlaceholder.toUpperCase() }}</span>
              </v-avatar>
            </template>
            <profile-popup :user.sync="message.user" :show-menu.sync="message.isProfileOpened"></profile-popup>
          </v-menu>

        <v-card flat outlined class="mb-5 ml-4 flex-grow-1" style="overflow: hidden">
          <div class="d-flex justify-space-between pt-3 pb-2 pl-4 pr-1 comment-header">
              <div>
                {{ message.user.fullName }} <span class="grey--text">&mdash;</span>&nbsp;<timeago class="grey--text" :datetime="message.createdAt" :auto-update="60"></timeago>
              </div>
              <v-menu offset-y left v-if="(user.info.id === message.user.id) || user.info.isSuperuser">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon small>
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="editingMessageId = message.id">
                    <v-list-item-title>{{ $t('Edit') }}</v-list-item-title>
                    <v-list-item-action>
                      <v-icon color="orange lighten-2">mdi-pencil</v-icon>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item @click="removeOwnMessage(message)">
                    <v-list-item-title>{{ $t('Delete') }}</v-list-item-title>
                    <v-list-item-action>
                      <v-icon color="red lighten-1">mdi-delete</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-menu>
          </div>

          <div v-if="editingMessageId !== message.id">
            <v-card-text v-hljs class="body-1" >
              <active-content :content="message.html"></active-content>
            </v-card-text>
            <div class="pl-5 pb-5">
              <rate-content
                  :can-rate="user.info.id !== message.user.id"
                  :rating.sync="message.rating"
                  :is-rated-by-user.sync="message.isRatedByUser"
                  :item-id="message.id"
                  :item-type="3"
              ></rate-content>
            </div>
          </div>
          <entity-content-editor
              v-else
              :message-object="message"
              :update-loading="editingMessageLoading"
              :outlined="false"
              @cancel="editingMessageId = null"
              @submit="submitEditedMessage"
          />
        </v-card>
      </div>
      <div class="d-flex align-start" v-if="user">

        <v-avatar size="32" class="mt-2 mr-4" color="primary">
          <img v-if="user.info.photo !== null" :src="user.info.photo">
          <span v-else class="white--text headline"
                  style="font-size: 1rem!important;">{{ user.info.photoPlaceholder.toUpperCase() }}</span>
        </v-avatar>

        <v-card flat outlined class="flex-grow-1" style="overflow: hidden">
          <public-editor :text.sync="comment"></public-editor>
        </v-card>
      </div>

      <set-username-dialog :show-menu.sync="showSetUsernameDialog" :closable="true">
        {{ $t('setUsernameAddMessageText') }}
      </set-username-dialog>
      <div class="d-flex justify-end">
        <v-btn text color="primary" :disabled="comment === ''" @click="submit" :loading="messageSubmitLoading">
          {{ $t('Reply') }}
        </v-btn>
      </div>
    </div>

  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import PublicEditor from "@/components/editor/PublicEditor";
import EntityContentEditor from "@/components/editor/EntityContentEditor";
import RateContent from "@/components/RateContent";
import ProfilePopup from "@/components/ProfilePopup";
import SetUsernameDialog from "@/components/dialogs/SetUsernameDialog";
import ActiveContent from "@/components/ActiveContent";

export default {
  name: 'question-detail',
  components: {ProfilePopup, RateContent, EntityContentEditor, PublicEditor, SetUsernameDialog, ActiveContent},

  data: () => ({
    questionsLoading: false,
    comment: '',
    questionLoading: false,
    messagesLoading: true,
    messageSubmitLoading: false,
    editingMessageId: null,
    editingMessageLoading: false,
    deletingMessageLoading: false,
    editingQuestion: false,
    editingQuestionLoading: false,
    deletingQuestionLoading: false,
    showSetUsernameDialog: false
  }),

  created() {
    this.comment = this['questions/getCachedComment'];
    this.fetch();
  },

  computed: {
    ...mapState(['questions', 'messages', 'vfs', 'user', 'lessons']),
    ...mapGetters(['vfs/currentFile', 'questions/getCachedComment']),

    question() {
      if (this.questions.questions.length > 0)
        return this.questions.questions.find(question => question.id === parseInt(this.$route.params.id))
      return {}
    }
  },

  methods: {
    fetch() {
      // Check if question presented in store.
      // There could be situation when user come from activity
      // route so only 20 first questions are in the store now.
      // So, fetching it.

      if (!this.question || (Object.entries(this.question).length === 0)) {
        this.questionLoading = true
        this.$store.dispatch('questions/fetchOne', this.$route.params.id).then(_, (error)=>{
          if (error.response.status === 404) {
            this.$store.dispatch('notifications/show', {
              text: this.$t('questionDoesNotExists'),
              color: 'red',
              timeout: 5000
            });
            this.$router.push({name: 'questionList_'});
          }
        }).finally(() => {
          this.questionLoading = false;
        })
      }
      this.messagesLoading = true;

      this.$store.dispatch('messages/fetch', {
        'itemType': this.$constants.LESSON_QUESTION_TYPE,
        'itemId': this.$route.params.id,
      }).finally(() => {
        this.messagesLoading = false
      })
    },

    submit() {
      if (this.user.info.hasAutoUsername) {
        this.showSetUsernameDialog = true;
        return
      }

      this.messageSubmitLoading = true
      this.$store.dispatch('messages/add', {
        'itemType': this.$constants.LESSON_QUESTION_TYPE,
        'itemId': this.question.id,
        'text': this.comment,
      }).then(() => {
        this.comment = ''
      }).finally(() => {
        this.messageSubmitLoading = false
      })
    },

    attachCode() {
      let code = this['vfs/currentFile'].content
      this.comment += `\n\`\`\`python\n${code}\n\`\`\`\n`
    },

    submitEditedMessage(message) {
      this.editingMessageLoading = true
      this.$store.dispatch('messages/update', {
        'id': message.id,
        'text': message.text,
      }).finally(() => {
        this.editingMessageId = null
        this.editingMessageLoading = false
      })
    },

    removeOwnMessage(message) {
      this.$confirm(this.$t("Delete this reply?"), {
        buttonTrueText: this.$t('Delete'),
        buttonFalseText: this.$t('Cancel'),
        buttonTrueColor: 'red'
      }).then((confirmed) => {
        if (confirmed) {
          this.deletingMessageLoading = true
          this.$store.dispatch('messages/delete', message).finally(() => {
            this.deletingMessageLoading = false
          });
        }
      });
    },

    removeOwnQuestion(question) {
      this.$confirm(this.$t("Delete this question?"), {
        buttonTrueText: this.$t('Delete'),
        buttonFalseText: this.$t('Cancel'),
        buttonTrueColor: 'red'
      }).then((confirmed) => {
        if (confirmed) {
          this.deletingQuestionLoading = true;
          this.$store.dispatch('questions/delete', question).then(() => {
            this.$router.push({name: 'questionList_'});
          }).finally(() => {
            this.deletingQuestionLoading = false;
          });
        }
      });
    },

    submitEditedQuestion(question) {
      this.editingQuestionLoading = true
      this.$store.dispatch('questions/update', {
        'id': question.id,
        'sectionId': question.sectionId,
        'lessonId': question.lessonId,
        'title': question.title,
        'text': question.text,
      }).finally(() => {
        this.editingQuestion = false
        this.editingQuestionLoading = false
      })
    }
  },
  watch: {
    '$route'(to, from) {
      if ((to.name === 'questionDetail_') && (from.name === 'questionDetail_')) {
        this.fetch();
      }
    },

    comment(value) {
      this.$store.dispatch('questions/setCachedComment', value)
    }
  }
}
</script>
