<template>
  <v-dialog
    v-model="showMenuInner"
    width="500"
    @keypress.esc="showMenuInner = false"
    persistent
  >
    <v-card>
      <v-card-title class="headline grey">
        {{ $t(notifications.update.text) }}
      </v-card-title>

      <v-card-text class="pa-5">

      </v-card-text>

<!--      <v-divider></v-divider>-->

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          text
          @click="cancel"
        >
          {{ $t('Later') }}
        </v-btn>

        <v-btn
          color="green"
          text
          @click="confirm"
        >
          {{ $t('Update') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ShowMixin from "@/mixins/ShowMixin";
import {mapState} from "vuex";

export default {
  name: 'updates-available-dialog',
  mixins: [ShowMixin],

  computed: {
    ...mapState(['notifications'])
  },

  methods: {
    cancel() {
      this.showMenuInner = false
      this.$store.dispatch('notifications/updateLater')
    },

    confirm() {
      this.showMenuInner = false
      window.location.reload();
    }
  }
}
</script>
