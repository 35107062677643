import Vue from 'vue'

const baseUrl = '/api/v2/account/'

export default {
  getUserInfo() {
    return Vue.axios.get(baseUrl)
  },

  getUserPaymentInfo() {
    return Vue.axios.get(`${baseUrl}payment-info/`)
  },

  getUserGifts() {
      return Vue.axios.get(`${baseUrl}gifts/`)
  },

  useGift(code) {
    return Vue.axios.put(`${baseUrl}gifts/use/`, {code: code})
  },

  patchUserInfo(data) {
    return Vue.axios.patch(baseUrl, data)
  },

  uploadAvatar(dataBase64) {
    return Vue.axios.put(`${baseUrl}photo/`, {'photo': dataBase64})
  },

  removeAvatar() {
    return Vue.axios.delete(`${baseUrl}photo/`)
  },

  changePassword(data) {
    return Vue.axios.put(`${baseUrl}password/`, data)
  }
}
