<template>
  <section :style="tabsContainerInlineStyles">
    <ul class="editorTabs" v-if="vfs.tabs.length > 0">
      <li
        v-for="tab in vfs.tabs"
        :key="tab.item.id"
        :class="{'active': tab.isActive}"
        @click="setActive(tab)"
      >
        <v-tooltip bottom open-delay="500">
          <template v-slot:activator="{on, attrs}">
            <div>
              <file-icon v-if="!tab.loading" :name="tab.item.name"></file-icon>
              <v-progress-circular v-else indeterminate width="1" color="gray" size="20"/>
            </div>
            <div v-bind="attrs" v-on="on" class="pl-2 d-flex file">
              <div class="name">{{ tab.item.name }} </div>
              <div v-if="files.content[tab.item.id] !== files.modifiedContent[tab.item.id]">*</div>

           </div>
          </template>
          <span v-if="tab.item.fullPath">{{ tab.item.fullPath }}</span>
          <span v-else>{{ tab.item.data.fullPath }}</span>
        </v-tooltip>
        <v-btn icon x-small @click="close(tab, $event)"><v-icon>mdi-close</v-icon></v-btn>
      </li>
    </ul>
  </section>
</template>

<script>
import {mapState} from "vuex";
import SizeMixin from "@/mixins/SizeMixin";
import FileExtensionMixin from "@/mixins/FileExtensionMixin";
import FileIcon from "@/components/FileIcon.vue";

export default {
  name: 'editor-tabs',
  mixins: [SizeMixin, FileExtensionMixin],
  components: {FileIcon},

  data: () => ({
    loading: true
  }),

  computed: {
    ...mapState(['settings', 'files', 'vfs']),

    tabsContainerInlineStyles() {
      return {
        'margin-left': `${this.left}px`,
        'margin-right': `${this.right}px`,
        'transition': this.settings.UI.editor.transition,
        'margin-top': `${this.top}px`,
      }
    }
  },

  methods: {
    setActive(tab) {
      this.$store.dispatch('vfs/setActiveTab', tab.item.id);
    },

    close(tab, event) {
      this.$store.dispatch('vfs/updateNode', {'id': tab.item.id, 'isOpened': false})
      this.$store.dispatch('vfs/closeTab', tab)
      if (event) {
        event.stopPropagation()
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles.scss";

.theme--light {
  ul.editorTabs {
    background-color: $background-color-light;
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
  }
  ul.editorTabs > li {
    border-right: thin solid rgba(0, 0, 0, 0.12);
  }
  ul.editorTabs > li:hover {
    background-color: lighten($background-color-light, 5%);
  }
}

.theme--dark {
  ul.editorTabs {
    background-color: $background-color-dark;
    border-bottom: thin solid rgba(255, 255, 255, 0.12);
  }
  ul.editorTabs > li {
    border-right: thin solid rgba(255, 255, 255, 0.12);
  }
  ul.editorTabs > li:hover {
    background-color: lighten($background-color-dark, 5%);
  }
}


ul.editorTabs {
  list-style: none;
  padding:0;
  height: 40px;
  display: flex;
  user-select: none;
}

ul.editorTabs > li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: default;
  transition: border-bottom .2s;
  border-bottom: solid 2px transparent;
  padding: 0 5px 0 10px;
}

ul.editorTabs > li > .file > .name {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}

ul.editorTabs > li:hover {
  transition: background-color .2s;
}

ul.editorTabs > .active {
  border-bottom: solid 2px var(--v-primary-base);
  transition: border-bottom .5s;
}

ul.editorTabs > li.active:hover {
  border-bottom: solid 2px var(--v-primary-base);
  transition: border-bottom .5s;
}
</style>