
export const messages = {
  assistant: {
    fixErrorWithoutSolution: {
      title: 'Provide a hint to fix the error',
      description: 'The program throws an error or does not pass the check. Provide a hint on how to fix it, but do not show the solution.',
    },
    fixError: {
      title: 'Help fix the error',
      description: 'Show what needs to be changed in the program to fix the error or pass the check.',
    },
    pep8: {
      title: 'PEP8 check',
      description: 'Check the code for compliance with PEP8 standards.',
    },
    hint: {
      title: 'Hint',
      description: 'Where should I start solving the problem?',
    },
    solution: {
      title: 'Our solution',
      description: 'How we solve this problem.',
    }
  },

  payment: {
    title: 'Unlock your path into the world of code'
  }
}