<template>
<v-container class="body-1 ps-4">

  <v-row align="start" class="ps-3"  v-for="(item, i) in items" :key="`item-quiz-${i}`">
    <v-checkbox v-model="selected" :value="i"></v-checkbox>
    <v-col class="pt-5" v-html="item.text" v-hljs>
    </v-col>
    </v-row>
</v-container>
</template>
<script>

export default {
  name: 'lesson-quiz',
  props: ['items'],
  data: () => ({
    selected: []
  }),
  created() {
    this.$emit('onResultChanged', {result: false, text: this.$t('Choose correct answers to complete this lesson.')})
  },
  watch: {
    selected: function(newValue) {
      let selectedItems = [...newValue];
      selectedItems.sort();
      let correctedAnswers = [];
      for (let i = 0;i < this.items.length; i++) {
        if (this.items[i].isCorrect) {
          correctedAnswers.push(i);
        }
      }
      if (correctedAnswers.toString() === selectedItems.toString()) {
        this.$emit('onResultChanged', {result: true, text: this.$t('Excellent! You can move on.')})
      } else {
        this.$emit('onResultChanged', {result: false, text: this.$t('Wrong. Try again.')})
      }
    }
  }
}
</script>
