<template>
  <v-card flat class="pb-2">
    <v-card-actions class="pa-4 pb-0">
      <div class="title">{{ titleHint }}</div>
    </v-card-actions>
    <v-card-text>
      <v-text-field :label="$t('Label')" v-model="title"></v-text-field>
      <public-editor :editorHeight="500" :text.sync="text"></public-editor>
    </v-card-text>
    <v-card-actions class="pr-4">
      <v-spacer></v-spacer>
      <v-btn
          color="primary"
          text
          :disabled="title === ''"
          :loading="addLoading"
          @click="submit"
      >
        {{ $t('Save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapState} from "vuex";
import PublicEditor from "@/components/editor/PublicEditor";

export default {
  name: 'workbook-content-add-view',
  components: {PublicEditor},
  data: () => ({
    addLoading: false,
    title: '',
    text: ''
  }),

  computed: {
    ...mapState(['workbook']),
    titleHint: function() {
      return this.title.length > 0 ? this.title : this.$t('Label')
    }
  },

  methods: {
    submit() {
      this.addLoading = true
      this.$store.dispatch('workbook/addRecord', {'title': this.title, 'text': this.text}).then((response) => {
        this.$router.push(`/workbook/${response.data.id}`)
        this.$store.dispatch('notifications/show', {text: this.$t('Workbook record added')})
      }).finally(() => {
        this.addLoading = false
      })
    }
  },

  watch: {

  }
}
</script>

<style scoped>
.v-md-editor {
  box-shadow: none;
  border: solid 1px grey;
  border-radius: 0;
}
</style>

