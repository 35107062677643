<template>
  <section :class="settings.preferences.darkTheme.value ? 'monokai-sublime-theme' : 'github-theme'">
    <v-card flat outlined class="mb-6 ml-4 flex-grow-1 mt-6 mr-4">
      <public-editor v-if="editor" @blur="onBlur" :text.sync="comment">
        <avatar :photo="user.info.photo" :placeholder="user.info.photoPlaceholder" class="mt-1 ml-4 mr-4"></avatar>
      </public-editor>

      <div v-else class="d-flex align-center pt-2 pb-2 pl-4 pr-1 " @click="editor = true">
        <avatar :photo="user.info.photo" :placeholder="user.info.photoPlaceholder" class="mt-1"></avatar>
        <div class="pl-4 text-h5 grey--text" style="cursor: auto">
          Хотите чем-нибудь поделиться?
        </div>
      </div>
    </v-card>
    <feed-item v-for="(item, i) in feed" :item.sync="item"></feed-item>
  </section>
</template>

<script>
import {mapState} from "vuex";
import PublicEditor from "@/components/editor/PublicEditor";
import Avatar from "@/components/Avatar";
import FeedItem from "@/components/FeedItem";

export default {
  name: 'feed-content-view',
  components: {Avatar, PublicEditor, FeedItem},
  data: () => ({
    loading: false,
    feed: null,
    comment: '',
    editor: false
  }),

  computed: {
    ...mapState(['social', 'settings', 'user'])
  },

  created() {
    this.$store.dispatch('questions/fetch', {
        'sectionId': 100,
        'lessonId': 500
      }).then((questions) => {
        this.feed = questions;
      })
  },

  methods: {
    onBlur(content) {
      if (content.length === 0) {
        this.editor = false
      }
    }
  },

  watch: {
    '$route.path'() {

    }
  }
}
</script>