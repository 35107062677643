import Vue from "vue";

let Constants = {
  install(Vue) {
    Vue.mixin({
      created() {
        this.$constants = {
          ACTIVITY_ASK_QUESTION: 1,
          ACTIVITY_ANSWER_TO_QUESTION: 2,

          // MESSAGE_ITEM_TYPES
          LESSON_QUESTION_TYPE: 1,
          ACHIEVEMENT_TYPE: 2,
          PUBLISHED_APPLICATION_TYPE: 3,
          POST_TYPE: 4,
        }
      }
    });
  }
};

Vue.use(Constants)
export default Constants;