<template>
  <div ref="container" class="animationContainer"></div>
</template>
<script>
export default {
  name: 'verification-progress-animation',
  data: () => ({
    elements: [],
    elementsInterval: null,
    activeInterval: null
  }),
  props: ['hasError'],
  created() {
    this.$nextTick(()=>{
      for (let i=0;i<4;i++) {
        let row = document.createElement('div');
        row.classList.add('animationRow');
        for (let j=0;j<8; j++) {
          let col = document.createElement('span');
          row.appendChild(col);
          col.classList.add('animationElement');
          col.innerHTML = Math.floor(Math.random() * 2).toString();
          this.elements.push(col);
        }
        this.$refs['container'].appendChild(row);
      }

      this.elementsInterval = setInterval(()=>{
        for (let i=0;i<Math.floor(Math.random() * 20);i++) {
          this.elements[Math.floor(Math.random() * (this.elements.length - 1))].innerHTML = Math.floor(Math.random() * 2).toString();
        }

      }, 100);

      this.activeInterval = setInterval(()=>{
        for (let i=0;i<this.elements.length;i++) {
          this.elements[i].classList.remove('animationElementActive');
        }
        for (let i=0;i<Math.floor(Math.random() * 1) + 1;i++) {
          this.elements[Math.floor(Math.random() * (this.elements.length - 1))].classList.add('animationElementActive');
        }
        if (this.hasError) {
          clearInterval(this.elementsInterval);
          for (let i=0;i<this.elements.length;i++) {
            this.elements[i].classList.remove('animationElementActive');
          }
          this.elements[Math.floor(Math.random() * 16)].classList.add('animationElementActiveRed');
          clearInterval(this.activeInterval);
        }
      }, 250);
    });

  },
  destroyed() {
    clearInterval(this.elementsInterval);
    clearInterval(this.activeInterval);
  },

  methods: {

  }
}
</script>
<style>
.animationContainer {
  font-weight: 300;
  font-family: "Monaco", "Menlo", "Ubuntu Mono", "Consolas", "source-code-pro", monospace;
  font-size: 18px;
  -webkit-mask-image: -webkit-gradient(linear, left top,
    left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
}
.animationElement {
  padding:3px;
  transition: all .4s ease;
  color: #777;
}
.animationElementActive {
  background-color: #4CAF50;
  color: #fff;
}
.animationElementActiveRed {
  background-color: red;
  color: #fff;
}
.animationRow {
  margin-top: 3px;
}
</style>