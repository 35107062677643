<template>
  <v-dialog v-model="showMenuInner" max-width="300" persistent>
      <v-card>
        <v-card-title>
          {{ $t('Choose theme') }}
        </v-card-title>

        <v-card-text>
          {{ $t('chooseThemeText') }}
          <v-radio-group row v-model="settings.preferences.darkTheme.value">
            <v-radio :label="$t('lightTheme')" :value="false"></v-radio>
            <v-radio :label="$t('darkTheme')" :value="true"></v-radio>
          </v-radio-group>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="save()" text color="primary">{{ $t('Save') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
import ShowMixin from "@/mixins/ShowMixin";
import {mapState} from "vuex";

export default {
  name: 'choose-theme-dialog',
  mixins: [ShowMixin],
  computed: {
    ...mapState(['settings', 'user', 'notifications', 'lessons']),
  },
  data: ()=> {
    return {
      loading: false
    }
  },

  methods: {
    save() {
      this.loading = true;
      this.$store.dispatch('settings/updatePreferences', this.settings.preferences).finally(() => {
        this.loading = false;
        this.showMenuInner = false;
      });
    }
  }
}

</script>