
export const namespaced = true

export const state = {
  message: {
    text: 'Success',
    timeout: 2000,
    show: false,
    color: 'success'
  },

  update: {
    text: 'Updates available',
    details: undefined, // could be used to show what changed in new version
    show: false,
    // if user clicked cancel button, so there is no need to determine them with updates dialog on every request.
    later: false
  },
}

export const mutations = {
  SET_MESSAGE(state, message) {
    state.message = {...state.message, ...message}
  },

  SET_UPDATES_CONFIG(state, config) {
    state.update = {...state.update, ...config}
  }
}

export const actions = {
  show({ commit }, message) {
    message = {...message, ...{show: true}}
    commit('SET_MESSAGE', message)
  },

  updatesAvailable({ commit }) {
    commit('SET_UPDATES_CONFIG', {show: true})
  },

  updateLater({ commit }) {
    commit('SET_UPDATES_CONFIG', {later: true})
  }
}

export const getters = {

}
