let Objects = null;
let interpreter = null
let dataArray = null;
let eventArray = null;

export function TurtleBridgeInit(interpreter_, dataArray_, eventArray_) {
  Objects = {};
  interpreter = interpreter_;
  dataArray = dataArray_;
  eventArray = eventArray_;
}

class Root {
  constructor(id) {
    this.id = id;
    this.el = document.createElement('div')
    this.el.id = `turtle-window-${id}`;
    this.el.className = 'turtle__window'
    // this.el.style.transform = 'translate(-50%, -50%)'
    this.el.style.top = `0px`
    this.el.style.left = `0px`

    this.header = document.createElement('div')
    this.header.className = 'turtle__window_title'
    this.header.addEventListener("mousedown", (e) => {
      let startX = e.clientX
      let startY = e.clientY

      let onMouseMove = (e) => {
        this.el.style.top = `${this.el.offsetTop - startY + e.clientY}px`
        this.el.style.left = `${this.el.offsetLeft - startX + e.clientX}px`
        startY = e.clientY;
        startX = e.clientX;
      }

      let onMouseUp = () => {
        document.removeEventListener('mousemove', onMouseMove)
        document.removeEventListener('mouseup', onMouseUp);
      }
      document.addEventListener('mousemove', onMouseMove)
      document.addEventListener('mouseup', onMouseUp);
    })

    let closeButton = document.createElement('a')
    closeButton.className = 'turtle__window_title_close'
    // closeButton.innerText = '×'

    closeButton.addEventListener('click', this.destroy);

    this.titleContainer = document.createElement('div')
    this.titleContainer.innerText = ''

    this.header.appendChild(this.titleContainer);
    this.header.appendChild(closeButton);

    this.el.appendChild(this.header)
    document.body.appendChild(this.el);

    this.container = document.createElement('div')
    this.container.style.position = 'relative'
    this.el.appendChild(this.container);
  }

  title(title) {
    this.titleContainer.innerText = title;
  }

  set_geometry(width, height, x, y) {
    
    this.el.style.left = `${x}px`
    this.el.style.top = `${y}px`
    this.el.style.width = `${width}px`
    this.el.style.height = `${height + this.header.offsetHeight}px`
    this.container.style.height = `${height}px`
  }

  destroy() {
    if (interpreter.interruptExecution !== undefined) {
      interpreter.interruptExecution();
    }
  }
}

class Line {
  constructor(...coords) {
    this.coordinates = [];
    this.el = document.createElementNS("http://www.w3.org/2000/svg", "path");
    this.el.style.stroke = 'black';
    this.el.style.strokeWidth = 1;
    this.el.style.strokeLinecap = 'round';
    this.el.style.fill = 'none';

    this.kwargs = coords.pop();
    this.coords(...coords);
    //console.log('Line', this.coordinates, this.kwargs);
  }

  coords(...args) {
    if (args.length % 2 !== 0) {
      args.pop();
    }

    this.coordinates = [...args];
    let coords_ = [...this.coordinates];
    let x0 = coords_.shift();
    let y0 = coords_.shift();
    let d = `M${x0} ${y0}`;

    while (coords_.length > 0) {
      let x = coords_.shift();
      let y = coords_.shift();
      d += ` L ${x} ${y}`
    }

    coords_ = [...this.coordinates];
    while (coords_.length > 2) {
      let y = coords_.pop();
      let x = coords_.pop();
      d += ` L ${x} ${y}`
    }
    this.el.setAttributeNS(null, "d", d)
  }

  itemconfigure(kwargs) {
    if (kwargs.fill) {
      this.el.style.stroke = kwargs.fill;
    }
    if (kwargs.width) {
      this.el.style.strokeWidth = kwargs.width;
    }
  }

}

class Polygon {
  constructor(...coords) {
    //console.log('Polygon', coords)
    this.coordinates = [];
    this.el = document.createElementNS("http://www.w3.org/2000/svg", "path");
    this.el.style.strokeLinecap = 'round';
    //this.el.style.stroke = 'black';
    //this.el.style.strokeWidth = 1;
    //this.el.style.fill = 'black';
    this.kwargs = coords.pop();
    this.coords(...coords);
    //console.log('Polygon', this.coordinates, this.kwargs);
  }

  coords(...args) {
    if (args.length % 2 !== 0) {
      args.pop();
    }

    this.coordinates = [...args];
    let coords_ = [...this.coordinates];
    let x0 = coords_.shift();
    let y0 = coords_.shift();
    let d = `M${x0} ${y0}`;

    while (coords_.length > 0) {
      let x = coords_.shift();
      let y = coords_.shift();
      d += ` L ${x} ${y}`
    }
    d += ` L ${x0} ${y0}`
    this.el.setAttributeNS(null, "d", d)
  }

  itemconfigure(kwargs) {
    if (kwargs.fill) {
      this.el.style.fill = kwargs.fill;
    }
    if (kwargs.outline) {
      this.el.style.stroke = kwargs.outline;
    }
    if (kwargs.width) {
      this.el.style.strokeWidth = kwargs.width;
    }
  }
}

class Text {
  constructor(x, y, text, anchor, fill, font) {
    this.x = x;
    this.y = y;
    this.text = text;
    this.anchor = anchor;
    this.fill = fill;
    this.font = font;
    this.justify = 'left';
    this.el = document.createElementNS("http://www.w3.org/2000/svg", "foreignObject")
    this.updateElement()
    this.coords(x, y)
  }

  coords(x, y) {
    if ((this.anchor === 'n') || (this.anchor === 'center') || (this.anchor === 's')) {
      this.el.setAttributeNS(null, "x", x - this.cWidth / 2)
    } else if ((this.anchor === 'ne') || (this.anchor === 'e') || (this.anchor === 'se')) {
      this.el.setAttributeNS(null, "x", x - this.cWidth)
    } else {
      this.el.setAttributeNS(null, "x", x)
    }

    if ((this.anchor === 'w') || (this.anchor === 'center') || (this.anchor === 'e')) {
      this.el.setAttributeNS(null, "y", y - this.cHeight / 2)
    } else if ((this.anchor === 'sw') || (this.anchor === 's') || (this.anchor === 'se')) {
      this.el.setAttributeNS(null, "y", y - this.cHeight)
    } else {
      this.el.setAttributeNS(null, "y", y)
    }
  }

  updateElement() {
    let container = document.createElement('div')
    if (this.width === null) {
      container.style.whiteSpace = 'nowrap'
    } else {
      container.style.overflowWrap = 'break-word'
      container.style.maxWidth = `${this.width}px`
    }

    container.style.userSelect = 'none'
    container.innerText = this.text

    if (this.justify === 'left') {
      container.style.textAlign = 'left'
    } else if (this.justify === 'right') {
      container.style.textAlign = 'right'
    } else if (this.justify === 'center') {
      container.style.textAlign = 'center'
    }

    let font = this.font
    if (Array.isArray(font)) {
      if (font.length > 0) {
        container.style.fontFamily = font[0]
      }
      if (font.length > 1) {
        container.style.fontSize = `${font[1]}px`
      }
      if ((font.length > 2) && (font[2].toLowerCase().includes('bold'))) {
        container.style.fontWeight = 'bold';
      }
      if ((font.length > 2) && (font[2].toLowerCase().includes('italic'))) {
        container.style.fontStyle = 'italic';
      }
    }

    container.style.position = 'absolute'
    container.style.color = this.fill
    document.body.appendChild(container)
    this.cHeight = container.offsetHeight + 1
    this.cWidth = container.offsetWidth + 1
    //document.body.removeChild(container)

    container.setAttribute('xmlns', "http://www.w3.org/1999/xhtml")
    container.style.position = 'relative'

    this.el.setAttributeNS(null, "width", this.cWidth)
    this.el.setAttributeNS(null, "height", this.cHeight)
    if (this.el.childElementCount > 0) {
      this.el.replaceChild(container, this.el.children[0])
    } else {
      this.el.appendChild(container)
    }
  }
}

class Canvas {
  constructor(id, masterId) {
    this.master = Objects[masterId];

    this.svg = document.createElementNS("http://www.w3.org/2000/svg", "svg")
    this.master.container.appendChild(this.svg);
    this.objects = {};

    this.autoresize()
  }

  reset(width, height, background) {
    console.info('canvas.reset', width, height, background)
  }

  delete_all() {
    for (let key in this.objects) {
      this.svg.removeChild(this.objects[key].el);
    }
    this.objects = {};
  }

  delete_by_id(_id) {
    this.svg.removeChild(this.objects[_id].el);
    delete this.objects[_id];
  }

  autoresize() {
    const width = this.master.container.offsetWidth;
    const height = this.master.container.offsetHeight;

    this.svg.setAttributeNS(null, "viewBox", `-${width/2} -${height/2} ${width} ${height}`)
    this.svg.setAttributeNS(null, "width", `${width}`)
    this.svg.setAttributeNS(null, "height", `${height}`)

  }

  create_line(_id, ...args) {
    this.objects[_id] = new Line(...args);
    this.svg.appendChild(this.objects[_id].el);
  }

  create_polygon(_id, ...args) {
    this.objects[_id] = new Polygon(...args);
    this.svg.appendChild(this.objects[_id].el);
  }

  create_text(_id, x, y, text, anchor, fill, font) {
    this.objects[_id] = new Text(x, y, text, anchor, fill, font);
    this.objects[_id].el.id = `text-${_id}`;
    this.svg.appendChild(this.objects[_id].el);
  }

  bbox(_id) {
    let o = this.objects[_id];
    if (o.el.nodeName === 'foreignObject') {
      interpreter.sendToWorker({
        'x0': parseFloat(o.el.getAttribute('x')),
        'y0': parseFloat(o.el.getAttribute('y')),
        'x1': parseFloat(o.el.getAttribute('x')) + o.cWidth,
        'y1': parseFloat(o.el.getAttribute('y')) + o.cHeight
      }, dataArray, eventArray);
    } else {
      interpreter.sendToWorker({
        'x0': o.x,
        'y0': o.y,
        'x1': o.x + o.cWidth,
        'y1': o.y + o.cHeight
      }, dataArray, eventArray);
    }

  }

  tag_raise(tag) {
    let o = this.objects[tag];
    // make last child of this.svg
    this.svg.appendChild(o.el);
  }

  coords(_id, ...args) {
    let o = this.objects[_id];
    if (o === undefined) {
      console.warn(`Object with id=${_id} not found`);
      return;
    }
    o.coords(...args);

  }
  config(kwargs) {
    if (kwargs.bg) {
      this.svg.style.backgroundColor = kwargs.bg;
    }

    //console.log('config: ', kwargs)
  }

  itemconfigure(_id, kwargs) {
    let o = this.objects[_id];
    if (o === undefined) {
      console.warn(`Object with id=${_id} not found`);
      return;
    }
    o.itemconfigure(kwargs);
  }

  bind(sequence) {
    console.log('bind', sequence);
    switch (sequence.toLowerCase()) {
      case '<button-1>':
        this.svg.addEventListener('click', (event) => {
          interpreter.sendToWorker({'sequence': '<button-1>', 'data': {
              x: event.offsetX,
              y: event.offsetY
            }}, dataArray, eventArray)
        });
        break
    }
  }

  tag_bind(tag, sequence) {
    // if type of tag is string, then it is a tag name
    if (typeof tag === 'string') {

    } else {
      let o = this.objects[tag];
      if (o === undefined) {
        console.warn(`Object with id=${tag} not found`);
        return;
      }
      switch (sequence.toLowerCase()) {
        case '<button-1>':
          this.svg.addEventListener('click', (event) => {
            interpreter.sendToWorker({
              'sequence': '<button-1>', 'tag': tag, 'data': {
                x: event.offsetX,
                y: event.offsetY
              }
            }, dataArray, eventArray)
          });
          break
      }
    }
  }
}

export function TurtleBridgeHandle(data) {
  if (data.messages !== undefined) {
    for (let i = 0; i < data.messages.length; i++) {
      let message = data.messages[i];
      const args = message.args || [];
      const kwargs = message.kwargs || {};
      //console.log('TurtleBridgeHandle', args, kwargs);

      if (message.method === '__init__') {
        if (Objects[message.id] !== undefined) {
          console.warn(`Object with id=${message.id} already exists`);
        }
        if (message.className === '_Root') {
          Objects[message.id] = new Root(message.id, ...args, kwargs);
        } else if (message.className === 'Canvas') {
          Objects[message.id] = new Canvas(message.id, ...args, kwargs);
        } else {
          console.warn(`Unknown class ${message.className}`);
        }
      } else {
        // Call method
        Objects[message.id][message.method](...args, kwargs);
      }
    }
  }
}